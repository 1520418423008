/* eslint-disable jsx-a11y/alt-text */
import React, {useEffect, useState} from "react";
import {InputBase, Grid, Button, Box} from "@material-ui/core";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {CompTextField} from "../../components/TextField";
import {ButtonMain} from "../../components/ButtonMain";
import {ButtonSecondary} from "../../components/ButtonSecondary";
import {CompRadioGroup} from "../../components/RadioGroup";
import {apiAction} from "../api/actions";
import settings from "../api/settings";
import i18n from "../../i18n.js";
import QRCode from 'qrcode';
import Typography from "@material-ui/core/Typography";
import {ageOptions, genderOptions} from "./feedbackOptions";

const searchFunction = (queryString, onSuccess, onFailure) => {
    return apiAction({
        url: `${settings.baseURL}/feedback/${queryString}`,
        onSuccess: onSuccess,
        onFailure: onFailure,
        label: "something"
    });
}

const sendFeedbackItem = (queryString, data, onSuccess, onFailure) => {
    return apiAction({
        url: `${settings.baseURL}/feedback/add/${queryString}`,
        data: data,
        method: "POST",
        onSuccess: onSuccess,
        onFailure: onFailure,
        label: "something"
    });
}

const FeedbackComponent = ({searchFunction,sendFeedbackItem,props}) => {

    const propsCode = props.match.params.code;

    let initialCode = '';
    let language = null;
    let mode = 0;
    let searchMode = false;
    let preview = false;

    if (propsCode) {
        const array = propsCode.split("-");

        for (var i = 0; i < array.length; i++) {
            var v = array[i];
            if (v === "0") {mode = 0}
            else if (v === "1") {mode = 1}
            else if (v === "2") {mode = 2}
            else if (v === "3") {mode = 3}
            else if (v === "4") {mode = 4}
            else if (v === "fi") {language = "fi"}
            else if (v === "sv") {language = "sv"}
            else if (v === "preview") {preview = true}
            else {
                initialCode = v;
            }
        }
        searchMode = true;
    }
    const [qr, setQr] = useState(null);
    if (window.location.href.includes("-preview") && qr === null) {
        QRCode.toDataURL(window.location.href.replace("-preview", ""), {
            width: 400,
            margin: 2,
        }, (err, url) => {
            if (err) return console.error(err);
            setQr(url);
            })
    }

    const {t} = useTranslation('translation');

    if (language && i18n.language !== language) {
        i18n.changeLanguage(language);
    }

    const [code, setCode] = useState(initialCode);
    const [clicked, setClicked] = useState(false);
    const [age, setAge] = useState("0");
    const [gender, setGender] = useState("0");
    const [rating, setRating] = useState("-1");
    const [comment, setComment] = useState('');
    const [currentMode, setCurrentMode] = useState(mode);
    const [error, setError] = useState(null);
    const [search, setSearch] = useState(searchMode);
    const [useAgeAndGender, setUseAgeAndGender] = useState(false);
    const [useFeedbackRating, setUseFeedbackRating] = useState(false);
    const [useFeedbackComment, setUseFeedbackComment] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [sent, setSent] = useState(false);
    const [name, setName] = useState('');
        //0 none found, 1 found not yet chosen, 2 feedback, 3 signUp
    useEffect(() => {
        if (clicked) {
            setClicked(false);
            console.log("Clicked with code: "+code);
            //search();
            setCurrentMode(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clicked]);

    useEffect(() => {
        if (search) {
            setSearch(false);
            handleSearch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search])

    const handleChange = (e) => {
        setCode(e.target.value);
    };

    const sendFeedback = () => {
        const data = {};
        data.age = currentMode === 3 ? age : null;
        data.gender = currentMode === 3 ? gender : null;
        data.rating = currentMode === 2 && useFeedbackRating ? rating : null;
        data.comment = currentMode === 2 && useFeedbackComment ? comment : null;

        sendFeedbackItem(code, data, () => {
            setSent(true);
            setError(null);
            goBack();
            return {type: "ignore", payload: "ignore"};
        },
        (error) => {
            let data = "Connection error";
            if (error.response && error.response.data && error.response.data.data) data = error.response.data.data.error;
            setError(data);
            return {type: "ignore", payload: "ignore"};
        });
    };

    const goBack = () => {
        setDisabled(false);
        setAge("0");
        setGender("0");
        setRating("-1");
        setComment('');
        setCurrentMode(1);
        setError(null);
    };

    const ratingOptions = [
        {
            label: "😁 "+t('feedback.excellent'),
            value: "5"
        },
        {
            label: "🙂",
            value: "4"
        },
        {
            label: "😐 "+t('feedback.neutral'),
            value: "3"
        },
        {
            label: "🙁",
            value: "2"
        },
        {
            label: "😠 "+t('feedback.verybad'),
            value: "1"
        },
        {
            label: t('feedback.not_answered'),
            value: "-1"
        },
    ]

    let ageComp = (
        <Grid item xs={12} sm={6}>
            <div className={'label label-size'}>{t('feedback.ageOptions')}</div>
            <CompRadioGroup
            large={true}
            name="ageOptions"
            selected={age}
            rows={ageOptions}
            labelTranslation={true}
            disabled={disabled}
            onChange={(e) => setAge(e.target.value)}/>
        </Grid>
    )

    let genderComp = (
        <Grid item xs={12} sm={6}>
            <div className={'label label-size'}>{t('feedback.genderOptions')}</div>
            <CompRadioGroup
            large={true}
            name="genderOptions"
            selected={gender}
            disabled={disabled}
            rows={genderOptions}
            labelTranslation={true}
            onChange={(e) => setGender(e.target.value)}/>
        </Grid>
    )
    
    let feedbackRating = (
        <Grid item xs={12} sm={12}>
            <div className={'label label-size'}>{t('feedback.rating')}</div>
            <CompRadioGroup
            large={true}
            name="ratingOptions"
            selected={rating}
            rows={ratingOptions}
            disabled={disabled}
            onChange={(e) => setRating(e.target.value)}/>
        </Grid>
    )

    let feedbackComment = (
        <>
            <div className={'label label-size'}>{t('feedback.comment')}</div>
            <Grid item xs={12} sm={12}>
                <CompTextField
                value={comment}
                name="comment"
                multiline
                fullWidth
                disabled={disabled}
                rows={6}
                inputProps={{maxLength: 2000}}
                onChange={(e) => setComment(e.target.value)}/>
            </Grid>
        </>
    )

    const handleSearch = () => {
        searchFunction(code, (data, response) => {
            setName(data.name);
            setUseAgeAndGender(data.ageAndGender === true);
            setUseFeedbackComment(data.feedbackComment === true);
            setUseFeedbackRating(data.feedbackRating === true);
            setError(null);
            if (currentMode === 0) {
                setCurrentMode(1);
            }
            return {type: "ignore", payload: "ignore"};
        },
        (data) => {
            setError(t('feedback.'+data.response.data.data.error));
            return {type: "ignore", payload: "ignore"};
        })
    };

    const chooseFeedbackF = () => {
        setSent(false);
        setCurrentMode(2);
    };
    const chooseSignUpF = () => {
        setSent(false);
        setCurrentMode(3);
    };

    let chooseFeedback = (
        <ButtonMain item sm={6} style={{width: "100%"}} className="buttonText" onClick={() => chooseFeedbackF()}>
            {t('feedback.sendFeedback')}
        </ButtonMain>
    )
    let chooseSignUp = (
        <ButtonMain item sm={6} style={{width: "100%"}} className="buttonText" onClick={() => chooseSignUpF()}>
            {t('feedback.sendSignUp')}
        </ButtonMain>
    )

    let sendFeedbackButton = (
        <ButtonMain disabled={disabled} style={{width: "100%"}} className="buttonText" onClick={() => sendFeedback()}>
            {t('feedback.send')}
        </ButtonMain>
    )

    let backButton = (
        <ButtonSecondary style={{width: "100%"}} className="buttonText" onClick={() => goBack()}>
            {t('feedback.goBack')}
        </ButtonSecondary>
    );
 
    let searchView = (
        <Grid item xs={12} sm={8} className={'search-input'} >
            <InputBase className={'search-input-input'}
                       placeholder={t('feedback.code')}
                       value={code}
                       onChange={handleChange}
            />
            <Button onClick={handleSearch} className={'search-input-button'}>
                <img className={`search-input-button-icon-image`}
                     src={process.env.PUBLIC_URL + '/media/images/icons/search.svg'}
                     alt={t('components.search')}/>
            </Button>
        </Grid>
    );

    let inner = (
        <Grid container>
            <Grid item xs={12} className={"align-right"}>
                <Button href="/login">
                    <span>{t('feedback.toMainPage')}</span>
                </Button>
            </Grid>
            {currentMode === 0 && searchView}
            {currentMode > 0 && (
                <Grid item xs={12} sm={12}>
                    <Typography component="h1" className={'title small-title'}>
                        {t('feedback.functionName')+": "+name}
                     </Typography>
                </Grid>
            )}
            <Box 
                style={{width: "100%"}}
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
            {currentMode === 1 && useAgeAndGender &&  chooseSignUp}
            {currentMode === 1 && (useFeedbackRating || useFeedbackComment) && chooseFeedback}
            </Box>
            {currentMode === 3 && useAgeAndGender && ageComp}
            {currentMode === 3 && useAgeAndGender && genderComp}
            {currentMode === 2 && useFeedbackRating && feedbackRating}
            {currentMode === 2 && useFeedbackComment && feedbackComment}
            <Box 
                style={{width: "100%"}}
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
            {currentMode > 1 && (useAgeAndGender || useFeedbackComment || useFeedbackRating) && sendFeedbackButton}
            {currentMode > 1 && (useAgeAndGender || useFeedbackComment || useFeedbackRating) && backButton}
            </Box>
            {sent && (
                <Grid item xs={12} sm={12}>
                    <Typography component="h2" className={'title green'}>
                        {t('feedback.feedbackSent')}
                    </Typography>
                </Grid>
            )}
            {error && (
                <Grid item xs={12} sm={12}>
                    <Typography component="h2" className={'title red'}>
                        {t(error)}
                    </Typography>
                </Grid>
            )}
        </Grid>
    )

    return (
        <>
                {preview && (
                    <Grid container spacing={0} xs={12} sm={3}>
				        <img src={qr} />
                    </Grid>
                )} 
                {!preview && (
            <Grid container className={"feedbackRoot"}>
                <Grid item xs={false} sm={2} md={3} lg={4} className={"high feedbackbackground"}/>
                <Grid item xs={12} sm={8} md={6} lg={4} className={"mid feedbackmiddle"}>
                    {inner}
                </Grid>
                <Grid item xs={false} sm={2} md={3} lg={4} className={"high feedbackbackground"}/>
            </Grid>
                )}
        </>
    )
}

FeedbackComponent.propTypes = {
    label: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
    return {
        props: ownProps
    }
}
const mapDispatchToProps = dispatch => {
    return {
        searchFunction: (queryString, onSuccess, onFailure) => dispatch(searchFunction(queryString, onSuccess, onFailure)),
        sendFeedbackItem: (queryString, data, onSuccess, onFailure) => dispatch(sendFeedbackItem(queryString, data, onSuccess, onFailure))
    };
}

const Feedback = connect(mapStateToProps, mapDispatchToProps)(FeedbackComponent);

export {Feedback};