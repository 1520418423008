import React from "react";
import PropTypes from "prop-types";
import * as Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import { getComparator } from "../../../utils";

const BarChart = (props) => {
  const { data, valueField, argumentField } = props;

  const comparator = getComparator();
  const dataSorted = data.sort((a, b) => comparator.compare(a.title, b.title)).reverse();

  const options = {
    chart: {
      type: "bar",
      backgroundColor: "rgba(0,0,0,0)"
    },
    title: {
      text: null,
    },
    xAxis: {
      categories: dataSorted.map((item) => item[argumentField]),
      title: {
        text: null,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: null,
      },
    },
    legend: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    series: [
      {
        name: "",
        data: dataSorted.map((item) => item[valueField]),
        color: "#0E4264",
        pointWidth: 20
      },
    ],
  };

  return (
    <div className="testCharts">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

BarChart.propTypes = {
  data: PropTypes.array,
  valueField: PropTypes.string,
  argumentField: PropTypes.string,
};

export { BarChart };
