import React from 'react';
import {Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import {CompTextField} from "../../components/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {changePassword, changePasswordAPICallError, setPasswordChanged} from "./changepass.actions";
import {Copyright} from "../../components/Copyright";
import Alert from "@material-ui/lab/Alert";
import {ReactComponent as ErrorIcon} from "../../styles/images/IconError.svg";


class ChangePasswordComponent extends React.Component {

    constructor(props) {
        super(props);
        this.userId = this.props.match.params.userId;
        this.hash = this.props.match.params.hash;
        this.key = this.props.match.params.key;
        this.min_pass_length = 8;
        this.state = {
            error: {},
            password: '',
            passwordAgain: '',
            passwordChanged: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.state.password !== this.state.passwordAgain) {
            this.setState({
                error: {
                    passwordAgain: this.props.t("change_password.password_miss_match")
                }
            });
            return;
        }
        if (this.state.password.length < this.min_pass_length) {
            this.setState({
                error: {
                    passwordAgain: this.props.t("change_password.password_too_short")
                }
            });
            return;
        }
        if (!(/\d/.test(this.state.password)) ||
            !(/[a-zåäö]/.test(this.state.password)) ||
            !(/[A-ZÅÄÖ]/.test(this.state.password))) {
            this.setState({
                error: {
                    passwordAgain: this.props.t("change_password.must_contain_number_and_uppercase_and_lowercase_characters")
                }
            });
            return;
        }
        this.setState({error: {}});
        this.props.changePassword(this.userId, this.hash, this.key, this.state.password, this.state.passwordAgain, (data, response) => {
            this.setState({passwordChanged: true});
            return setPasswordChanged(data, response);
        }, (error) => {
            this.setState({
                error: {
                    submitError: this.props.t("change_password.submit_error")
                }
            });
            return changePasswordAPICallError(error);
        })
    }

    render() {
        const {t} = this.props;
        const alert = this.state.error;
        let alertComponent = (<div/>);
        if (alert !== undefined && alert.submitError !== undefined) {
            alertComponent = (
                <Alert
                    severity={'error'}
                    iconMapping={{
                        error: <ErrorIcon fontSize="inherit"/>,
                    }}
                >{alert.submitError}</Alert>
            );
        }

        let paper = (
            <div className={'paper'}>
                <div className={'paperHeader'}>
                    <img className={'appLogo'}
                         src={process.env.PUBLIC_URL + '/media/images/Logo_nuoDo_login.svg'}
                         alt={t('common.youth_work')}/>
                </div>
                <div className={'paperContent'}>
                    <Typography component="h1" className={'title'}>
                    {t('change_password.title')}
                </Typography>
                <Typography align={'center'} gutterBottom={true}>
                    {t('change_password.description')}
                </Typography>
                {alertComponent}
                <form className={'form'} noValidate autoComplete="off"
                      onSubmit={this.handleSubmit}>
                    <CompTextField
                        variant="outlined"
                        required
                        fullWidth
                        name="password"
                        label={t('change_password.password')}
                        type="password"
                        id="password"
                        errorAlreadyTranslated={true}
                        onChange={this.handleChange}
                        value={this.state.password}
                        autoComplete="current-password"
                    />
                    <CompTextField
                        variant="outlined"
                        required
                        fullWidth
                        name="passwordAgain"
                        label={t('change_password.password_again')}
                        type="password"
                        id="passwordAgain"
                        errorAlreadyTranslated={true}
                        onChange={this.handleChange}
                        value={this.state.passwordAgain}
                        error={this.state.error.passwordAgain}
                        autoComplete="current-password"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={'comp-button button-main submit'}
                    >
                        {t('change_password.send_button')}
                    </Button>
                </form>
                </div>
                <div className={'paperFooter'}>
                    <Copyright/>

                    <img
                        src={process.env.PUBLIC_URL + '/media/images/kuntaliittoLogo.svg'}
                        alt={t('common.youth_work')}/>
                </div>
            </div>
        )
        if (this.state.passwordChanged) {
            paper = (
                <div className={'paper'}>
                    <Typography component="h1" variant="h5" gutterBottom={true}>
                        {t('change_password.password_changed_title')}
                    </Typography>
                    <Typography align={'center'} gutterBottom={true}>
                        {t('change_password.password_changed_description')}
                    </Typography>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={'submit'}
                        href={'/login'}
                    >
                        {t('change_password.password_changed_send_button')}
                    </Button>
                    <Box mt={5}>
                        <Copyright/>
                    </Box>
                </div>
            )
        }

        return (
            <Grid container component="main" className={'loginRoot'}>
                <CssBaseline/>
                <Grid item xs={false} sm={4} md={7} className={'loginBackgroundImage'}/>
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    {paper}
                </Grid>
            </Grid>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changePassword: (userId, hash, key, password, passwordAgain, onSuccess, onFailure) => dispatch(changePassword(userId, hash, key, password, passwordAgain, onSuccess, onFailure)),
        setPasswordChanged: (data, response) => dispatch(setPasswordChanged(data, response))
    };
}

const ChangePassword = connect(
    null,
    mapDispatchToProps
)(withTranslation('translation')(ChangePasswordComponent));
export {ChangePassword};
