import types from "./location.types";
import {apiAction} from "../api/actions";
import settings from "../api/settings";


export const createLocation = (data, onSuccess) => {
    return apiAction({
        url: settings.baseURL + "/locations/",
        method: 'POST',
        data: data,
        onSuccess: onSuccess,
        onFailure: apiCallError,
        label: types.CREATE_LOCATION
    });
};

export const listLocations = (municipalityId, all = false) => {
    return apiAction({
        url: settings.baseURL + "/locations/municipality/"+municipalityId + (all === true ? "?all=true" : ""),
        onSuccess: setLocations,
        onFailure: apiCallError,
        label: types.FETCH_LOCATIONS_LIST
    });
}

const setLocations = data => {
    for (var i = 0; i < data.length; i++) {
        data[i].original = data[i].name;
    }
    return {
        type: types.SET_LOCATIONS_LIST,
        payload: data
    };
}

const apiCallError = error => {
    let data = "Connection error";
    if (error.response !== undefined) data = error.response.data;
    return {
        type: types.LOCATION_API_ERROR,
        payload: data
    }
}

export const getLocation = (id, onSuccess) => {
    if(onSuccess == null) {
        onSuccess = setLocation;
    }
    return apiAction({
        url: `${settings.baseURL}/locations/${id}/`,
        onSuccess: onSuccess,
        onFailure: apiCallError,
        label: types.FETCH_LOCATION
    });
}

export const setLocation = data => {
    return {
        type: types.SET_LOCATION,
        payload: data
    };
}

export const unsetLocation = () => {
    return {
        type: types.UNSET_LOCATION,
        payload: null
    };
}

export const saveLocation = (id, data, onSuccess) => {
    return apiAction({
        url: `${settings.baseURL}/locations/${id}/`,
        method: 'PUT',
        data: data,
        onSuccess: onSuccess,
        onFailure: apiCallError,
        label: types.UPDATE_LOCATION
    });
}

export const archiveLocation = (id,onSuccess) => {
    return apiAction({
        url: `${settings.baseURL}/locations/${id}/archive`,
        method: 'POST',
        onSuccess: onSuccess,
        onFailure: apiCallError,
        label: types.ARCHIVE_LOCATION
    });
};



