import {Accordion, AccordionDetails, AccordionSummary,} from "@material-ui/core";
import React from "react";
import * as PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {isFunction} from "../utils";


const CompAccordion = (props) => {
    const {id, label, error, children, onChange, isExpanded, ...componentProps} = props;

    let useState = true;
    if (isExpanded != null) {
        useState = false;
    }
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        if (isFunction(onChange)) {
            onChange(event, isExpanded);
        }
    };

    return (
        <Accordion
            className={`comp-accordion-container ${props.className ? 'container-' + props.className : ''} ${error ? 'error' : ''}`}
            expanded={useState ? expanded === id : isExpanded}
            onChange={handleChange(id)}
            {...componentProps}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls={`${id}-content`}
                className={`comp-accordion-summary ${props.className ? 'summary-' + props.className : ''}`}
                id={`${id}-header`}>
                <Typography className="header-text">{label} <span
                    className={`error-label comp-accordion-error ${props.className ? 'error-' + props.className : ''}`}>{error}</span>
                </Typography>
            </AccordionSummary>
            <AccordionDetails
                className={`comp-accordion-details ${props.className ? 'details-' + props.className : ''}`}>
                {children}
            </AccordionDetails>
        </Accordion>
    );
}

CompAccordion.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    error: PropTypes.string,
    onChange: PropTypes.func,
    isExpanded: PropTypes.bool
};

export {CompAccordion};