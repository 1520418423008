import React from 'react';
import {Grid, Modal} from "@material-ui/core";

import {connect} from "react-redux";
import {filterSystemLogs, listSystemLogs, restoreSystemLog, setSystemLog, unsetSystemLog} from "../systemlog.actions";
import {ButtonMain} from "../../../components/ButtonMain";
import {ButtonSpacer} from "../../../components/ButtonSpacer";
import {CompSystemLogTable} from "./systemlogTableComponent";

import {CompSearchInput} from "../../../components/SearchInput";
import {ButtonSecondary} from "../../../components/ButtonSecondary";
import {Trans, withTranslation} from "react-i18next";

import {getChoices} from "../../root/root.actions";
import {CompDatePicker} from "../../../components/DatePicker";
import {hasPermission, RESTORE_NATIONWIDE_CONTENT_IDENTIFIER} from "../../../permissions";
import {CompPartitionHeader} from "../../../components/PartitionHeader";
import {CompPartSelector as PartSelector} from "../../../components/PartSelector";

const mapStateToProps = (state) => {
    return {
        systemLogs: state.systemLog.systemLogs,
        selectedSystemLog: state.systemLog.selectedSystemLog,
        systemLogFieldErrors: state.systemLog.systemLogFieldErrors,
        authenticatedUser: state.auth.authenticatedUser,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        listSystemLogs: () => dispatch(listSystemLogs()),
        filterSystemLogs: (searchString, startDate, endDate, operation, category) => dispatch(filterSystemLogs(searchString, startDate, endDate, operation, category)),
        unsetSystemLog: () => dispatch(unsetSystemLog()),
        setSystemLog: selectedSystemLog => dispatch(setSystemLog(selectedSystemLog)),
        restoreSystemLog: (id, onSuccess) => dispatch(restoreSystemLog(id, onSuccess)),
        getChoices: () => dispatch(getChoices()),
    };
}

class SystemLogPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            restoreModalOpen: false,
            filterModalOpen: false,
            filter: '',
            startDate: null,
            endDate: null,
            operation: null,
            category: null
        }
        this.filteredRows = this.filteredRows.bind(this);
        this.search = this.search.bind(this);
        this.closeRestoreModal = this.closeRestoreModal.bind(this);
        this.restore = this.restore.bind(this);
        this.openRestoreModal = this.openRestoreModal.bind(this);
        this.openFilterModal = this.openFilterModal.bind(this);
        this.closeFilterModal = this.closeFilterModal.bind(this);
        this.changeFiltering = this.changeFiltering.bind(this);
        this.clearFiltering = this.clearFiltering.bind(this);
        this.handleStartChange = this.handleStartChange.bind(this);
        this.handleEndChange = this.handleEndChange.bind(this);
        this.handleOperationsChange = this.handleOperationsChange.bind(this);
        this.handleCategoriesChange = this.handleCategoriesChange.bind(this);
    }

    async componentDidMount() {
        this.props.listSystemLogs();
    }

    restore(event) {
        event.preventDefault()
        this.props.restoreSystemLog(this.props.selectedSystemLog.id, () => {
            this.setState({
                ...this.state,
                restoreModalOpen: false
            });
            this.props.listSystemLogs();

            return unsetSystemLog();
        })
        this.setState({...this.state, restoreModalOpen: false})
    }

    openRestoreModal(event) {
        event.preventDefault()
        this.setState({...this.state, restoreModalOpen: true})
    }

    closeRestoreModal(event) {
        event.preventDefault()
        this.setState({...this.state, restoreModalOpen: false})
    }

    openFilterModal(event) {
        event.preventDefault()
        this.setState({...this.state, filterModalOpen: true})
    }

    closeFilterModal(event) {
        event.preventDefault()
        this.setState({...this.state, filterModalOpen: false})
    }
    changeFiltering() {
        this.props.filterSystemLogs(
            this.state.filter,
            this.state.startDate,
            this.state.endDate,
            this.state.operation,
            this.state.category
        );
        this.setState({...this.state, filterModalOpen: false})
    }
    clearFiltering(event) {
        event.preventDefault()
        this.props.listSystemLogs();
        this.setState({...this.state,
            filter: '',
            startDate: null,
            endDate: null,
            operation: null,
            category: null
        })
    }
    handleStartChange = (e) => {
        this.props.filterSystemLogs(
            this.state.filter,
            e.target.value,
            this.state.endDate,
            this.state.operation,
            this.state.category
        );
        this.setState({
            ...this.state,
            'startDate': e.target.value
        })
    }
    handleEndChange = (e) => {
        this.props.filterSystemLogs(
            this.state.filter,
            this.state.startDate,
            e.target.value,
            this.state.operation,
            this.state.category
        );
        this.setState({
            ...this.state,
            'endDate': e.target.value
        })
    }
    handleOperationsChange(selection) {
        this.setState({
            ...this.state,
            operation: selection});
    }
    handleCategoriesChange(selection) {
        this.setState({
            ...this.state,
            category: selection});
    }

    selectSystemLog = (event, row, isAddAction /*, index */) => {
        event.preventDefault();
        if (isAddAction) {
            this.props.setSystemLog(row);
        } else {
            this.props.unsetSystemLog();
        }
    }

    search(e) {
        this.setState({filter: e.target.value})
    }

    filteredRows() {
        const lowerCasedFilter = this.state.filter.toLowerCase();
        return this.props.systemLogs.filter(item => {
            return Object.keys(item).some(key => {
                    if (typeof item[key] == 'string') {
                        return item[key].toLowerCase().includes(lowerCasedFilter)
                    } else {
                        return false;
                    }
                }
            );
        });
    }

    render() {
        let selectedName = "";
        if (this.props.selectedSystemLog && this.props.selectedSystemLog.id) {
            selectedName = this.props.selectedSystemLog.title
        }
        const {t} = this.props;

        let hasRestorePerms = false;
        let archiveButtons = [];

        if (hasPermission(RESTORE_NATIONWIDE_CONTENT_IDENTIFIER, this.props.authenticatedUser)) {
            // todo this should be extended by own municipality permissions
            hasRestorePerms = true;
        }

        if (hasRestorePerms) {
            archiveButtons.push(
                <ButtonMain className={'archive-button'}
                            disabled={!this.props.selectedSystemLog ||
                            !('id' in this.props.selectedSystemLog) ||
                            this.props.selectedSystemLog.operation !== 'archive'}
                            onClick={this.openRestoreModal}>
                    {t('common.restore')}
                </ButtonMain>
            );
        }

        return (
            <div>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={12} md={6}>
                                <div className={'MuiGrid-item-label label'}>{t('system_log.start_and_end_date')}</div>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <CompDatePicker
                                            value={this.state.startDate}
                                            name="startDate"
                                            onChange={this.handleStartChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <CompDatePicker
                                            value={this.state.endDate}
                                            name="endDate"
                                            onChange={this.handleEndChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <ButtonSpacer>
                                    <ButtonSecondary
                                        onClick={this.clearFiltering}
                                    >{t('system_log.clear_filter')}
                                    </ButtonSecondary>
                                    <ButtonMain
                                        onClick={this.openFilterModal}
                                    >{t('system_log.filter')}
                                    </ButtonMain>
                                </ButtonSpacer>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <CompSystemLogTable
                                    rows={this.filteredRows()}
                                    rowClick={this.selectSystemLog}
                                    label={t('system_log.logbook')}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <CompSearchInput label={t('system_log.search_log')}
                                                 onChange={this.search}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid container spacing={1}
                              direction="row"
                              justify="flex-end"
                              alignItems="center">
                            <Grid item xs={12} className={'align-right'}>
                                <ButtonSpacer>
                                    {archiveButtons}
                                </ButtonSpacer>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Modal
                    open={this.state.restoreModalOpen}
                    onClose={this.closeRestoreModal}
                >
                    <div className={'modal archiveModal'}>
                        <div className={'modalDescription'}>
                            <Trans i18nKey="system_log.restore_desc" selectedName={selectedName}/>
                        </div>
                        <div className={'modalQuestion'}>
                            {t('system_log.restore_question')}
                        </div>
                        <div className={'modalButtons'}>
                            <ButtonSpacer>
                                <ButtonSecondary onClick={this.closeRestoreModal}>
                                    {t('common.cancel')}
                                </ButtonSecondary>
                                <ButtonMain onClick={this.restore}>
                                    {t('common.restore')}
                                </ButtonMain>
                            </ButtonSpacer>
                        </div>

                    </div>
                </Modal>
                <Modal
                    open={this.state.filterModalOpen}
                    onClose={this.closeFilterModal}
                >
                    <div className={'modal systemLogFilterModal'}>
                        <div className={'title'}>
                            <CompPartitionHeader text={t('components.show')}/>
                        </div>
                        <div className={'selector'}>
                            <PartSelector
                                selectors={[
                                    {
                                        'key': "all",
                                        'label': t('system_log.all')
                                    },
                                    {
                                        'key': "edit",
                                        'label': t('system_log.edit')
                                    },
                                    {
                                        'key': "archive",
                                        'label': t('system_log.archive')
                                    },
                                    {
                                        'key': "create",
                                        'label': t('system_log.create')
                                    },
                                    {
                                        'key': "restore",
                                        'label': t('system_log.restore')
                                    },
                                ]}
                                onSelect={this.handleOperationsChange}
                                activeKey={this.state.operation}
                                title={t('system_log.operations')}
                            />
                        </div>
                        <div className={'selector'}>
                            <PartSelector
                                selectors={[
                                    {
                                        'key': "all",
                                        'label': t('system_log.all')
                                    },
                                    {
                                        'key': "areas",
                                        'label': t('common.areas')
                                    },
                                    {
                                        'key': "locations",
                                        'label': t('common.locations')
                                    },
                                    {
                                        'key': "functions",
                                        'label': t('common.functions')
                                    },
                                    {
                                        'key': "users",
                                        'label': t('common.users')
                                    },
                                ]}
                                onSelect={this.handleCategoriesChange}
                                activeKey={this.state.category}
                                title={t('system_log.categories')}
                            />
                        </div>
                        <div className={'modalButtons'}>
                            <ButtonSpacer>
                                <ButtonSecondary onClick={this.closeFilterModal}>
                                    {t('common.close')}
                                </ButtonSecondary>
                                <ButtonMain
                                    onClick={this.changeFiltering}
                                >
                                    {t('system_log.filter')}
                                </ButtonMain>
                            </ButtonSpacer>
                        </div>

                    </div>
                </Modal>
            </div>
        )
    }
}

const SystemLogPageForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation('translation')(SystemLogPage));

export {SystemLogPageForm};