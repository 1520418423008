import React from "react";
import {connect} from 'react-redux'
import {CompSearchList} from "../../../components/SearchList";
import {MUNICIPALITY_DRAFT} from "../../root/root.utils";
import {useTranslation} from "react-i18next";

const mapStateToProps = (state, ownProps) => {
    // return state;
    return {
        municipalities: state.municipality.municipalities,
        isLoadingMunicipalitiesListData: state.municipality.isLoadingMunicipalitiesListData,
        selectedMunicipality: state.municipality.selectedMunicipality,
        props: ownProps
    }
}
const ListMunicipalitiesComponent = ({
                                         municipalities,
                                         isLoadingMunicipalitiesListData,
                                         selectedMunicipality,
                                         props
                                     }) => {
    const {t} = useTranslation('translation');
    const onSelect = props.onSelect ? props.onSelect : null;
    return (
        <CompSearchList
            className={`listMunicipalitiesComponent ${isLoadingMunicipalitiesListData ? 'loading' : ''}`}
            rows={municipalities}
            keyColumn={'id'}
            valueFunc={(row) => {
                if(row.state === MUNICIPALITY_DRAFT) {
                    return row.name  + " - " +  t("municipality.draft")
                }
                return row.name
            }}
            classColumn={'state'}
            selectedRows={[selectedMunicipality.id]}
            onSelect={onSelect}>
            {t('common.select_municipality')}</CompSearchList>
    )
};

const ListMunicipalities = connect(mapStateToProps)(ListMunicipalitiesComponent);

export
{
    ListMunicipalities
};
