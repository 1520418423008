import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

const TooltipInfo = ({ titleText, onClose, severity, message }) => {
  return (
    <Tooltip title={titleText} enterTouchDelay={0}>
      <IconButton>
        <QuestionMarkIcon />
      </IconButton>
    </Tooltip>
  );
};

export default TooltipInfo;