import Typography from "@material-ui/core/Typography";
import {Drawer, List, ListItemText} from "@material-ui/core";
import React, {Component} from "react";
import {ListItemLink} from "./ListItemLink";
import {connect} from "react-redux";
import {closeMenu} from "../modules/root/root.actions";
import {withTranslation} from "react-i18next";
import {
    hasPermission,
    PERMISSION_AREAS,
    PERMISSION_CONTENT_IDENTIFIERS,
    PERMISSION_FUNCTIONS,
    PERMISSION_LOCATIONS, PERMISSION_REPORTS,
    PERMISSION_SYSTEM_LOG,
    PERMISSION_USERS
} from "../permissions";

const mapStateToProps = state => {
    return {
        mobileOpen: state.root.mobileOpen,
        leftDrawerOpen: state.root.leftDrawerOpen,
        breadcrumbs: state.root.breadcrumbs,
        authenticatedUser: state.auth.authenticatedUser,
    };

}
const mapDispatchToProps = dispatch => {
    return {
        closeMenu: () => dispatch(closeMenu())
    };
}


class LeftDrawerComponent extends Component {
    isActive = (path) => {
        if (this.props.breadcrumbs.length < 1) {
            return false;
        }
        const last = this.props.breadcrumbs[this.props.breadcrumbs.length - 1];
        return last.path === path;
    }

    render() {
        const {t} = this.props;

        const links = []

        if (hasPermission(PERMISSION_AREAS, this.props.authenticatedUser)) {
            links.push({
                path: "/municipality",
                label: t('common.areas'),
            });
        }
        if (hasPermission(PERMISSION_LOCATIONS, this.props.authenticatedUser)) {
            links.push({
                path: "/location",
                label: t('common.locations'),
            });
        }
        if (hasPermission(PERMISSION_FUNCTIONS, this.props.authenticatedUser)) {
            links.push({
                path: "/function",
                label: t('common.functions'),
            });
        }
        if (hasPermission(PERMISSION_CONTENT_IDENTIFIERS, this.props.authenticatedUser)) {
            links.push({
                path: "/contentIdentifier",
                label: t('common.content_identifiers'),
            });
        }
        if (hasPermission(PERMISSION_USERS, this.props.authenticatedUser)) {
            links.push({
                path: "/user",
                label: t('common.users'),
            });
        }
        if (hasPermission(PERMISSION_SYSTEM_LOG, this.props.authenticatedUser)) {
            links.push({
                path: "/systemLog",
                label: t('common.system_logs'),
            });
        }
        if (hasPermission(PERMISSION_REPORTS, this.props.authenticatedUser)) {
            links.push({
                path: "/report",
                label: t('common.reports'),

            });
        }

        return (
            <Drawer
                className={`leftDrawer ${this.props.mobileOpen && this.props.leftDrawerOpen ? 'active' : ''}`}
                variant="permanent"
                classes={{
                    paper: 'leftDrawerPaper'
                }}
            >
                <Typography className={'drawer-title leftDrawer-title'}>
                    <span>{t('menu.categories')}</span>
                </Typography>
                <List className={'drawer-list left-drawer-list'}>
                    <div>
                        {
                            links.map(({path, label, children}, index) => (
                                    <ListItemLink key={`item-link-${index}`} href={path}
                                                  className={`leftListItemLink ${this.isActive(path) ? 'active' : ''}`}>
                                        <ListItemText primary={label}/>
                                        {children}
                                    </ListItemLink>
                                )
                            )
                        }
                    </div>
                </List>
            </Drawer>
        );
    }

}

const LeftDrawer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation('translation')(LeftDrawerComponent));
export {LeftDrawer};