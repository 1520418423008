import React from 'react';
import {Grid} from "@material-ui/core";
import {CompPartSelector as PartSelector} from "../../components/PartSelector";
import {Application} from "../../application/MainApp";

import {connect} from "react-redux";
import {setBreadcrumbs} from "../root/root.actions";
import {FunctionPageForm} from "./components/functionPage";
import {withTranslation} from "react-i18next";


class FunctionComponent extends React.Component {
    constructor(props) {
        super(props);
        props.setBreadcrumbs(props.match);

        const action = this.props.match.params.action;
        const editid = this.props.match.params.editid;
        let activeTab = "history";
        if (action === 'create') {
            activeTab = "yourFunctions";
        }

        this.state = {
            activeTab: activeTab,
            action:action,
            editid:editid
        }
        this.handleTabChange = this.handleTabChange.bind(this);
    }

    handleTabChange(selection) {
        this.setState({...this.state, activeTab: selection});
    }

    render() {
        const { t } = this.props;
        let page = (<FunctionPageForm action={this.state.action} editid={this.state.editid} page={this.state.activeTab}/>)
        if (this.state.activeTab === 'yourFunctions') {
            page = (<FunctionPageForm  action={this.state.action} editid={this.state.editid} page={this.state.activeTab}/>)
        }
        return (
            <Application>
                <Grid container>
                    <Grid item xs={12}>
                        <PartSelector
                            selectors={[
                                {
                                    'key': "history",
                                    'label': t('function.history')
                                },
                                {
                                    'key': "yourFunctions",
                                    'label': t('function.your_functions')
                                },
                            ]}
                            onSelect={this.handleTabChange}
                        />
                    </Grid>
                </Grid>
                {page}
            </Application>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setBreadcrumbs: (match) => dispatch(setBreadcrumbs(match)),
    };
}


const Func = connect(
    null,
    mapDispatchToProps
)(withTranslation('translation')(FunctionComponent));

export {Func};