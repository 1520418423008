import React, { useEffect, useState } from 'react';
import {connect} from "react-redux";
import {apiAction} from "../api/actions";
import settings from "../api/settings";

const getVersionFunction = (onSuccess, onFailure) => {
    return apiAction({
        url: `${settings.baseURL}/version`,
        onSuccess: onSuccess,
        onFailure: onFailure,
        label: "something"
    });
}

const VersionComponent = ({getVersionFunction}) => {
    
    const [version, setVersion] = useState("loading");

    useEffect(() => {
        getVersionFunction((data, response) => {
            console.log(data);
            console.log(response);
            setVersion(data);
            return {type: "ignore", payload: "ignore"};
        },
        (data) => {
            setVersion("error");
            return {type: "ignore", payload: "ignore"};
        })
    });

    return (
        <p>{version}</p>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        getVersionFunction: (onSuccess, onFailure) => dispatch(getVersionFunction(onSuccess, onFailure)),
    };
}

const Version = connect(
    null,
    mapDispatchToProps
)(VersionComponent);

export {Version};