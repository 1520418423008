const types = {

    FETCH_SYSTEM_LOGS_LIST: 'systemLog/fetchSystemLogsList',
    SET_SYSTEM_LOGS_LIST: 'systemLog/setSystemLogsList',
    FETCH_SYSTEM_LOG: 'systemLog/fetchSystemLog',
    SET_SYSTEM_LOG: 'systemLog/setSystemLog',
    UNSET_SYSTEM_LOG: 'systemLog/unsetSystemLog',
    SYSTEM_LOG_API_ERROR: 'systemLog/systemLogAPIError',
    RESTORE_SYSTEM_LOG: 'systemLog/restoreSystemLog',
}

export default types;