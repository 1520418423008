import {API_END, API_START} from "../api/types";
import types from "./location.types";
import {types as auth_types} from "../auth/auth.types";

export const initialState = {
    locations: [],
    isLoadingLocationsListData: false,
    selectedLocation: {},
    locationFieldErrors: {},
}

export default function moduleReducer(state = initialState, action) {
    switch (action.type) {
        case auth_types.AUTH_LOGIN:
            return initialState;
        case auth_types.AUTH_LOGOUT:
            return initialState;
        case types.SET_LOCATIONS_LIST:
            return {
                ...state,
                locations: action.payload
            }
        case types.SET_LOCATION:
            return {
                ...state,
                locationFieldErrors: {},
                selectedLocation: action.payload
            }
        case types.UNSET_LOCATION:
            return {
                ...state,
                locationFieldErrors: {},
                selectedLocation: {}
            }
        case API_START:
            if (action.payload === types.FETCH_LOCATIONS_LIST) {
                return {
                    ...state,
                    isLoadingLocationsListData: true
                };
            }
            return state
        case API_END:
            if (action.payload === types.FETCH_LOCATIONS_LIST) {
                return {
                    ...state,
                    isLoadingLocationsListData: false
                };
            }
            return state
        case types.LOCATION_API_ERROR:
            if (action.payload == null) {
                return state;
            }
            if (typeof action.payload === "string") {
                console.log("API error: " + action.payload)
                return state
            } else if ('status' in action.payload && action.payload.status === 405) {
                console.log("API error: " + action.payload.error)
                return state
            } else if ('data' in action.payload && action.payload.data !== null) {
                console.log("API error: " + action.payload.message)
                return {
                    ...state,
                    locationFieldErrors: action.payload.data
                }
            } else {
                console.log("API error: " + action.payload)
                return state
            }

        default:
            // Do not use the default case
            // A dispatch action will pass through each of the reducers associated with this one.
            // An action at the other end of your application could affect this state
            // without being expressed in the code. This should be avoided.
            return state
    }
}
