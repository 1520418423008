/**
 * Fetch parameters or fail with a timeout
 *
 * @param {*} url passed as a parameter to fetch()
 * @param {*} options passed as a parameter to fetch()
 * @param {*} timeout milliseconds until request with fetch() times out
 */
export async function getParameters(
    url = "/service_cloudfront_public_runtime_parameters.json",
    options = {},
    timeout = 4200
) {
    async function _getParameters() {
        console.debug("Fetching service parameters from given url", url);
        const response = await Promise.race([
            fetch(url, options),
            new Promise((_, reject) =>
                setTimeout(() => reject(new Error("timeout")), timeout)
            ),
        ]);
        if (response.status >= 200 && response.status < 300) {
            console.debug(
                "Successfully fetched service parameters from given url",
                url
            );
            const service_cloudfront_public_runtime_parameters = await response.json();
            console.debug(
                "Parameters are",
                service_cloudfront_public_runtime_parameters
            );
            return service_cloudfront_public_runtime_parameters;
        }
        throw response;
    }
    try {
        return await _getParameters();
    } catch (e) {
        console.error(e);
    }
}