import React, {useEffect, useRef} from "react";

import {FormControl, FormLabel, Grid} from "@material-ui/core";
import {CompPartitionHeader} from "../../../components/PartitionHeader";
import * as PropTypes from "prop-types";
import {ButtonSpacer} from "../../../components/ButtonSpacer";
import {CompDatePicker} from "../../../components/DatePicker";
import {CompTimePicker} from "../../../components/TimePicker";
import {connect} from "react-redux";
import {getLocation, unsetLocation} from "../../location/location.actions";
import {CompDropdown} from "../../../components/Dropdown";
import {useTranslation} from "react-i18next";
import {formatISODateStr, formatTimeStr, generateKey, isFunction} from "../../../utils";
import {selectButtonType} from "../../../components/utils";
import moment from "moment";
import {CompTextField} from "../../../components/TextField";
import {CompRadioGroup} from "../../../components/RadioGroup";
import {hasPermission, PERMISSION_CREATE_SYSTEM_WIDE_NOTIFICATION} from "../../../permissions";

const HomeFormComponent = ({
                               municipalities,
                               authenticatedUser,
                               props
                           }) => {
    const {
        label,
        onChange,
        actionButtons,
        selectedNotification = {},
        fieldErrors = {},
    } = props;

    const {t} = useTranslation('translation');

    const mounted = useRef();
    useEffect(() => {
        if (!mounted.current) {
            // do componentDidMount logic

            if (!hasPermission(PERMISSION_CREATE_SYSTEM_WIDE_NOTIFICATION, authenticatedUser)) {

                const event = {
                    target: {
                        value: "ONE_MUNICIPALITY",
                        name: "notificationVisibility",
                    },
                };

                const data = {
                    notificationVisibility: "ONE_MUNICIPALITY",
                    municipalityId: authenticatedUser.mainMunicipalityId
                };
                if (isFunction(onChange)) {
                    onChange(event, data)
                }
            }
            mounted.current = true;
        }
    });

    const handleChange = (event) => {
        if (isFunction(onChange)) {
            onChange(event)
        }
    };

    const handleDateTimeChange = (event, fieldName) => {
        const data = {}

        let startDate = fieldName === 'startDate' ? event.target.value : formatISODateStr(selectedNotification.startDatetime);
        let startTime = fieldName === 'startTime' ? event.target.value : formatTimeStr(selectedNotification.startDatetime);
        let endDate = fieldName === 'endDate' ? event.target.value : formatISODateStr(selectedNotification.endDatetime);
        let endTime = fieldName === 'endTime' ? event.target.value : formatTimeStr(selectedNotification.endDatetime);

        if (fieldName === 'startDate' && startTime === undefined) {
            startTime = '00:00:00';
        }
        if (fieldName === 'startTime' && startDate === undefined) {
            startDate = formatISODateStr(Date.now());
        }
        if (fieldName === 'endDate' && endTime === undefined) {
            endTime = '23:59:59';
        }
        if (fieldName === 'endTime' && endDate === undefined) {
            endDate = formatISODateStr(Date.now());
        }

        if (fieldName === 'startDate' && !endDate) {
            endDate = startDate;
            if (!endTime) {
                endTime = '23:59:59';
            }

            data['endDatetime'] = moment.utc(endDate + "T" + endTime);
        }
        const startMoment = moment.utc(startDate + "T" + startTime);
        if (startDate && startTime) {
            data['startDatetime'] = startMoment;
        }

        const endMoment = moment.utc(endDate + "T" + endTime);
        if (endDate && endTime) {
            data['endDatetime'] = endMoment;
        }

        if (isFunction(onChange)) {
            onChange(event, data);
        }

    }

    const handleClick = (event, callback) => {
        event.preventDefault()
        const data = {...selectedNotification}
        callback(event, data);
    };

    let componentActionButtons = [];
    if (actionButtons) {
        actionButtons.forEach(function (button) {
            const key = generateKey('funcEditButton');
            selectButtonType(button, componentActionButtons, key, handleClick);
        });
    }

    const getMunicipalityOptions = () => {
        const response = [];
        for (const i in municipalities) {
            const municipality = municipalities[i];
            if (hasPermission(PERMISSION_CREATE_SYSTEM_WIDE_NOTIFICATION, authenticatedUser) ||
                authenticatedUser.mainMunicipalityId === municipality.id) {
                response.push({value: municipality.id, label: municipality.name})
            }
        }
        response.sort((a,b) => a.label.localeCompare(b.label));
        return response;
    }
    const getVisibilityChoices = () => {
        const visibilityChoices = [];
        if (hasPermission(PERMISSION_CREATE_SYSTEM_WIDE_NOTIFICATION, authenticatedUser)) {
            visibilityChoices.push(
                {
                    label: t("notification.allMunicipalities"),
                    value: "ALL_MUNICIPALITIES"
                }
            )
        }
        visibilityChoices.push(
            {
                label: t("notification.oneMunicipality"),
                value: "ONE_MUNICIPALITY"
            }
        )
        return visibilityChoices;
    }

    const notificationTypeChoices = [
        {
            label: t("notification.instruction"),
            value: "INSTRUCTION"
        },
        {
            label: t("notification.information"),
            value: "INFORMATION"
        },
        {
            label: t("notification.notification"),
            value: "NOTIFICATION"
        },
    ]


    return (
        <form noValidate autoComplete="off" className={"function-container"}>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <CompPartitionHeader text={label}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">{t('notification.notificationVisibility')}</FormLabel>
                                <CompRadioGroup name="notificationVisibility"
                                                selected={selectedNotification.notificationVisibility}
                                                error={fieldErrors.notificationVisibility}
                                                rows={getVisibilityChoices()}
                                                onChange={handleChange}/>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <CompDropdown
                                label={t('common.select_municipality')}
                                selectedValue={selectedNotification.municipalityId}
                                rows={getMunicipalityOptions()}
                                disabled={selectedNotification.notificationVisibility !== "ONE_MUNICIPALITY"}
                                name="municipalityId"
                                hasEmptyOption={true}
                                error={fieldErrors.municipalityId}
                                onChange={handleChange}/>

                        </Grid>
                        <Grid item xs={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">{t('notification.notificationType')}</FormLabel>
                                <CompRadioGroup name="notificationType"
                                                selected={selectedNotification.notificationType}
                                                error={fieldErrors.notificationType}
                                                rows={notificationTypeChoices}
                                                onChange={handleChange}/>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={'label'}>
                                {t('notification.start_and_end_date')}
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CompDatePicker
                                label={`${t('notification.start_date')}*`}
                                value={formatISODateStr(selectedNotification.startDatetime)}
                                name="startDate"
                                error={fieldErrors.startDatetime}
                                onChange={e => handleDateTimeChange(e, 'startDate')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CompDatePicker
                                label={t('notification.end_date')}
                                value={formatISODateStr(selectedNotification.endDatetime)}
                                name="endDate"
                                error={fieldErrors.endDatetime}
                                onChange={e => handleDateTimeChange(e, 'endDate')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                        <Grid item xs={12} md={6}>
                            <CompTimePicker
                                label={`${t('notification.start_time')}*`}
                                value={selectedNotification.startDatetime}
                                name="startTime"
                                error={fieldErrors.startDatetime}
                                onChange={e => handleDateTimeChange(e, 'startTime')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CompTimePicker
                                label={t('notification.end_time')}
                                value={selectedNotification.endDatetime}
                                name="endTime"
                                error={fieldErrors.endDatetime}
                                onChange={e => handleDateTimeChange(e, 'endTime')}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>

                    <CompTextField
                        label={t('notification.content')}
                        value={selectedNotification.content}
                        error={fieldErrors.content}
                        name="content"
                        multiline
                        rows={24}
                        onChange={handleChange}/>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} className={'align-right'}>
                    <ButtonSpacer>
                        {componentActionButtons}
                    </ButtonSpacer>
                </Grid>
            </Grid>
        </form>
    )
}

HomeFormComponent.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func,
    selectedNotification: PropTypes.object,
    fieldErrors: PropTypes.object,
    actionButtons: PropTypes.array,
};

const mapStateToProps = (state, ownProps) => {
    // return state;
    return {
        municipalities: state.municipality.municipalities,
        authenticatedUser: state.auth.authenticatedUser,
        props: ownProps
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getLocation: (id, onSuccess) => dispatch(getLocation(id, onSuccess)),
        unsetLocation: () => dispatch(unsetLocation()),
    };
}

const HomeForm = connect(mapStateToProps, mapDispatchToProps)(HomeFormComponent);

export {HomeForm};