import React from "react";
import * as PropTypes from "prop-types";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";


const CompReportOwnMunicipalityTableComponent = ({
                                                     props
                                                 }) => {
    const {
        rows = [],
        label = "",
    } = props;

    const {t} = useTranslation('translation');

    return (
        <div className={'CompSystemLogTable-wrapper'}>
            <div className={'CompSystemLogTable-mainTitle'}>{label}</div>
            <TableContainer component={Paper} className={'CompSystemLogTable-container'}>
                <Table className={'CompSystemLogTable-table'} aria-label={label}>
                    <TableHead>
                        <TableRow>
                            <TableCell/>
                            <TableCell>{t('report.monday')}</TableCell>
                            <TableCell>{t('report.tuesday')}</TableCell>
                            <TableCell>{t('report.wednesday')}</TableCell>
                            <TableCell>{t('report.thursday')}</TableCell>
                            <TableCell>{t('report.friday')}</TableCell>
                            <TableCell>{t('report.saturday')}</TableCell>
                            <TableCell>{t('report.sunday')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow
                                hover
                                tabIndex={-1}
                                key={`row-${index}`}
                            >
                                <TableCell component="th" scope="row">
                                    {t(`report.${row.title}`)}
                                </TableCell>
                                <TableCell>{row.monday}</TableCell>
                                <TableCell>{row.tuesday}</TableCell>
                                <TableCell>{row.wednesday}</TableCell>
                                <TableCell>{row.thursday}</TableCell>
                                <TableCell>{row.friday}</TableCell>
                                <TableCell>{row.saturday}</TableCell>
                                <TableCell>{row.sunday}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

CompReportOwnMunicipalityTableComponent.propTypes = {
    rows: PropTypes.array,
    label: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
    // return state;
    return {
        selectedSystemLog: state.systemLog.selectedSystemLog,
        props: ownProps
    }
}

const CompReportOwnMunicipalityTable = connect(mapStateToProps)(CompReportOwnMunicipalityTableComponent);

export {
    CompReportOwnMunicipalityTable,
}