import React from 'react';
import {Grid} from "@material-ui/core";
import {CompPartSelector as PartSelector} from "../../components/PartSelector";
import {Application} from "../../application/MainApp";
import {MunicipalityPageForm} from "./components/municipalityPage";
import {OrganizationPageForm} from "./components/organizationPage";
import {connect} from "react-redux";
import {setBreadcrumbs} from "../root/root.actions";
import {withTranslation} from "react-i18next";


class MunicipalityComponent extends React.Component {
    constructor(props) {
        super(props);
        props.setBreadcrumbs(props.match);
        this.state = {
            activeTab: "municipality",
        }
        this.handleTabChange = this.handleTabChange.bind(this);
    }

    handleTabChange(selection) {
        this.setState({...this.state, activeTab: selection});
    }

    render() {
        let page = (<MunicipalityPageForm/>)
        if (this.state.activeTab === 'organization') {
            page = (<OrganizationPageForm/>)
        }
        const { t } = this.props;

        return (
            <Application>
                <Grid container>
                    <Grid item xs={12}>
                        <PartSelector
                            selectors={[
                                {
                                    'key': "municipality",
                                    'label': t('municipality.municipality_info')
                                },
                                {
                                    'key': "organization",
                                    'label': t('municipality.organization_info')
                                },
                            ]}
                            onSelect={this.handleTabChange}
                        />
                    </Grid>
                </Grid>
                {page}
            </Application>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setBreadcrumbs: (match) => dispatch(setBreadcrumbs(match)),
    };
}


const Municipality = connect(
    null,
    mapDispatchToProps
)(withTranslation('translation')(MunicipalityComponent));

export {Municipality};