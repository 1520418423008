import types from "./function.types";
import {apiAction} from "../api/actions";
import settings from "../api/settings";

export const setEmptyAction = data => {
    return {
        type: types.SET_EMPTY_ACTION,
        payload: data
    }
}

const setNewLink = data => {
    return {
        type: types.UPDATE_FUNCTION_LINKS,
        payload: data
    }
}

const setFeedbackReport = data => {
    return {
        type: types.FEEDBACK_REPORT,
        payload: data
    }
}

const setFeedbackList = data => {
    return {
        type: types.LIST_FEEDBACK,
        payload: data
    }
}

export const setFunctions = data => {
    return {
        type: types.SET_FUNCTIONS_LIST,
        payload: data
    };
}
const setFunctionTemplates = data => {
    return {
        type: types.SET_FUNCTION_TEMPLATES_LIST,
        payload: data
    }
}

export const setYouthData = data => {
    return {
        type: types.SET_YOUTHINFO,
        payload: data
    }
}

const functionAPICallError = error => {
    let data = "Connection error";
    console.log(error);
    if (error.response !== undefined) data = error.response.data;
    return {
        type: types.FUNCTION_API_ERROR,
        payload: data
    }
}

export const createFunction = (data, createTemplate, onSuccess, onlyTemplate = false) => {
    let url = `${settings.baseURL}/functions/`;
    if (createTemplate) {
        url = `${settings.baseURL}/functions/template`;
        if (onlyTemplate) {
            url = `${settings.baseURL}/functions/template/new`;
        }
    }
    return apiAction({
        url: url,
        method: 'POST',
        data: data,
        onSuccess: onSuccess,
        onFailure: functionAPICallError,
        label: types.CREATE_FUNCTION
    });
};

//municipalityId, functionType, startDate, endDate, filter, rows, 
export const listFunctions = (filters, onSuccess) => {
    let queryStr = "";
    if (filters != null && filters.municipalityId != null) {
        queryStr = `?municipalityId=${filters.municipalityId}&functionType=${filters.functionType}` 
        + (filters.startDate != null ? `&startDate=${filters.startDate}` : "") 
        + (filters.endDate != null ? `&endDate=${filters.endDate}` : "") 
        + (filters.rows != null ?  `&limit=${filters.rows}` : "")
        + (filters.filter != null ?  `&filter=${filters.filter}` : "")
        + (filters.organizerId != null ? `&organizerId=${filters.organizerId}` : ""); 
    }
    return _listFunctions(queryStr, onSuccess);
}

export const listOwnFunctions = (userId, onSuccess) => {
    let queryStr = "";
    if (userId != null) {
        queryStr = `?organizerId=${userId}`
    }
    return _listFunctions(queryStr, onSuccess);
}

export const getFeedbackReport = (eventId) => {
    return apiAction({
        url: `${settings.baseURL}/functions/feedback/report/${eventId}`,
        onSuccess: setFeedbackReport,
        onFailure: functionAPICallError,
        label: types.FEEDBACK_REPORT

    })
}

export const getYouthCardInfo = (eventId) => {
    return apiAction({
        url: `${settings.baseURL}/youth-card/get/${eventId}`,
        onSuccess: setYouthData,
        onFailure: functionAPICallError,
        label: types.FETCH_YOUTHINFO
    }
    )
}

export const recreateLinksForFunction = (functionId) => {
    return apiAction({
        url: `${settings.baseURL}/functions/updatecode/${functionId}`,
        onSuccess: setNewLink,
        onFailure: functionAPICallError,
        label: types.UPDATE_FUNCTION_LINKS
    })
}

const _listFunctions = (queryString, onSuccess) => {
    if(onSuccess === undefined) {
        onSuccess = setFunctions;
    }
    return apiAction({
        url: `${settings.baseURL}/functions/${queryString}`,
        onSuccess: onSuccess,
        onFailure: functionAPICallError,
        label: types.FETCH_FUNCTIONS_LIST
    });
}

export const paginatedListFunctions = (municipalityId, functionType, startDateEnd, organizerId, referenceId, limit, direction, onSuccess) => {
    let queryStr = "?";

    if (limit != null) {
        queryStr += `&limit=${limit}`
    }
    if (referenceId != null) {
        queryStr += `&referenceId=${referenceId}`
    }
    if (direction != null) {
        queryStr += `&direction=${direction}`
    }
    if (municipalityId != null) {
        queryStr += `&municipalityId=${municipalityId}`
    }
    if (functionType != null) {
        queryStr += `&functionType=${functionType}`
    }
    if (startDateEnd != null) {
        queryStr += `&startDateEnd=${startDateEnd}`
    }
    if (organizerId != null) {
        queryStr += `&organizerId=${organizerId}`
    }
    return apiAction({
        url: `${settings.baseURL}/functions/${queryStr}`,
        onSuccess: onSuccess,
        onFailure: functionAPICallError,
        label: types.FETCH_FUNCTIONS_LIST
    });
}

export const deleteFeedback = (feedbackItemId) => {
    return apiAction({
        url: `${settings.baseURL}/functions/feedback/delete/${feedbackItemId}`,
        onSuccess: setFeedbackList,
        onFailure: functionAPICallError,
        label: types.LIST_FEEDBACK
    });
}

export const listFeedback = (eventId) => {
    return apiAction({
        url: `${settings.baseURL}/functions/feedback/list/${eventId}`,
        onSuccess: setFeedbackList,
        onFailure: functionAPICallError,
        label: types.LIST_FEEDBACK
    });
}

export const listFunctionTemplates = (municipalityId) => {
    return apiAction({
        url: `${settings.baseURL}/functions/template/${municipalityId}`,
        onSuccess: setFunctionTemplates,
        onFailure: functionAPICallError,
        label: types.FETCH_FUNCTIONS_LIST
    });
}

export const getFunction = id => {
    return apiAction({
        url: `${settings.baseURL}/functions/${id}/`,
        onSuccess: setFunction,
        onFailure: functionAPICallError,
        label: types.FETCH_FUNCTION
    });
}

export const setFunction = data => {
    return {
        type: types.SET_FUNCTION,
        payload: data
    };
}

export const unsetFunction = () => {
    return {
        type: types.UNSET_FUNCTION,
        payload: null
    };
}

export const saveFunction = (id, data, onSuccess) => {
    let url = `${settings.baseURL}/functions/${id}/`;
    return apiAction({
        url: url,
        method: 'PUT',
        data: data,
        onSuccess: onSuccess,
        onFailure: functionAPICallError,
        label: types.UPDATE_FUNCTION
    });
}

export const archiveFunction = (id, onSuccess) => {
    return apiAction({
        url: `${settings.baseURL}/functions/${id}/archive`,
        method: 'POST',
        onSuccess: onSuccess,
        onFailure: functionAPICallError,
        label: types.ARCHIVE_FUNCTION
    });
};

export const updateFunctionTemplate = (id, data, onSuccess) => {
    let url = `${settings.baseURL}/functions/template/${id}/`;
    return apiAction({
        url: url,
        method: 'PUT',
        data: data,
        onSuccess: onSuccess,
        onFailure: functionAPICallError,
        label: types.UPDATE_FUNCTION_TEMPLATE
    });
}

export const deleteFunctionTemplate = (id, onSuccess) => {
    return apiAction({
        url: `${settings.baseURL}/functions/template/${id}/delete`,
        method: 'POST',
        onSuccess: onSuccess,
        onFailure: functionAPICallError,
        label: types.DELETE_FUNCTION_TEMPLATE
    });
};

export const removemeFunctionTemplate = (id, onSuccess) => {
    return apiAction({
        url: `${settings.baseURL}/functions/template/${id}/removeme`,
        method: 'POST',
        onSuccess: onSuccess,
        onFailure: functionAPICallError,
        label: types.REMOVEME_FUNCTION_TEMPLATE
    });
}




