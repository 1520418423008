import {Button, List, ListItem, ListItemText,} from "@material-ui/core";
import React from "react";
import * as PropTypes from "prop-types";
import {getComparator, isFunction, valueFoundInArray} from "../utils";
import {useTranslation} from "react-i18next";


const CompListTree = (props) => {
    const { t } = useTranslation('translation');

    let {
        error,
        } = props;

    const {
        rows,
        errorAlreadyTranslated=false,
        selectedRows = [],
        initialOpenedRows = [],
        keyColumn,
        valueColumn,
        children,
        disabled,
        onSelect,
        className,
        wholeTreeOpen = false,
        ...componentProps} = props;

    const [open, setOpen] = React.useState(initialOpenedRows);

    if(error && !errorAlreadyTranslated) {
        error = t("errors."+error);
    }

    const handleSelectionToggle = (row, index) => () => {
        if(disabled) return;

        const i = selectedRows.indexOf(row[keyColumn])
        const isAddAction = i < 0


        if (isFunction(onSelect)) {
            onSelect(row, isAddAction, index, i)
        }
    }

    const handleListItemOpen = (key, event) => {
        event.stopPropagation();
        event.preventDefault();
        if(disabled) return;

        const newOpen = [...open]

        if (isOpen(key)) {
            // remove
            const i = open.indexOf(key)
            newOpen.splice(i, 1)
        } else {
            // add
            newOpen.push(key)
        }
        setOpen(newOpen);
    };

    const isOpen = key => {
        if(wholeTreeOpen) return true;
        return valueFoundInArray(key, open);
    }
    const hasChildren = row => {
        return 'children' in row && row.children.length > 0
    }

    const loopRows = (rows, parentKey, level) => {
        if(!rows) {
            return;
        }
        if(!level) {
            level = 0
        }
        if(level > 0 && !isOpen(parentKey)) {
            return;
        }
        const comparator = getComparator()
        return (
            <div>
                {rows.sort((a, b) => comparator.compare(a[valueColumn],b[valueColumn])).map((row, index) => (
                    <div key={`list-tree-row-${index}`}>
                        <ListItem key={row[keyColumn]}
                                  role={undefined}
                                  dense
                                  button
                                  disabled={disabled}
                                  className={`list-tree-item list-tree-item-${level} ${valueFoundInArray(row[keyColumn], selectedRows) ? 'selected' : ''}`}
                                  onClick={handleSelectionToggle(row, index)}>
                            <Button disabled={disabled}
                                    onClick={(e) => handleListItemOpen(row[keyColumn], e)}
                                    className={`tree-opener-icon ${hasChildren(row) ? '' : 'invisible'}`}>
                                <img className={`tooltip-icon-image ${isOpen(row[keyColumn]) ? 'hidden' : ''}`}
                                     src={process.env.PUBLIC_URL + '/media/images/icons/tree/ButtonTreePlus.svg'}
                                     alt={t('common.plus')} />
                                <img className={`tooltip-icon-image ${isOpen(row[keyColumn]) ? '' : 'hidden'}`}
                                     src={process.env.PUBLIC_URL + '/media/images/icons/tree/ButtonTreeMinus.svg'}
                                     alt={t('common.minus')} />
                            </Button>
                            <ListItemText
                                id={`list-tree-item-label-${row[keyColumn]}`}
                                primary={row[valueColumn]}
                            />
                        </ListItem>
                        {loopRows(row.children, row[keyColumn], level +1)}
                    </div>
                ))}
            </div>
        )

    }

return (
    <div
         className={`list-tree ${className} ${disabled ? 'disabled': ''} ${error ? 'error':''}`}
         {...componentProps}>
        <div className={'list-tree-title'}>
            {children}
        </div>
        <List className={'list-tree-container'}>
            {loopRows(rows)}
        </List>
        <div className={`error-label`}>{error}</div>
    </div>
);
}

CompListTree.propTypes =
    {
        keyColumn: PropTypes.string,
        valueColumn: PropTypes.string,
        rows: PropTypes.array,
        selectedRows: PropTypes.array,
        openedRows: PropTypes.array,
        onSelect: PropTypes.func,
        wholeTreeOpen: PropTypes.bool
    };

export {CompListTree};