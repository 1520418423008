import React from "react";
import {useTranslation} from "react-i18next";


const BalloonNotification = (props) => {
    const {title = null, content, severity = "", extraElements = [], image = null, ...componentProps} = props;
    const {t} = useTranslation('translation');
    let imageComponent = null;
    if (image) {
        imageComponent = (<img className="icon-image"
                               src={image}
                               alt={t('common.icon')}/>);
    }
    let titleText = "?";
    if(title !== null) {
        titleText = title;
    }

    return (
        <div {...componentProps} className={'balloon-notification-container'}>
            <div className={'balloon-notification-top'}>
                <div>
                    <div className={`title ${severity}`}>
                        {imageComponent}
                        <div>{titleText}</div>

                    </div>
                </div>
                <div className={'extra-elements'}>
                    {extraElements.map((element, index) => (
                        <div
                            className={`extra-element`}
                            key={`extra-element-${index}`}>
                            {element}
                        </div>
                    ))}
                </div>
            </div>
            <div className={'content mainPageText'}>
                {content}
            </div>
        </div>
    );
}

export {BalloonNotification}