export const genderOptions = [
    {
        label: 'function.men',
        value: "1"
    },
    {
        label: 'function.women',
        value: "2"
    },
    {
        label: 'function.other',
        value: "3"
    },
    {
        label: 'feedback.not_answered',
        value: "0"
    },
]

export const ageOptions = [
    {
        label: 'function.age_1_to_8',
        value: "1"
    },
    {
        label: 'function.age_9_to_12',
        value: "2"
    },
    {
        label: 'function.age_13_to_15',
        value: "3"
    },
    {
        label: 'function.age_16_to_17',
        value: "4"
    },
    {
        label: 'function.age_18_to_28',
        value: "5"
    },
    {
        label: 'function.age_29_or_more',
        value: "6"
    },
    {
        label: 'feedback.not_answered',
        value: "0"
    },
]