import * as PropTypes from "prop-types";
import React from "react";
import {
  Chart,
  Legend,
  PieSeries,
  Tooltip,
} from "@devexpress/dx-react-chart-material-ui";
import { Animation, EventTracker } from "@devexpress/dx-react-chart";
import { withStyles } from "@material-ui/core/styles";

const legendStyles = () => ({
  root: {
    maxHeight: "300px",
    minHeight: "300px",
    overflowX: "auto",
    width: "100%",
  },
});

const legendRootBase = ({ classes, ...restProps }) => (
  <Legend.Root {...restProps} className={classes.root} />
);

const Root = withStyles(legendStyles, { name: "LegendRoot" })(legendRootBase);

const legendLabelStyles = () => ({
  label: {
    whiteSpace: "nowrap",
  },
});

const legendLabelBase = ({ classes, ...restProps }) => (
  <Legend.Label className={classes.label} {...restProps} />
);

const Label = withStyles(legendLabelStyles, { name: "LegendLabel" })(
  legendLabelBase
);

const DonutChart = (props) => {
  let { data, valueField, argumentField, t, translationPath } = props;

  const filteredData = data.filter((item) => item.value !== 0);

  const translatedData = filteredData.map((item) => ({
    ...item,
    title: `${t(`${translationPath}.${item.title}`)}: ${item.value}`, 
  }));

  return (
    <Chart data={translatedData}>
      <PieSeries
        name={Label}
        valueField={valueField}
        argumentField={argumentField}
        innerRadius={0.5}
      />
      <Animation />
      <Legend position="bottom" rootComponent={Root} labelComponent={Label} />
      <EventTracker />
      <Tooltip/>
    </Chart>
  );
};

/*
max-height: 306px;
    overflow-x: auto;
 */

DonutChart.propTypes = {
  data: PropTypes.array,
  valueField: PropTypes.string,
  argumentField: PropTypes.string,
  t: PropTypes.any,
  translationPath: PropTypes.string,
};

export { DonutChart };

