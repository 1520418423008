import React, {useEffect, useRef} from "react";
import {generateKey, isFunction} from "../utils";
import * as PropTypes from "prop-types";
import moment from "moment";
import TextField from '@material-ui/core/TextField';
import InputMask from "react-input-mask";
import {useTranslation} from "react-i18next";

const CompTimePicker = (props) => {
    const {t} = useTranslation('translation');
    const key = generateKey('timepicker');
    let {label, error, value = null, id, disabled, onChange, ...componentProps} = props;

    const format = "HH:mm:ss"
    const container = React.createRef();
    const [selectedTime, setSelectedTime] = React.useState(moment.utc(value).format(format));
    const [errorText, setErrorText] = React.useState(error);

    const mounted = useRef();
    useEffect(() => {
        if (!mounted.current) {
            // do componentDidMount logic
            mounted.current = true;
        } else {
            // do componentDidUpdate logic
            if (selectedTime.includes("_")) return;
            const currentGivenValue = moment.utc(value).format(format);
            if(currentGivenValue !== selectedTime) {
                setSelectedTime((currentGivenValue));
            }
        }
    },[selectedTime, value]);

    const handleChange = (event) => {
        event.stopPropagation();
        event.preventDefault();

        let time = event.target.value

        setSelectedTime(time);

        if (time.includes("_")) return;

        let timeObj = moment.utc(time, 'HH:mm');

        if (!timeObj.isValid()) {
            setErrorText(t('errors.invalid_value'));
            return;
        }
        setErrorText(null);



        if (isFunction(onChange)) {
            const data = {
                target: {
                    ...componentProps,
                    value: timeObj.set('second', 0).format(format),
                    id: key,
                },
            };
            onChange(data)
        }
    };

    return (
        <div
            id={`comp-timepicker-container-${key}`}
            className={`comp-timepicker-container ${props.className ? 'container-' + props.className : ''} ${errorText ? 'error' : ''}  ${disabled ? 'disabled': ''}`}
            ref={container}>
            <div className={'label'}>{label}</div>
            <InputMask
                {...componentProps}
                disabled={disabled}
                mask={"99:99"}
                value={selectedTime}
                maskChar="_"
                onChange={handleChange}>
                {() => <TextField
                    {...componentProps}
                    disabled={disabled}
                />}
            </InputMask>
            <div className={`error-label ${props.className ? 'error-' + props.className : ''}`}>{errorText}</div>
        </div>
    );
}

CompTimePicker.propTypes = {
    label: PropTypes.string,
    error: PropTypes.string,
    value: PropTypes.object,
    onChange: PropTypes.func
};

export {CompTimePicker};