const types = {
    CREATE_LOCATION: 'location/createLocation',
    FETCH_LOCATIONS_LIST: 'location/fetchMunicipalitiesList',
    SET_LOCATIONS_LIST: 'location/setMunicipalitiesList',
    FETCH_LOCATION: 'location/fetchLocation',
    SET_LOCATION: 'location/setLocation',
    UNSET_LOCATION: 'location/unsetLocation',
    UPDATE_LOCATION: 'location/updateLocation',
    LOCATION_API_ERROR: 'location/APIError',
    ARCHIVE_LOCATION: 'location/archiveLocation',
}

export default types;