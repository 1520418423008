import types from "./systemlog.types";
import {apiAction} from "../api/actions";
import settings from "../api/settings";

const setSystemLogs = data => {
    return {
        type: types.SET_SYSTEM_LOGS_LIST,
        payload: data
    };
}

const systemLogAPICallError = error => {
    let data = "Connection error";
    if (error.response !== undefined) data = error.response.data;
    return {
        type: types.SYSTEM_LOG_API_ERROR,
        payload: data
    }
}

export const listSystemLogs = () => {
    return filterSystemLogs(null, null, null);
}

export const filterSystemLogs = (searchString, startDate, endDate, operation, category) => {
    const queryArray = [];
    if (searchString !== undefined && searchString !== null) {
        queryArray.push(`search=${searchString}`)
    }
    if (startDate !== undefined && startDate !== null) {
        queryArray.push(`startDate=${startDate}`)
    }
    if (endDate !== undefined && endDate !== null) {
        queryArray.push(`endDate=${endDate}`)
    }
    if (operation !== undefined && operation !== null) {
        queryArray.push(`operation=${operation}`)
    }
    if (category !== undefined && category !== null) {
        queryArray.push(`category=${category}`)
    }

    let queryStr = "";
    if(queryArray.length > 0) {
        queryStr = "?" + queryArray.join("&")
    }
    let url = `${settings.baseURL}/system_logs${queryStr}`;
    return apiAction({
        url: url,
        onSuccess: setSystemLogs,
        onFailure: systemLogAPICallError,
        label: types.FETCH_SYSTEM_LOGS_LIST
    });
}

export const setSystemLog = data => {
    return {
        type: types.SET_SYSTEM_LOG,
        payload: data
    };
}

export const unsetSystemLog = () => {
    return {
        type: types.UNSET_SYSTEM_LOG,
        payload: null
    };
}


export const restoreSystemLog = (id,onSuccess) => {
    return apiAction({
        url: `${settings.baseURL}/system_logs/${id}/restore`,
        method: 'POST',
        onSuccess: onSuccess,
        onFailure: systemLogAPICallError,
        label: types.RESTORE_SYSTEM_LOG
    });
};


