import React, {useEffect, useRef} from "react";
import {generateKey, isFunction} from "../../../utils";
import * as PropTypes from "prop-types";
import TextField from '@material-ui/core/TextField';
import {useTranslation} from "react-i18next";

const EventHoursTimePicker = (props) => {
    const {t} = useTranslation('translation');
    const key = generateKey('timepicker');
    let {label, error, value = null, id, disabled, onChange, ...componentProps} = props;

    let hours = null;
    let mins = null;
    if (value !== null) {
        hours = Math.trunc(value);
        mins = Math.round((value * 60) % 60);
    }
    const container = React.createRef();
    const [selectedHours, setSelectedHours] = React.useState(hours);
    const [selectedMinutes, setSelectedMinutes] = React.useState(mins);
    const [lastValue, setLastValue] = React.useState(value);
    const [errorText, setErrorText] = React.useState(error);

    const mounted = useRef();
    useEffect(() => {
        if (!mounted.current) {
            // do componentDidMount logic
            mounted.current = true;
        } else {
            // do componentDidUpdate logic
            if (lastValue !== value) {
                let hours = Math.trunc(value);
                if (hours === 0) hours = "";

                let mins = Math.round((value * 60) % 60);
                if (mins === 0) mins = "";

                if (hours !== selectedHours) setSelectedHours(hours)
                if (mins !== selectedMinutes) setSelectedMinutes(mins)
                setLastValue(value)
            }
        }
    }, [selectedHours, selectedMinutes, lastValue, setSelectedHours, setSelectedMinutes, setLastValue, value]);

    const getValue = () => {
        let hours = null;
        let mins = null;
        if (value !== null) {
            hours = Math.trunc(value).toString();
            mins = Math.round((value * 60) % 60).toString();
        }
        if (hours == null) hours = "00";
        if (mins == null) mins = "00";
        if (mins.length < 2) mins = "0" + mins;
        return `${hours}h ${mins}m`
    }
    const setValue = (hours, mins) => {
        if (mins === undefined || mins === null) mins = 0;
        value = Math.round((hours + (mins / 60)) * 100) / 100;

        if (isFunction(onChange)) {
            const data = {
                target: {
                    ...componentProps,
                    value: value.toString(),
                    id: key,
                },
            };
            onChange(data)
        }
    }
    const isValidValue = (hours, mins) => {
        if (hours < 0 || hours > 999) {
            setErrorText(t('errors.invalid_value'));
            return false;
        }

        if (mins < 0 || mins > 59) {
            setErrorText(t('errors.invalid_value'));
            return false;
        }
        setErrorText(null);
        return true
    }

    const handleHourChange = (event) => {
        event.stopPropagation();
        event.preventDefault();

        let hours = 0;
        if (event.target.value !== null && event.target.value !== '') hours = parseInt(event.target.value)

        if (!isValidValue(hours, selectedMinutes)) return;

        setValue(hours, selectedMinutes)
    };

    const handleMinuteChange = (event) => {
        event.stopPropagation();
        event.preventDefault();

        let mins = 0;
        if (event.target.value !== null && event.target.value !== '') mins = parseInt(event.target.value)

        if (mins < 0 || mins > 59) {
            setErrorText(t('errors.invalid_value'));
            return;
        }

        if (!isValidValue(selectedHours, mins)) return;

        setValue(selectedHours, mins);
    };

    const debounce = (func, delay) => {
        let debounceTimer;
        return function () {
            const context = this;
            const args = arguments;
            clearTimeout(debounceTimer);
            debounceTimer =
                setTimeout(() => func.apply(context, args), delay);
        }
    }

    const hourChangeWrapper = (func) => {
        return function () {
            const context = this;
            const args = arguments;
            const e = args[0];
            let hours = null;
            if (e.target.value !== null && e.target.value !== '') hours = parseInt(e.target.value)
            if (hours !== null && hours < 0) hours = 0;
            if (hours !== null && hours > 999) hours = parseInt(hours.toString().substring(0, 3));
            e.target.value = hours;
            setSelectedHours(hours)
            func.apply(context, args);
        }
    }
    const minsChangeWrapper = (func) => {
        return function () {
            const context = this;
            const args = arguments;
            const e = args[0];
            let mins = null;
            if (e.target.value !== null && e.target.value !== '') mins = parseInt(e.target.value)
            if (mins < 0) mins = 0;
            if (mins > 59) mins = 59;
            e.target.value = mins;
            setSelectedMinutes(mins)
            func.apply(context, args);
        }
    }

    let optimisedHandleHourChange = hourChangeWrapper(debounce(handleHourChange, 300));
    let optimisedHandleMinuteChange = minsChangeWrapper(debounce(handleMinuteChange, 300));

    return (
        <div
            id={`event-hours-timepicker-container-${key}`}
            className={`event-hours-timepicker-container ${props.className ? 'container-' + props.className : ''} ${errorText ? 'error' : ''}  ${disabled ? 'disabled' : ''}`}
            ref={container}>
            <div className={'label'}>{label} ({getValue()})</div>
            <div className={'fields'}>
                <TextField
                    onChange={optimisedHandleHourChange}
                    value={selectedHours}
                    placeholder={"hhh"}
                    type="number"
                    disabled={disabled}
                />:
                <TextField
                    onChange={optimisedHandleMinuteChange}
                    value={selectedMinutes}
                    placeholder={"mm"}
                    type="number"
                    disabled={disabled}
                />
            </div>


            <div className={`error-label ${props.className ? 'error-' + props.className : ''}`}>{errorText}</div>
        </div>
    );
}

EventHoursTimePicker.propTypes = {
    label: PropTypes.string,
    error: PropTypes.string,
    value: PropTypes.object,
    onChange: PropTypes.func
};

export {EventHoursTimePicker};