const types = {
    MENU_TOGGLED: 'root/menuOpened',
    LEFT_DRAWER_SELECTED: 'root/leftDrawerSelected',
    RIGHT_DRAWER_SELECTED: 'root/rightDrawerSelected',
    MENU_CLOSED: 'root/menuClosed',
    SET_BREADCRUMBS: 'root/setBreadcrumbs',
    ALERT_CLOSED: 'root/alertClosed',
    SET_ALERT: 'root/setAlert',
    UPDATE_CONTENT_IDENTIFIER: 'root/updateContentIdentifier',
    SET_CHOICES: 'root/setChoices',
    FETCH_CHOICES: 'root/fetchChoices',
    ROOT_API_ERROR: 'root/rootAPIError',

}

export {types};