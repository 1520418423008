import {API_START, API_END, ACCESS_DENIED, API_ERROR, API,  WRONG_PASSWORD} from "./types";
import settings from "./settings";
import {types} from "../auth/auth.types";
import {setLoggedOut} from "../auth/auth.actions";

export const apiStart = label => ({
    type: API_START,
    payload: label
});

export const apiEnd = label => ({
    type: API_END,
    payload: label
});

export const accessDenied = url => {
    if(url === "/login") return accessDeniedResponse(url)
    // check is the user still logged in
    return apiAction({
        url: `${settings.baseURL}/is_authenticated`,
        onSuccess: (response) => isAuthenticated(url, response),
        onFailure: setLoggedOut,
        label: types.AUTH_IS_AUTHENTICATED
    });

};
const isAuthenticated = (url, response) => {
    // if user is still logged in "access denied" response should be returned
    if (response === true || response === 'true') return accessDeniedResponse(url)
    // if user isn't logged in we should continue to logout process
    return setLoggedOut()
};

export const incorrectCredentials = () => (
    { 
        type: WRONG_PASSWORD,
        payload: {}
    }
);

export const accessDeniedResponse = (url) => (
     {
        type: ACCESS_DENIED,
        payload: {
            url
        }
    }

);


export const apiError = error => {
    let data = "Connection error";
    if (error.response !== undefined) data = error.response.data;
    return {
        type: API_ERROR,
        payload: data
    }
};

export const apiAction = ({
                              url = "",
                              method = "GET",
                              data = null,
                              accessToken = null,
                              onSuccess = () => {
                              },
                              onFailure = () => {
                              },
                              label = "",
                              headersOverride = null,
                              responseType='json'
                          }) => {
    return {
        type: API,
        payload: {
            url,
            method,
            data,
            accessToken,
            onSuccess,
            onFailure,
            label,
            headersOverride,
            responseType
        }
    };
}