const settings = {
    APIKey: process.env.REACT_APP_BACKEND_API_KEY !== undefined ? process.env.REACT_APP_BACKEND_API_KEY : null,
    baseURL: process.env.REACT_APP_BACKEND_URL !== undefined ? process.env.REACT_APP_BACKEND_URL : null,
};

if (settings.APIKey === null) {
    settings.APIKey = localStorage.getItem('APIKey') || null

}

if (settings.baseURL === null) {
    settings.baseURL = localStorage.getItem('baseURL') || null
}

if (settings.baseURL === null) {
    let domainArray = window.location.hostname.split(".");
    if(domainArray.length > 0) {
        domainArray[0] = "api";
        settings.baseURL = "https://" + (domainArray.join("."));
    }
}


export default settings;