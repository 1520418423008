import types from "./user.types";
import {apiAction} from "../api/actions";
import settings from "../api/settings";

const setUsers = data => {
    return {
        type: types.SET_USERS_LIST,
        payload: data
    };
}
export const setUserErrors = data => {
    return {
        type: types.SET_USER_ERRORS,
        payload: data
    };
}

const userAPICallError = error => {
    let data = "Connection error";
    if (error.response !== undefined) data = error.response.data;
    return {
        type: types.USER_API_ERROR,
        payload: data
    }
}

export const createUser = (data, onSuccess) => {
    return apiAction({
        url: settings.baseURL + "/users/",
        method: 'POST',
        data: data,
        onSuccess: onSuccess,
        onFailure: userAPICallError,
        label: types.CREATE_USER
    });
};

export const listUsers = (municipalityId) => {
    let queryStr = "";
    if(municipalityId != null) {
        queryStr = `?municipalityId=${municipalityId}`
    }
    return apiAction({
        url: `${settings.baseURL}/users/${queryStr}`,
        onSuccess: setUsers,
        onFailure: userAPICallError,
        label: types.FETCH_USERS_LIST
    });
}

export const getUser = id => {
    return apiAction({
        url: `${settings.baseURL}/users/${id}/`,
        onSuccess: setUser,
        onFailure: userAPICallError,
        label: types.FETCH_USER
    });
}

export const setUser = data => {
    return {
        type: types.SET_USER,
        payload: data
    };
}

export const unsetUser = () => {
    return {
        type: types.UNSET_USER,
        payload: null
    };
}

export const saveUser = (id, data, onSuccess) => {
    return apiAction({
        url: `${settings.baseURL}/users/${id}/`,
        method: 'PUT',
        data: data,
        onSuccess: onSuccess,
        onFailure: userAPICallError,
        label: types.UPDATE_USER
    });
}

export const removeUser = (id, onSuccess) => {
    return apiAction({
        url: `${settings.baseURL}/users/${id}/remove`,
        method: 'POST',
        onSuccess: onSuccess,
        onFailure: userAPICallError,
        label: types.REMOVE_USER
    });
}

// Notifications

export const unsetNotification = () => {
    return {
        type: types.UNSET_NOTIFICATION,
        payload: null
    };
}

export const setNotification = data => {
    return {
        type: types.SET_NOTIFICATION,
        payload: data
    };
}

const setNotifications = data => {
    return {
        type: types.SET_NOTIFICATIONS_LIST,
        payload: data
    };
}


export const listNotifications= () => {
    return apiAction({
        url: `${settings.baseURL}/notifications`,
        onSuccess: setNotifications,
        onFailure: userAPICallError,
        label: types.FETCH_NOTIFICATIONS_LIST
    });
}

export const createNotification = (data, onSuccess) => apiAction({
    url: `${settings.baseURL}/notifications`,
        method: 'POST',
        data: data,
        onSuccess: onSuccess,
        onFailure: userAPICallError,
        label: types.CREATE_NOTIFICATION
    });


export const removeNotification = (id, onSuccess) => apiAction({
    url: `${settings.baseURL}/notifications/${id}/remove`,
    method: 'POST',
    onSuccess: onSuccess,
    onFailure: userAPICallError,
    label: types.REMOVE_NOTIFICATION
});


const setReminders = data => {
    return {
        type: types.SET_REMINDERS_LIST,
        payload: data
    };
}

export const getReminders = () => apiAction({
    url: `${settings.baseURL}/reminders`,
    onSuccess: setReminders,
    onFailure: userAPICallError,
    label: types.FETCH_REMINDERS_LIST
});

export const acceptReminder = (reminderId) => apiAction({
    url: `${settings.baseURL}/reminders/${reminderId}/accept`,
    onSuccess: getReminders,
    onFailure: userAPICallError,
    label: types.ACCEPT_REMINDER
});




