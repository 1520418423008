import {InputBase} from "@material-ui/core";
import React from "react";
import {generateKey} from "../utils";
import * as PropTypes from "prop-types";
import {useTranslation} from "react-i18next";


const CompTextField = (props) => {
    const key = generateKey('text-field');

    let {label, error, errorAlreadyTranslated=false, value="", id, ...componentProps} = props;
    const {t} = useTranslation('translation');

    if(value == null) {
        value = "";
    }

    if(error && !errorAlreadyTranslated) {
        error = t("errors."+error);
    }

    return (
        <div className={`comp-text-field-container ${props.className ? 'container-' + props.className : ''} ${error ? 'error':''}`}>
            <div className={'label'}>{label}</div>
            <InputBase id={key} value={value} {...componentProps} />
            <div className={`error-label ${props.className ? 'error-' + props.className : ''}`}>{error}</div>
        </div>
    );
}

CompTextField.propTypes = {
    label: PropTypes.string,
    error: PropTypes.string
};

export {CompTextField};