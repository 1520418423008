import React, {useState, useEffect} from "react";

import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {connect} from "react-redux";
import {Grid} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {CompCheckbox} from "../../../components/Checkbox";
import * as PropTypes from "prop-types";
import {getMunicipalityGrid, getMunicipalityCSVReport} from "../report.actions";
import {ButtonMain} from "../../../components/ButtonMain";
import types from "../report.types";

const DataQueryMunicipalityReport = ({
        municipalities, 
        getMunicipalityGrid, 
        getMunicipalityCSVReport,
        props
    }) => {
    const {t} = useTranslation('translation');
    const [municipalityGrid, setMunicipalityGrid] = useState([]);
    const [gridShown, setGridShown] = useState(true);
    const [updateSelections, setUpdateSelections] = useState(false);
    const [selections, setSelections] = useState([]);

    const choices = [
        {
            label: t('municipality.municipality_info'),
            value: "MUNICIPALITY"
        },
        {
            label: t('municipality.municipality_personal_info'),
            value: "MUNICIPALITY_PERSONAL"
        }
    ]

    const handleMultiselectChange = (event, value) => {
        event.preventDefault()

        const i = selections.indexOf(value)
        const isAddAction = i < 0
        if (isAddAction) {
            selections.push(value);
        } else {
            selections.splice(i, 1);
        }
        setSelections(selections);
        setUpdateSelections(true);
    }

    useEffect(() => {
        if (updateSelections) {
            setUpdateSelections(false);
        }
    }, [updateSelections])

    useEffect(() => {
        getMunicipalityGrid((data) => {
            data.rows.sort((a,b) => a.municipalityEntity.localeCompare(b.municipalityEntity))
            setMunicipalityGrid(data.rows);
            return {
                type: types.IGNORE,
                payload: data
            }
        },
        (data) => {
            return {
                type: types.IGNORE,
                payload: data
            }
        }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const downloadCSV = (e, language) => {
        const values = {
            language: language,
            selections: selections
        }

        getMunicipalityCSVReport(values, (data, response) => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;

            const contDispo = response.headers['content-disposition'];
            const contDispoParts = contDispo.split(/filename=(.*\.csv)/);

            let filename = "file.csv";
            if (contDispoParts.length === 3) {
                filename = contDispoParts[1].trim()
            }

            link.setAttribute('download', filename); //or any other extension
            document.body.appendChild(link);
            link.click();
            return {
                type: types.IGNORE,
                payload: data
            }
        })
    }


    return (
        <div>
        <ButtonMain key={'add_new_organization'} onClick={() => setGridShown(!gridShown)}>
            {gridShown ? t("report.hide_grid") : t("report.show_grid")}
        </ButtonMain>
        {gridShown && 
        <TableContainer component={Paper} className={'CompSystemLogTable-container'}>
            <Table className={'CompSystemLogTable-table'} aria-label={"something"}>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('common.municipality')}</TableCell>
                        <TableCell>{t('common.ALL_USERS')}</TableCell>
                        <TableCell>{t('common.ROLE_ADMIN_SYSTEM')}</TableCell>
                        <TableCell>{t('common.ROLE_ADMIN_MUNICIPALITY')}</TableCell>
                        <TableCell>{t('common.ROLE_LEADER_MUNICIPALITY')}</TableCell>
                        <TableCell>{t('common.ROLE_ADMIN_ORGANIZATION')}</TableCell>
                        <TableCell>{t('common.ROLE_EMPLOYEE_MUNICIPALITY')}</TableCell>
                        <TableCell>{t('common.ROLE_EMPLOYEE_ORGANIZATION')}</TableCell>
                        <TableCell>{t('common.ROLE_GUEST')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {
                    municipalityGrid.map((row, index) => (
                        <TableRow key={`row-${index}`}>
                            <TableCell>{row.municipalityEntity}</TableCell>
                            <TableCell>{row.roleAdminSystem+row.roleAdminMunicipality+row.roleLeaderMunicipality+row.roleAdminOrganization+row.roleEmployeeMunicipality+row.roleEmployeeOrganization+row.roleGuest}</TableCell>
                            <TableCell>{row.roleAdminSystem}</TableCell>
                            <TableCell>{row.roleAdminMunicipality}</TableCell>
                            <TableCell>{row.roleLeaderMunicipality}</TableCell>
                            <TableCell>{row.roleAdminOrganization}</TableCell>
                            <TableCell>{row.roleEmployeeMunicipality}</TableCell>
                            <TableCell>{row.roleEmployeeOrganization}</TableCell>
                            <TableCell>{row.roleGuest}</TableCell>
                        </TableRow>))
                }
                {   municipalityGrid.length > 0 &&  
                    <TableRow key={`row-last`}>
                            <TableCell></TableCell>
                            <TableCell>{municipalityGrid.map(r => r.roleAdminSystem+r.roleAdminMunicipality+r.roleLeaderMunicipality+r.roleAdminOrganization+r.roleEmployeeMunicipality+r.roleEmployeeOrganization+r.roleGuest).reduce((p,n) => p+n)}</TableCell>
                            <TableCell>{municipalityGrid.map(r => r.roleAdminSystem).reduce((p,n) => p+n)}</TableCell>
                            <TableCell>{municipalityGrid.map(r => r.roleAdminMunicipality).reduce((p,n) => p+n)}</TableCell>
                            <TableCell>{municipalityGrid.map(r => r.roleLeaderMunicipality).reduce((p,n) => p+n)}</TableCell>
                            <TableCell>{municipalityGrid.map(r => r.roleAdminOrganization).reduce((p,n) => p+n)}</TableCell>
                            <TableCell>{municipalityGrid.map(r => r.roleEmployeeMunicipality).reduce((p,n) => p+n)}</TableCell>
                            <TableCell>{municipalityGrid.map(r => r.roleEmployeeOrganization).reduce((p,n) => p+n)}</TableCell>
                            <TableCell>{municipalityGrid.map(r => r.roleGuest).reduce((p,n) => p+n)}</TableCell>
                    </TableRow>
                }
                </TableBody>
                </Table>
        </TableContainer>}
        <ButtonMain
            key={'load_municipality_csv'}
            onClick={e => downloadCSV(e, t('report.current_language'))}>
            {t('report.load_csv')}
        </ButtonMain>
        <Grid item xs={12}>
            {!updateSelections && <CompCheckbox 
                rows={choices}
                selected={selections}
                onChange={handleMultiselectChange} 
                name={"selections"}/>
            }
        </Grid>
        </div>
    );
}

DataQueryMunicipalityReport.propTypes = {
    getMunicipalityGrid: PropTypes.func
};

const mapStateToProps = (state, ownProps) => {
    return {
        municipalities: state.municipality.municipalities,
        props: ownProps
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getMunicipalityGrid: (onSuccess) => dispatch(getMunicipalityGrid(onSuccess)),
        getMunicipalityCSVReport: (data, onSuccess) => dispatch(getMunicipalityCSVReport(data, onSuccess))
    };
}

const DataQueryMunicipalityReportPage = connect(mapStateToProps, mapDispatchToProps)(DataQueryMunicipalityReport);

export {DataQueryMunicipalityReportPage};