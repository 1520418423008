import moment from "moment";

export const generateKey = (pre) => {
    /**
     * Generate random key for the element
     */
    if (pre == null || pre === '') {
        throw new Error("Prefix missing");
    }
    return `${pre}_${new Date().getTime()}_${Math.random().toString(36).substring(7)}`;
}

export const isNormalInteger = (str) => {
    if (str == null) {
        return false;
    }
    if (isFunction(str)) {
        return false;
    }
    if (typeof str == "number") {
        return true;
    }
    str = str.trim();
    if (!str) {
        return false;
    }
    str = str.replace(/^0+/, "") || "0";
    var n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str;
}

export const isFunction = (obj) => typeof obj == 'function';

export const formatDateStr = (dateStr) => {
    if(dateStr === undefined || dateStr === null) {
        return;
    }
    const format = "DD.MM.YYYY"
    return moment.utc(dateStr).format(format);
}

export const formatISODateStr = (dateStr) => {
    if(dateStr === undefined || dateStr === null) {
        return;
    }
    const format = "YYYY-MM-DD"
    return moment.utc(dateStr).format(format);
}

export const formatTimeStr = (datetimeStr) => {
    if(datetimeStr === undefined || datetimeStr === null) {
        return;
    }
    const format = "HH:mm:ss"
    return moment.utc(datetimeStr).format(format);

}

export const valueFoundInArray = (value, arr) => {
    if (value === undefined || value == null) return false;
    if (arr === undefined || arr == null) return false;
    const i = arr.indexOf(value)
    return i >= 0
}

export const getComparator = () => new Intl.Collator('sv');