import {Checkbox, FormControl, FormControlLabel,} from "@material-ui/core";
import React from "react";
import * as PropTypes from "prop-types";
import {useTranslation} from "react-i18next";


const CompCheckbox = (props) => {
    const {rows, error, selected, name, onChange, booleanmode=false, ...componentProps} = props;
    const {t} = useTranslation('translation');
    return (
        <FormControl className={`comp-checkbox-group ${error ? 'error' : ''}`} aria-label={name}
                    name={name}>
            {rows.map((row, index) => (
                <FormControlLabel className={`${name}-checkbox-${index}`}
                                  key={`${name}-checkbox-${index}`}
                                  control={
                                      <Checkbox
                                          checked={
                                            (booleanmode && selected[row.key]) ||
                                            (!booleanmode && selected != null && selected.includes(row.value))
                                            }
                                          onChange={(event) => onChange(event, row.value, row)}
                                          name={name}
                                      />
                                  }
                                  label={row.label}
                                  {...componentProps} />
            ))}
            <div className={`error-label ${props.className ? 'error-' + props.className : ''}`}>{t("errors."+error)}</div>
        </FormControl>
    );
}

CompCheckbox.propTypes = {
    selected: PropTypes.array,
    rows: PropTypes.array,
    error: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
};

export {CompCheckbox};