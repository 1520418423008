import {FormControlLabel, Radio, RadioGroup,} from "@material-ui/core";
import React from "react";
import * as PropTypes from "prop-types";
import {useTranslation} from "react-i18next";


const CompRadioGroup = (props) => {
    const {rows, error, selected, name, labelTranslation, ...componentProps} = props;
    const {t} = useTranslation('translation');

    return (
        <RadioGroup className={`${props.large ? 'large-' : ''}comp-radio-button-group ${error ? 'error' : ''}`} aria-label={name}
                    name={name}>
            {rows.map((row, index) => (
                <FormControlLabel className={`${name}-radio-${index}`}
                                  key={`${name}-radio-${index}`}
                                  value={row.value}
                                  checked={row.value.localeCompare(selected) === 0}
                                  control={<Radio/>}
                                  label={labelTranslation ? t(row.label) : row.label}
                                  {...componentProps} />
            ))}
            <div className={`error-label ${props.className ? 'error-' + props.className : ''}`}>{t("errors."+error)}</div>
        </RadioGroup>
    );
}

CompRadioGroup.propTypes = {
    selected: PropTypes.string,
    rows: PropTypes.array,
    error: PropTypes.string,
    name: PropTypes.string
};

export {CompRadioGroup};