import {Button, Container, IconButton,} from "@material-ui/core";
import React, {Component} from "react";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import {connect} from "react-redux";
import {openMenu, selectLeftDrawer, selectRightDrawer} from "../modules/root/root.actions";
import {CompBreadcrumbs} from "../components/Breadcrumbs";
import {withTranslation} from "react-i18next";
import {setLoggedOut} from "../modules/auth/auth.actions";
import {getUserIconUrl} from "../modules/root/root.utils";
import PublicIcon from '@material-ui/icons/Public';
import i18n from "../i18n.js";
import {Link} from "react-router-dom";

const mapStateToProps = state => {
    return {
        mobileOpen: state.root.mobileOpen,
        leftDrawerOpen: state.root.leftDrawerOpen,
        rightDrawerOpen: state.root.rightDrawerOpen,
        authenticatedUser: state.auth.authenticatedUser,
    };

}
const mapDispatchToProps = dispatch => {
    return {
        openMenu: () => dispatch(openMenu()),
        selectLeftDrawer: () => dispatch(selectLeftDrawer()),
        selectRightDrawer: () => dispatch(selectRightDrawer()),
        setLoggedOut: () => dispatch(setLoggedOut())
    };
}

class TopMenuComponent extends Component {
    constructor(props) {
        super(props);
        this.openMobileMenu = this.openMobileMenu.bind(this);
        this.selectLeftMobileMenu = this.selectLeftMobileMenu.bind(this);
        this.selectRightMobileMenu = this.selectRightMobileMenu.bind(this);
        this.openUserMenu = this.openUserMenu.bind(this);
        this.closeUserMenu = this.closeUserMenu.bind(this);
        this.logout = this.logout.bind(this);
        this.changeLanguage = this.changeLanguage.bind(this);

        this.state = {
            userMenuOpen: false,
        }
    }

    openUserMenu(event) {
        event.preventDefault();
        this.setState({
            ...this.state,
            userMenuOpen: true
        })
    }

    closeUserMenu(event) {
        event.preventDefault();
        this.setState({
            ...this.state,
            userMenuOpen: false
        })
    }

    openMobileMenu(event) {
        event.preventDefault();
        this.props.openMenu();
    }

    selectLeftMobileMenu(event) {
        event.preventDefault();
        this.props.selectLeftDrawer();
    }

    selectRightMobileMenu(event) {
        event.preventDefault();
        this.props.selectRightDrawer();
    }

    logout(event) {
        event.preventDefault();
        this.props.setLoggedOut();
    }

    changeLanguage(lng) {
        localStorage.setItem('loc_id', lng)
        i18n.changeLanguage(lng);
    }

    render() {
        const {t} = this.props;

        let desktopLogoSrc = process.env.PUBLIC_URL + '/media/images/Logo_nuoDo_desktop.svg';
        let mobileLogoSrc = process.env.PUBLIC_URL + '/media/images/Logo_nuoDo_mobile.svg';
        let langChangeLink = (
            <li>
                <Button onClick={() => this.changeLanguage("sv")}>
                    <PublicIcon />
                    <span>{t('common.sv')}</span>
                </Button>
            </li>

        )
        if (i18n.language === "sv") {
            desktopLogoSrc = process.env.PUBLIC_URL + '/media/images/Logo_ungDo_desktop.svg';
            mobileLogoSrc = process.env.PUBLIC_URL + '/media/images/Logo_ungDo_mobile.svg';
            langChangeLink = (
                <li>
                    <Button onClick={() => this.changeLanguage("fi")}>
                        <PublicIcon />
                        <span>{t('common.fi')}</span>
                    </Button>
                </li>
            )
        }
        return (
            <AppBar position="fixed" className={'appBar'}>
                <Toolbar className={'appBarToolbar'}>
                    <IconButton
                        className={'mobile-menu-icon'}
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={this.openMobileMenu}
                    >
                        <MenuRoundedIcon className={this.props.mobileOpen ? 'hidden' : ''}/>
                        <ArrowBackRoundedIcon className={this.props.mobileOpen ? '' : 'hidden'}/>
                    </IconButton>
                    <div className={'logo-container'}>
                        <Link className="header-logo" to="/">
                            <img className="header-logo-normal"
                                 src={desktopLogoSrc}
                                 alt={t('common.youth_work')}/>
                        </Link>
                    </div>
                    <Container maxWidth="lg" className={'container breadcrumb-container'}>
                        <CompBreadcrumbs/>
                    </Container>
                    <Button className={'userMenuButton'} onClick={this.openUserMenu}>

                        <div className={'user-icon'}>
                            <img className="user-icon-img"
                                 src={process.env.PUBLIC_URL + getUserIconUrl(this.props.authenticatedUser)}
                                 alt={t('common.user_icon')}/>
                        </div>
                    </Button>

                    <div className={`userMenu ${this.state.userMenuOpen ? '' : 'hidden'}`}>
                        <Button className={'user-icon userMenu-icon'} onClick={this.closeUserMenu}>
                            <img className="user-icon-img"
                                 src={process.env.PUBLIC_URL + getUserIconUrl(this.props.authenticatedUser)}
                                 alt={t('common.user_icon')}/>
                        </Button>
                        <p className={'userMenu-name'}>
                            {this.props.authenticatedUser.firstName} {this.props.authenticatedUser.lastName}
                        </p>
                        <ul className={'userMenu-menu'}>
                            {langChangeLink}
                            <li>
                                <Button href={'/user/openProfile'}>
                                    <img className="userMenu-menu-icon-img"
                                         src={process.env.PUBLIC_URL + '/media/images/icons/IconMove.svg'}
                                         alt={t("function.card_image_alt")}/>
                                    <span>{t('components.own_information')}</span>
                                </Button>
                            </li>
                            <li>
                                <Button onClick={this.logout}>
                                    <img className="userMenu-menu-icon-img"
                                         src={process.env.PUBLIC_URL + '/media/images/icons/IconLogout.svg'}
                                         alt={t("function.card_image_alt")}/>
                                    <span>{t('components.logout')}</span>
                                </Button>
                            </li>
                        </ul>
                    </div>
                    <div className={`userMenuOverlay ${this.state.userMenuOpen ? '' : 'hidden'}`}
                         onClick={this.closeUserMenu}/>

                </Toolbar>
                <Toolbar className={`appBarToolbarMobile ${this.props.mobileOpen ? 'hidden' : ''}`}>
                    <div className={'logo-container'}>
                        <Link className="header-logo" to="/">
                            <img className="header-logo-mobile"
                                 src={mobileLogoSrc}
                                 alt={t('common.youth_work')}/>
                        </Link>
                    </div>
                </Toolbar>
                <Toolbar className={`appBarToolbarMobileMenu ${this.props.mobileOpen ? '' : 'hidden'}`}>
                    <div className={`mobile-menu-selector ${this.props.leftDrawerOpen ? 'active' : ''}`}>
                        <Button disableElevation onClick={this.selectLeftMobileMenu}>
                            {t('menu.categories')}
                        </Button>
                    </div>
                    <div className={`mobile-menu-selector ${this.props.rightDrawerOpen ? 'active' : ''}`}>
                        <Button disableElevation onClick={this.selectRightMobileMenu}>
                            {t('menu.shortcuts')}
                        </Button>
                    </div>
                </Toolbar>
            </AppBar>
        );
    }
}

const TopMenu = connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation('translation')(TopMenuComponent));

export
{
    TopMenu
}
    ;