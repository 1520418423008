import React from 'react';
import {StylesProvider} from "@material-ui/core/styles";
import './i18n';
import {App} from './App';
import reportWebVitals from './reportWebVitals';
import "./styles/css/index.css"
import {persistor, store} from "./store.js"
import {Provider} from "react-redux";
import {PersistGate} from 'redux-persist/integration/react'
import {getParameters} from "./modules/api/parameters";
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import { createRoot } from 'react-dom/client';

/**
 * Override application and Amplify runtime parameters with environment overrides
 * or use defaults if service specific variables are not available
 */
getParameters()
    .then((parameters) => {
        if (parameters === undefined || parameters === null) {
            return;
        }

        const params = parameters.kl_nuorisotyo_front_cloudfront_cloudfront_public_runtime_parameters;
        if (params === null || params === undefined) {
            return;
        }
        if ('backend_api_key' in params) {
            localStorage.setItem('APIKey', params.backend_api_key);
        }

        if ('backend_url' in params) {
            localStorage.setItem('baseURL', params.backend_url);
        }
    })
    .catch((reason) => {
        console.error(
            "Runtime parameter fetching or configuration failed with reason",
            reason
        );
    })

    .finally(() => {
        const container = document.getElementById('root');
        const root = createRoot(container)
        
        root.render(
            <StylesProvider injectFirst>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <I18nextProvider i18n={i18next}>
                        <App/>
                        </I18nextProvider>
                    </PersistGate>
                </Provider>
            </StylesProvider>
        );
    });


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
