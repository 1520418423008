import React from 'react';

import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Grid} from "@material-ui/core";
import {CompReportYouthWorkFiguresTable} from "./reportYouthWorkFiguresTableComponent";
import {CompRadioGroup} from "../../../components/RadioGroup";
import {ReactComponent as ProvincesMap} from "../../../styles/images/map_provinces.svg";
import {CompSearchList} from "../../../components/SearchList";
import {listMunicipalities} from "../../municipality/municipality.actions";
import {
    getCountryReport,
    getCountryReportSuccess,
    getMunicipalityReport,
    getMunicipalityReportSuccess,
    getProvinceReport,
    getProvinceReportSuccess
} from "../report.actions";
import {CompDatePicker} from "../../../components/DatePicker";
import {CompReportOwnMunicipalityTable} from "./reportOwnMunicipalityTableComponent";
import moment from "moment";

const mapStateToProps = (state) => {
    return {
        authenticatedUser: state.auth.authenticatedUser,
        municipalities: state.municipality.municipalities,
        provinces: state.root.provinces
    }
}

const mapDispatchToProps = dispatch => {
    return {
        listMunicipalities: () => dispatch(listMunicipalities()),
        getMunicipalityReport: (municipalityId, startDate, endDate, onSuccess) => dispatch(getMunicipalityReport(municipalityId, startDate, endDate, onSuccess)),
        getProvinceReport: (provinceId, startDate, endDate, onSuccess) => dispatch(getProvinceReport(provinceId, startDate, endDate, onSuccess)),
        getCountryReport: (startDate, endDate, onSuccess) => dispatch(getCountryReport(startDate, endDate, onSuccess)),
    };
}

class ReportPage extends React.Component {
    constructor(props) {
        super(props);
        this.props.listMunicipalities();

        this.state = {
            level: 'province',
            municipalityId: null,
            provinceId: null,
            startDate: moment.utc().subtract(14, "days").format("YYYY-MM-DD"),
            endDate: moment.utc().format("YYYY-MM-DD"),
            data: {},
        }

        this.getReport = this.getReport.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.selectMunicipality = this.selectMunicipality.bind(this);
        this.selectProvince = this.selectProvince.bind(this);

    }

    async componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.page !== prevProps.page) {
            if (this.props.page === 'ownMunicipality') {
            } else {
            }
        }
    }

    getReport = (values) => {
        if (values.level === 'municipality' && values.municipalityId !== null && values.startDate !== null && values.endDate !== null) {
            this.props.getMunicipalityReport(values.municipalityId, values.startDate, values.endDate, data => {
                this.setState({data: data});
                return getMunicipalityReportSuccess(data);
            })
        } else if (values.level === 'province' && values.provinceId !== null && values.startDate !== null && values.endDate !== null) {
            this.props.getProvinceReport(values.provinceId, values.startDate, values.endDate, data => {
                this.setState({data: data});
                return getProvinceReportSuccess(data);
            })
        } else if (values.level === 'country' && values.startDate !== null && values.endDate !== null) {
            this.props.getCountryReport(values.startDate, values.endDate, data => {
                this.setState({data: data});
                return getCountryReportSuccess(data);
            })
        }

    }

    valuetext(value) {
        return `${value} päivää`;
    }

    handleChange = (e) => {
        const values = {...this.state};
        values[e.target.name] = e.target.value;
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
        this.getReport(values)
    }
    selectMunicipality = (row, isAddAction/*, index */) => {
        if (isAddAction) {
            this.handleChange({
                target: {
                    value: row.id,
                    name: "municipalityId",
                },
            });
        }
    }
    selectProvince = (row, isAddAction/*, index */) => {
        if (isAddAction) {
            this.handleChange({
                target: {
                    value: row.id,
                    name: "provinceId",
                },
            });
        }
    }

    render() {
        const {t} = this.props;

        const levelChoices = [
            {
                label: t('common.municipality'),
                value: "municipality"
            },
            {
                label: t('common.province'),
                value: "province"
            },
            {
                label: t('common.finland'),
                value: "country"
            },
        ]

        const getWeekSummaries = () => getKey('weekSummaries', this.state.data, []);
        const getSummaries = () => getKey('summaries', this.state.data, []);
        const getKey = (key, element, defaultValue) => {
            try {
                return element[key];
            } catch (err) {
                return defaultValue;
            }
        }


        let listComponent = (<div/>);
        if (this.state.level === 'municipality') {
            listComponent = (
                <CompSearchList
                    className={`listComponent`}
                    rows={this.props.municipalities}
                    keyColumn={'id'}
                    valueFunc={(row) => row.name}
                    selectedRows={[this.state.municipalityId]}
                    onSelect={this.selectMunicipality}>
                    {t('common.select_municipality')}
                </CompSearchList>
            );
        } else if (this.state.level === 'province') {
            listComponent = (
                <CompSearchList
                    className={`listComponent`}
                    rows={this.props.provinces}
                    keyColumn={'id'}
                    valueFunc={(row) => row.name}
                    selectedRows={[this.state.provinceId]}
                    onSelect={this.selectProvince}>
                    {t('common.select_province')}
                </CompSearchList>
            );

        }

        let report = (<div className={'hidden'}/>)
        if (Object.keys(this.state.data).length > 0) {
            if (this.state.level === 'municipality') {
                report = (<CompReportOwnMunicipalityTable
                    rows={getWeekSummaries()}
                    label={t('report.week_report')}/>)
            } else {
                report = (<CompReportYouthWorkFiguresTable
                    rows={getSummaries()}
                    label={getKey('title', this.state.data, "")}/>)
            }
        }

        return (
            <div className={'reports'}>
                <Grid container>
                    <Grid item xs={12} sm={6} md={3}>
                        <CompRadioGroup
                            name="level"
                            selected={this.state.level}
                            rows={levelChoices}
                            onChange={this.handleChange}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} className={'reportMapContainer'}>
                        <ProvincesMap/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5}>
                        {listComponent}
                    </Grid>
                    <Grid item xs={12} md={7}/>
                    <Grid item xs={12} md={5}>
                        <div className={'MuiGrid-item-label label'}>{t('report.start_and_end_date')}</div>
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={6}>
                                <CompDatePicker
                                    value={this.state.startDate}
                                    name="startDate"
                                    onChange={this.handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CompDatePicker
                                    value={this.state.endDate}
                                    name="endDate"
                                    onChange={this.handleChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {report}
                    </Grid>
                </Grid>

            </div>
        )
    }
}

const YouthWorkFiguresReportPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation('translation')(ReportPage));

export {YouthWorkFiguresReportPage};