import React, {Component} from "react";
import {connect} from "react-redux";


import {BrowserRouter as Router, Switch} from "react-router-dom";
import {routes} from "./routes";
import {setLoggedOut} from "./modules/auth/auth.actions";


class AppComponent extends Component {

    async componentDidMount() {
        if (this.props.authenticatedUser === undefined
            || this.props.authenticatedUser === null
            || Object.keys(this.props.authenticatedUser).length === 0) {
            this.props.setLoggedOut();
        }
    }

    render() {
        return (
            <Router>
                <Switch>
                    {routes.map(({RouteType, path, Component, PermissionName}, index) => {
                        return (
                            <RouteType
                                key={`route-type-${index}`}
                                path={path}
                                authenticatedUser={this.props.authenticatedUser}
                                component={Component}
                                permissionName={PermissionName}
                                exact/>
                        )
                    })}
                </Switch>
            </Router>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.auth.authenticatedUser,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setLoggedOut: () => dispatch(setLoggedOut())
    };
}
export const App = connect(
    mapStateToProps,
    mapDispatchToProps
)(AppComponent);