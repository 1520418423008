import React, {useState, useEffect} from "react";

import { useHistory } from 'react-router-dom';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, Link} from "@material-ui/core";
import {CompPartitionHeader} from "../../../components/PartitionHeader";
import {CompTextField} from "../../../components/TextField";
import * as PropTypes from "prop-types";
import {ButtonSpacer} from "../../../components/ButtonSpacer";
import CircularProgress from '@mui/material/CircularProgress';
import {CompAccordion} from "../../../components/Accordion";
import {CompInputList} from "../../../components/InputList";
import {CompDatePicker} from "../../../components/DatePicker";
import {CompTimePicker} from "../../../components/TimePicker";
import {CompCheckbox} from "../../../components/Checkbox";
import {ButtonMain} from "../../../components/ButtonMain";
import {connect} from "react-redux";
import {CompMultiselectDropdown as MultiselectDropdown} from "../../../components/MultiselectDropdown";
import {CompRadioGroup} from "../../../components/RadioGroup";
import {getLocation} from "../../location/location.actions";
import {CompDropdown} from "../../../components/Dropdown";
import {SearchableDropdown} from "../../../components/SearchableDropdown";
import {getFunctionTypeOptions} from "../../root/root.utils";
import {setFunction, listFunctionTemplates, recreateLinksForFunction, getFeedbackReport, deleteFeedback, listFeedback, setYouthData, getYouthCardInfo} from "../function.actions";
import {updateContentIdentifierChoices} from "../../root/root.actions";
import {listLocations} from "../../location/location.actions";
import Paper from "@material-ui/core/Paper";
import {
    listMunicipalityUsersSilent,
    listOrganizations,
    getOrganizationTree
} from "../../municipality/municipality.actions";
import {useTranslation} from "react-i18next";
import {formatISODateStr, formatTimeStr, generateKey, isFunction, getComparator} from "../../../utils";
import {CompSearchInput} from "../../../components/SearchInput";
import {selectButtonType} from "../../../components/utils";
import moment from "moment";
import {hasPermission, PERMISSION_CREATE_FUNCTION_BEHALF_OF_ANOTHER, PERMISSION_CREATE_FUNCTION_ANY_MUNICIPALITY, PERMISSION_EDIT_OWN_FUNCTIONS} from "../../../permissions";
import {EventHoursTimePicker} from "./EventHoursTimePicker";
import {ageOptions, genderOptions} from "../../feedback/feedbackOptions";
import CustomSnackbar from "../../../components/CustomSnackbar";
import TooltipInfo from "../../../components/Tooltip";
import { getOrganizations } from "../../../components/organizationUtils";
import { useSelector } from "react-redux";

const FunctionFormComponent = ({
                                   municipalities,
                                   selectedMunicipality,
                                   selectedMunicipalityUsers,
                                   selectedTemplateHandlers,
                                   organizations,
                                   organizationTree,
                                   locations,
                                   locationTypes,
                                   contentIdentifiers,
                                   methods,
                                   templates,
                                   func,
                                   authenticatedUser,
                                   setFunction,
                                   listFunctionTemplates,
                                   listLocations,
                                   listMunicipalityUsersSilent,
                                   listOrganizations,
                                   getOrganizationTree,
                                   updateContentIdentifierChoices,
                                   recreateLinksForFunction,
                                   getFeedbackReport,
                                   deleteFeedback, 
                                   listFeedback,
                                   getYouthCardInfo,
                                   props
                               }) => {
    const {
        label,
        onChange,
        editorActivated,
        actionButtons,
        functionTemplateButtons,
        selectedFunction,
        fieldErrors = {},
        allPartitionsOpen = false,
        updateData,
    } = props;
    
    const [showParticipantInformation, setShowParticipantInformation] = useState(false);
    const {t} = useTranslation('translation');

    const history = useHistory();
    const [activeAccordion, setActiveAccordion] = useState('');
    const [selectedTemplate, setselectedTemplate] = useState(null);
    const [updateDataForm, setUpdateDataForm] = useState(updateData);
    const [fetchFeedack, setFetchFeedback] = useState(true);
    //const recheckParticipantInfo = !selectedFunction.id;
    const [temporary, setTemporary] = useState(false);
    const [listDone, setListDone] = useState(false);
    const [feedbackDone, setFeedbackDone] = useState(false);
    const [organizationList, setOrganizationList] = useState([]);
    const [municipalityList, setMunicipalityList] = useState([]);
    const [open, setOpen] = useState(false);
    const [eventId, setEventId] = useState();
    const [youthDataList, setYouthDataList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const state = useSelector(state => state);

    useEffect(() => {
        setYouthDataList(state.func.youthData)
    }, [state.func.youthData, setYouthDataList])

    useEffect(() => {
        if (updateDataForm) {
            var e = {
                target: {
                    value: selectedMunicipality.id
                }
            }
            handleMunicipalityChange(e, true);
            setUpdateDataForm(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateDataForm])

    useEffect(() => {
        setOrganizationList(getOrganizations(organizationTree));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationTree])

    useEffect(() => {
        const comparator = getComparator();
        const response = [];
        for (var municipality of municipalities) {
            response.push(municipality);
        }
        response.sort((a, b) => comparator.compare(a.name,b.name));
        setMunicipalityList(response);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [municipalities])



    useEffect(() => {
        if (fetchFeedack) {
            setFetchFeedback(false);
            func.feedbackReport = null;
            handleFeedbackFetch();
            handleListFeedback();
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchFeedack, selectedFunction])

    useEffect(() => {
        setEventId(selectedFunction.id)
        if (listDone && feedbackDone) {
            setFeedbackDone(false);
            setListDone(false);
            setShowParticipantInformation(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listDone, feedbackDone])

    useEffect(() => {
        if (temporary) {
            setTemporary(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [temporary])

    const handleListFeedback = () => {
        if (selectedFunction.id) {
            listFeedback(selectedFunction.id);
        }
        setListDone(true);
    }

    const handleFeedbackFetch = () => {
        if (selectedFunction.id) {
            getFeedbackReport(selectedFunction.id);
        }
        setFeedbackDone(true);
    }

    const handleChange = (event) => {
        if (isFunction(onChange)) {
            onChange(event)
        }
    };

    const handleNonNegativeChange = (event) => {
        if (isFunction(onChange)) {
            if (event.target.value < 0) {
                event.target.value = 0;
            }

            onChange(event)
        }
    }

    const handleMunicipalityChange = (event, doNotUpdateFunction) => {
        let selectedMunicipality = {}
        municipalities.forEach((municipality) => {
            if (municipality.id === parseInt(event.target.value)) {
                selectedMunicipality = municipality;
            }
        })
        const data = {}
        data['municipalityId'] = selectedMunicipality.id;
        setselectedTemplate(null);
        listFunctionTemplates(selectedMunicipality.id);
        listLocations(selectedMunicipality.id);
        listMunicipalityUsersSilent(selectedMunicipality.id);
        listOrganizations(selectedMunicipality.id);
        getOrganizationTree(selectedMunicipality.id, 10);
        updateContentIdentifierChoices(selectedMunicipality.id);
        if (!doNotUpdateFunction) {
            setFunction(data);
        }
    };

    const [showDeleteTemplate, setShowDeleteTemplate] = useState(false);
    const [showEditTemplate, setShowEditTemplate] = useState(false);
    const [showRemovemeTemplate, setShowRemovemeTemplate] = useState(false);

    const handleTemplateChange = (event) => {
        let selectedTemplate = {}
        templates.forEach((template) => {
            if (template.id === parseInt(event.target.value)) {
                selectedTemplate = template;

                let isOwner = selectedTemplate.creatorId === authenticatedUser.id;

                //if owner, allowed to do everything
                if (permissionToCreateFunctionBehalfOfAnother) {
                    setShowDeleteTemplate(true);
                    setShowEditTemplate(true);
                    let found = false;
                    selectedTemplate.templateHandlers.forEach(t => {
                        if (t.id === authenticatedUser.id) {
                            found = true;
                        }
                    })
                    found = found && (selectedTemplate.templateHandlers.length > 1 || !isOwner);
                    setShowRemovemeTemplate(found && !selectedTemplate.municipalityTemplate);
                }
                else if (isOwner) {
                    setShowDeleteTemplate(true);
                    setShowEditTemplate(true);
                    setShowRemovemeTemplate(selectedTemplate.templateHandlers.length > 1 && !selectedTemplate.municipalityTemplate);
                }
                else {
                    setShowDeleteTemplate(false);
                    setShowEditTemplate(false);
                    setShowRemovemeTemplate(!selectedTemplate.municipalityTemplate);
                }
            }
        })
        setselectedTemplate(selectedTemplate);
        const {id, ...data} = selectedTemplate;

        const currentDate = formatISODateStr(Date.now());

        if (data['startTime']) {
            data['startDate'] = formatISODateStr(currentDate);
            data['startDatetime'] = moment.utc(currentDate + "T" + data['startTime']);
        }
        if (data['endTime']) {
            data['endDate'] = formatISODateStr(currentDate);
            data['endDatetime'] = moment.utc(currentDate + "T" + data['endTime']);
        }

        calculateEventHours(data, data['startDate'], data['startTime'], data['endDate'], data['endTime']);

        setFunction(data);
    };

    const handleDateTimeChange = (event, fieldName) => {
        const data = {}
        const isFeedback = fieldName.endsWith('Feedback');

        let startDate = fieldName === 'startDate' || fieldName === 'startDateFeedback' ? 
            event.target.value : formatISODateStr(isFeedback ? selectedFunction.startDatetimeFeedback : selectedFunction.startDatetime);
        let startTime = fieldName === 'startTime' || fieldName === 'startTimeFeedback' ? 
            event.target.value : formatTimeStr(isFeedback ? selectedFunction.startDatetimeFeedback : selectedFunction.startDatetime);
        let endDate = fieldName === 'endDate' || fieldName === 'endDateFeedback' ? 
            event.target.value : formatISODateStr(isFeedback ? selectedFunction.endDatetimeFeedback : selectedFunction.endDatetime);
        let endTime = fieldName === 'endTime' || fieldName === 'endTimeFeedback' ? 
            event.target.value : formatTimeStr(isFeedback ? selectedFunction.endDatetimeFeedback : selectedFunction.endDatetime);

        if ((fieldName === 'startDate' || fieldName === 'startDateFeedback') && startTime === undefined) {
            startTime = '00:00:00';
        }
        if ((fieldName === 'startTime' || fieldName === 'startTimeFeedback') && startDate === undefined) {
            startDate = formatISODateStr(Date.now());
        }
        if ((fieldName === 'endDate' || fieldName === 'endDateFeedback') && endTime === undefined) {
            endTime = '23:59:59';
        }
        if ((fieldName === 'endTime' || fieldName === 'endTimeFeedback') && endDate === undefined) {
            endDate = formatISODateStr(Date.now());
        }

        if ((fieldName === 'startDate' || fieldName === 'startDateFeedback') && !endDate) {
            endDate = startDate;
            if (!endTime) {
                endTime = '23:59:59';
            }
            if (isFeedback) {
                data['endDatetimeFeedback'] = moment.utc(endDate + "T" + endTime);
            }
            else {
                data['endDatetime'] = moment.utc(endDate + "T" + endTime);
            }
        }
        calculateEventHours(data, startDate, startTime, endDate, endTime, isFeedback);

        if (isFunction(onChange)) {
            onChange(event, data);
        }

    }
    const calculateEventHours = (data, startDate, startTime, endDate, endTime, isFeedback) => {
        const startMoment = moment.utc(startDate + "T" + startTime);
        if (startDate && startTime) {
            data[isFeedback ? 'startDatetimeFeedback' : 'startDatetime'] = startMoment;
        }

        const endMoment = moment.utc(endDate + "T" + endTime);
        if (endDate && endTime) {
            data[isFeedback ? 'endDatetimeFeedback' : 'endDatetime'] = endMoment;
        }

        if (startDate && startTime &&
            endDate && endTime && !isFeedback) {
            const duration = moment.duration(endMoment.diff(startMoment));
            let hours = duration.asHours();

            if (hours < 0) hours = 0;
            if (hours > 8) hours = 8;
            data['eventHours'] = Math.round(100*hours)/100.0;
        }
    }

    const handleClick = (event, callback) => {
        event.preventDefault()
        const data = {...selectedFunction}
        callback(event, data);
    };
    const handleFunctionTemplateClick = (event, callback) => {
        event.preventDefault();
        const data = {...selectedFunction};
        callback(event, data, selectedTemplate, selectedMunicipality);
    };

    const handleAccordionChange = (event, isExpanded, key) => {
        event.preventDefault();
        if (isExpanded) {
            setActiveAccordion(key);
        } else {
            setActiveAccordion('');
        }
    }

    const onLocationSelectionChange = event => {
        if (event.target.value === "") {
            const funcData = {...selectedFunction};
            funcData.locationId = null;
            funcData.locationName = "";
            funcData.locationPostCode = "";
            funcData.locationTypeId = "";
            setFunction(funcData);

        } else {
            locations.forEach((location) => {
                if (location.id === parseInt(event.target.value)) {
                    const funcData = {...selectedFunction};
                    funcData.locationId = location.id;
                    funcData.locationName = location.name;
                    funcData.locationPostCode = location.postCode;
                    funcData.locationTypeId = location.locationTypeId;
                    setFunction(funcData);
                }
            })
        }
    }
    
    const onLocationTypeSelectionChange = event => {
        const funcData = {...selectedFunction};
        funcData.locationTypeId = event.target.value === "" ? null : event.target.value;
        setFunction(funcData);
    }

    const selectOrganizer = (row, isAddAction, index, i) => {
        if (isFunction(onChange)) {
            let value = [];
            if ('organizers' in selectedFunction) {
                value = [...selectedFunction.organizers];
            }
            if (isAddAction) {
                value.push(row);
            } else {
                value.splice(i, 1);
            }

            const data = {
                target: {
                    value: value,
                    name: "organizers",
                },
            };
            onChange(data);
        }
    }
    
    const selectTemplateHandler = (row, isAddAction, index, i) => {
        if (isFunction(onChange)) {
            let value = [];
            if ('templateHandlers' in selectedFunction) {
                value = [...selectedFunction.templateHandlers];
            }
            if (isAddAction) {
                value.push(row);
            } else {
                value.splice(i, 1);
            }

            const data = {
                target: {
                    value: value,
                    name: "templateHandlers",
                },
            };
            onChange(data);
        }
    }

    const handleContentIdentifierChange = (row, isAddAction, index, i) => {
        if (isFunction(onChange)) {
            let value = [];
            if ('contentIdentifiers' in selectedFunction) {
                value = [...selectedFunction.contentIdentifiers];
            }
            if (isAddAction) {
                value.push(row);
            } else {
                value.splice(i, 1);
            }

            const data = {
                target: {
                    value: value,
                    name: "contentIdentifiers",
                },
            };
            onChange(data);
        }
    };

    const handleMethodChange = (row, isAddAction, index, i) => {
        if (isFunction(onChange)) {
            let value = [];
            if ('methods' in selectedFunction) {
                value = [...selectedFunction.methods];
            }
            if (isAddAction) {
                value.push(row);
            } else {
                value.splice(i, 1);
            }

            const data = {
                target: {
                    value: value,
                    name: "methods",
                },
            };
            onChange(data);
        }
    };

    const takeFromFeedbackValues = () => {
        if (func.feedbackReport) {
            selectedFunction.participantsMen = func.feedbackReport.participantsMenAnswered;
            selectedFunction.participantsWomen = func.feedbackReport.participantsWomenAnswered;
            selectedFunction.participantsOther = func.feedbackReport.participantsOtherAnswered;
            selectedFunction.participantsSexNotAnswered	= func.feedbackReport.participantsSexNotAnsweredAnswered;
            selectedFunction.participants = selectedFunction.participantsMen + selectedFunction.participantsWomen + selectedFunction.participantsOther + selectedFunction.participantsSexNotAnswered;
            
            selectedFunction.participantsAge_1To_8 = func.feedbackReport.participantsAge1to8Answered;
            selectedFunction.participantsAge_9To_12 = func.feedbackReport.participantsAge9to12Answered;
            selectedFunction.participantsAge_13To_15 = func.feedbackReport.participantsAge13to15Answered;
            selectedFunction.participantsAge_16To_17 = func.feedbackReport.participantsAge16to17Answered;
            selectedFunction.participantsAge_18To_28 = func.feedbackReport.participantsAge18to28Answered;
            selectedFunction.participantsAge_29OrMore = func.feedbackReport.participantsAge29orMoreAnswered;
            selectedFunction.participantsAgeNotAnswered = func.feedbackReport.participantsAgeNotAnsweredAnswered
            setTemporary(true);
        }
    }

    const ncheck = (somevalue) => {
        if (somevalue == null) return 0;
        return parseInt(somevalue);
    }

    const getDataFromYouthCard = (eventId, () => {
        var id = selectedFunction.id
        getYouthCardInfo(id)

        if(youthDataList) {
            selectedFunction.participantsAge_29OrMore = ncheck(selectedFunction.participantsAge_29OrMore) + ncheck(youthDataList.age29orMore);
            selectedFunction.participants = ncheck(selectedFunction.participants) + ncheck(youthDataList.participants);
            selectedFunction.participantsWomen = ncheck(selectedFunction.participantsWomen) + ncheck(youthDataList.women);
            selectedFunction.participantsMen = ncheck(selectedFunction.participantsMen) + ncheck(youthDataList.men);
            selectedFunction.participantsOther = ncheck(selectedFunction.participantsOther) + ncheck(youthDataList.other);
            selectedFunction.participantsSexNotAnswered	= ncheck(selectedFunction.participantsSexNotAnswered) + ncheck(youthDataList.sexNotAnswered);
            selectedFunction.participantsAge_1To_8 = ncheck(selectedFunction.participantsAge_1To_8) + ncheck(youthDataList.age1to8);
            selectedFunction.participantsAge_9To_12 = ncheck(selectedFunction.participantsAge_9To_12) + ncheck(youthDataList.age9to12);
            selectedFunction.participantsAge_13To_15 = ncheck(selectedFunction.participantsAge_13To_15) + ncheck(youthDataList.age13to15);
            selectedFunction.participantsAge_16To_17 = ncheck(selectedFunction.participantsAge_16To_17) + ncheck(youthDataList.age16to17);
            selectedFunction.participantsAge_18To_28 = ncheck(selectedFunction.participantsAge_18To_28) + ncheck(youthDataList.age18to28);
            selectedFunction.participantsAgeNotAnswered = ncheck(selectedFunction.participantsAgeNotAnswered) + ncheck(youthDataList.ageNotAnswered);
            setTemporary(true);
            setLoading(true)
            setDisabled(true)

            setTimeout(() => {
                setLoading(false)
            }, 400)
        }
    })

    const createTemplateLabel = (template) => {
        let name = template.name;
        if (template.municipalityTemplate) {
            name += " - " + t('function.public_template');
        }
        else if (template.templateHandlers.length > 1) {
            name += " - " + t('function.group_template');
        }
        else {
            name += " - " + t('function.private_template');
        }
        return name;
    }

    const getFunctionTemplateOptions = () => {
        const response = [];
        for (const i in templates) {
            const template = templates[i];
            response.push({value: template.id, label: createTemplateLabel(template), template: template})
        }
        return response;
    }

    const getMethods = () => {
        const response = [];
        for (const i in methods) {
            const method = methods[i];
            response.push({id: method.id, name: t("fields." + method.name)})
        }
        response.sort((a,b) => a.name.localeCompare(b.name));
        return response;
    }

    let componentActionButtons = [];
    if (actionButtons) {
        actionButtons.forEach(function (button) {
            const key = generateKey('funcEditButton');
            selectButtonType(button, componentActionButtons, key, handleClick);
        });
    }
    let editFunctionTemplateButton = null;
    let deleteFunctionTemplateButton = null;
    let removemeFunctionTemplateButton = null;
    if (functionTemplateButtons) {
        let componentFunctionTemplateButtons = [];
        functionTemplateButtons.forEach( function (button) {
            const key = generateKey('funcTemplateButton');
            selectButtonType(button, componentFunctionTemplateButtons, key, handleFunctionTemplateClick, button.id);
        })

        componentFunctionTemplateButtons.forEach(button => {
            if (button.props.buttonIdentifier === "edit") {
                editFunctionTemplateButton = button;
            }
            else if (button.props.buttonIdentifier === "delete") {
                deleteFunctionTemplateButton = button;
            }
            else {
                removemeFunctionTemplateButton = button;
            }
        })

    }

    const encase = (rep, value) => {
        return rep ? " ("+value+")" : "";
    }

    const rep = func.feedbackReport && func.feedbackReport.eventId === selectedFunction.id;
    const r = func.feedbackReport ? func.feedbackReport : {};

    const selectedOrganizersRows = [];
    const selectedTemplateHandlersRows = [];
    if ('organizers' in selectedFunction) {
        selectedFunction.organizers.forEach(function (organizer) {
            selectedOrganizersRows.push(organizer.id);
        });
    } else if (editorActivated) {
        selectedMunicipalityUsers.forEach((user) => {
            if (user.id === authenticatedUser.id)
                selectedFunction.organizers = [user];
        });
        selectedOrganizersRows.push(authenticatedUser.id);
    }
    if ('templateHandlers' in selectedFunction) {
        selectedFunction.templateHandlers.forEach(function (handler) {
            selectedTemplateHandlersRows.push(handler.id);
        });
    } else if (editorActivated) {
        selectedMunicipalityUsers.forEach((user) => {
            if (user.id === authenticatedUser.id)
                selectedFunction.templateHandlers = [user];
        });
        selectedTemplateHandlersRows.push(authenticatedUser.id);
    }

    const permanentOrganizers = [];
    const permanentTemplateHandlers = [];
    
    const permissionToCreateFunctionBehalfOfAnother = hasPermission(PERMISSION_CREATE_FUNCTION_BEHALF_OF_ANOTHER, authenticatedUser);

    if (!permissionToCreateFunctionBehalfOfAnother) {
        permanentOrganizers.push(authenticatedUser.id);
        permanentTemplateHandlers.push(authenticatedUser.id);
    }

    const selectedContentIdentifiers = [];
    if ('contentIdentifiers' in selectedFunction) {
        selectedFunction.contentIdentifiers.forEach(function (identifier) {
            selectedContentIdentifiers.push(identifier.id);
        });
    }

    const selectedMethods = [];
    if ('methods' in selectedFunction) {
        selectedFunction.methods.forEach(function (method) {
            selectedMethods.push(method.id);
        });
    }

    const filteredContentIdentifier = () =>  {
        var filtered = contentIdentifiers.filter(
            (item) => {
                var text = t('report.current_language') === "FINNISH" ? item.titleFi : item.titleSv;
                return text !== null && text.length > 0;
            });
        const spacer = '\xA0'.repeat(6);
        filtered.forEach(row => {
            row.currentTitle = `${row.parentId === null ? '' : spacer}${t('report.current_language') === "FINNISH" ? row.titleFi : row.titleSv}${row.parentId === null ? ' - ' + t('common.nationwide_short') : ''}`
            }
        )
        filtered.sort((a,b) => a.currentTitle.localeCompare(b.currentTitle));

        const intendedContentIdentifierList = [];
        const parentsToChildrenMap = new Map();
        filtered.forEach(item => {
            if (item.parentId == null) {
                parentsToChildrenMap.set(item.id, []);
            }
        });
        filtered.forEach(item => {
            if (item.parentId !== null) {
                parentsToChildrenMap.get(item.parentId).push(item);
            }
        });
        filtered.forEach(item => {
            if (item.parentId == null) {
                intendedContentIdentifierList.push(item);
                intendedContentIdentifierList.push(...parentsToChildrenMap.get(item.id));
            }
        });

        return intendedContentIdentifierList;
    }

    const accordionHasError = (errorFields) => {
        let returnValue = false;
        errorFields.forEach(errorField => {
            if (errorField in fieldErrors) {
                returnValue = true;
            }
        })
        return returnValue;
    }

    const municipalitySelect = hasPermission(PERMISSION_CREATE_FUNCTION_ANY_MUNICIPALITY, authenticatedUser);

    if (!selectedFunction.municipalityId) {
        selectedFunction.municipalityId = selectedMunicipality.id;
    }
    if (!municipalitySelect && selectedFunction.municipalityId && selectedFunction.municipalityId !== selectedMunicipality.id) {
        var e = {
            target: {
                value: selectedMunicipality.id
            }
        }
        selectedFunction.municipalityId = selectedMunicipality.id;
        handleMunicipalityChange(e, false);
    }

    const feedbackChoices = [
        {
            label: t('function.gender_and_age'),
            key: "feedbackGenderAndAge",
            value: selectedFunction.feedbackGenderAndAge
        },
        {
            label: t('function.function_rating'),
            key: "feedbackFunctionRating",
            value: selectedFunction.feedbackFunctionRating
        },
        {
            label: t('function.function_comment'),
            key: "feedbackFunctionComment",
            value: selectedFunction.feedbackFunctionComment
        }
    ];
    const templateChoice = [
        {
            label: t('function.public_function_template'),
            key: "municipalityTemplate",
            value: selectedFunction.municipalityTemplate
        }
    ]

    const generateLinks = (event, id) => {
        event.preventDefault();
        //const errors = {};
        //if (this.state.selectedMunicipalities.length <= 0 
        //    && !(this.state.allMunicipalities && this.state.allMunicipalities.length > 0 && this.state.allMunicipalities[0] === "ALL")) {
        //    errors['selectedMunicipalities'] = 'invalid_value';
        //}
        //if (this.state.startDateFeedback === null || this.state.startDateFeedback === '') {
        //    errors['startDateFeedback'] = 'invalid_value';
        //}
        //if (this.state.endDateFeedback === null || this.state.endDateFeedback === '') {
        //    errors['endDateFeedback'] = 'invalid_value';
        //}
        //this.setState({errors: errors})

        recreateLinksForFunction(id);
    }

    const handleMultiselectChange = (event, value, choice) => {
        event.preventDefault();

        if (value) {
            selectedFunction[choice.key] = false;
        }
        else {
            selectedFunction[choice.key] = true;
        }
        
        const data = {
            target: {
                value: selectedFunction[choice.key],
                name: choice.key
            },
        };
        handleChange(data);
    }

    const handleDeleteFeedback = (row) => {
        deleteFeedback(row);
    }
    
    const generateLinksView = (
        <Grid item xs={12} sm={12}>
            <ButtonMain
                key={'generate_new_code'}
                onClick={e => generateLinks(e, selectedFunction.id)}
                disabled={!editorActivated}>
                {t('function.generate_links')}
            </ButtonMain>
        </Grid>
    );

    const getCode = (link = false) => {
        if (selectedFunction.id && func["linkupdate"+selectedFunction.id]) {
            const code = func["linkupdate"+selectedFunction.id];
            if (selectedFunction.feedbackCode !== code) {
                selectedFunction.feedbackCode = code;
            }
        }
        var loc = window.location.href+"/";
        loc.replace("function", "feedback");
        return link ? loc + selectedFunction.feedbackCode: selectedFunction.feedbackCode;
    }

    const getLink = () => {
        return "/feedback/"+getCode()+"-"+languageOption+"-"+feedbackOption+"-preview";
    }

    const feedbackLinksView = (
        <Grid item xs={12} sm={4}>
            <CompTextField
                label={t('function.feedback_code')}
                value={getCode()}
                disabled={true}
                name="feedbackLink"
            />
        </Grid>
    );

    const languageOptions = [
        {
            label: t('common.fi'),
            value: "fi"
        },
        {
            label: t('common.sv'),
            value: "sv"
        }
    ];

    const feedbackOptions = [
        {
            label: t('function.signAndfeedback'),
            value: "1"
        },
        {
            label: t('function.onlyFeedback'),
            value: "2"
        },
        {
            label: t('function.onlySignIn'),
            value: "3"
        }
    ];

    const [languageOption, setLanguageOption] = useState("fi");
    const [feedbackOption, setFeedbackOption] = useState("1");
    
    const getCreatorName = (creatorId) => {

        let creatorName = "";
        selectedFunction.organizers.forEach(organizer => {
            if (organizer.id === creatorId) {
                creatorName = organizer.firstName + " " + organizer.lastName;
            }
        });
        selectedFunction.templateHandlers.forEach(handler => {
            if (handler.id === creatorId) {
                creatorName = handler.firstName + " " + handler.lastName;
            }
        });
        

        return creatorName;
    }

    const getSortedLocations = () => {
        const response = [];
        for (var location of locations) {
            response.push(location);
        }
        const comparator = getComparator();
        response.sort((a, b) => comparator.compare(a.name,b.name));
        return response;
    }

    const getLocationTypeOptions = () => {
        const response = [];
        for (const i in locationTypes) {
            const locationType = locationTypes[i];
            response.push({value: locationType.id, label: t("fields."+locationType.name)});
        }
        const comparator = getComparator();
        response.sort((a, b) => comparator.compare(a.label,b.label));

        return response;
    }

    const handleButtonClick = () => {
        if(hasPermission(PERMISSION_EDIT_OWN_FUNCTIONS, authenticatedUser) && editorActivated) {
            navigator.clipboard.writeText(new URL(window.location.origin + "/function/edit/" + func.selectedFunction.id + "?link=true"))
            setOpen(true);
        } else {
            navigator.clipboard.writeText(new URL(window.location.origin + "/function/" + func.selectedFunction.id + "?link=true"))
            setOpen(true);
        }
      };
    
    const handleAlertClose = () => {
        setOpen(false);
    };

    const handleQRcodeLinkCopy = () => {
        navigator.clipboard.writeText(new URL(window.location.origin + new_link))
        setOpen(true)
    }

    var str = getLink()
    var new_link = str.replace(/-preview/g, '');

    const feedbackQRCodeView = (
        <Grid item xs={12} sm={8}>
            <Grid container>
                <Grid item xs={12} sm={4}>
                    <div className={'label'}>{t('function.languageOption')}</div>
                    <CompRadioGroup
                    name="languageOption"
                    selected={languageOption}
                    rows={languageOptions}
                    onChange={(e) => setLanguageOption(e.target.value)}/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <div className={'label'}>{t('function.feedbackOption')}</div>
                    <CompRadioGroup
                    name="feedbackOption"
                    selected={feedbackOption}
                    rows={feedbackOptions}
                    onChange={(e) => setFeedbackOption(e.target.value)}/>
                </Grid>
                <Grid>
                    <Grid item xs={12} sm={4} style={{marginBottom: "10%"}}>
                        <Link onClick={() => window.open(getLink())} style={{cursor: 'pointer', whiteSpace: "nowrap"}}>
                            {t('function.feedback_link')}
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Link onClick={handleQRcodeLinkCopy} style={{cursor: 'pointer', whiteSpace: "nowrap"}}>
                            {t('function.link_to_function')}
                        <CustomSnackbar open={open} onClose={handleAlertClose} severity="success" message={t('common.link_copied')} />
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
            
        </Grid>
    );

    const feedbackItems = selectedFunction.feedbackList ? selectedFunction.feedbackList : [];

    return (
        <form noValidate autoComplete="off" className={"function-container"}>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} style={{display: "flex", alignItems: "center"}}>
                            <Grid item xs={8}>
                                <CompPartitionHeader text={label}/>
                            </Grid>
                            <Grid item xs={12} className={'align-right'}>
                                {'id' in selectedFunction ? (
                                <TooltipInfo titleText={t('common.copy_link_tooltip')}>
                                </TooltipInfo>): null}
                                {'id' in selectedFunction ? (<ButtonMain onClick={handleButtonClick}>
                                    {t('common.copy_link')}
                                </ButtonMain>) : null}
                                    <CustomSnackbar open={open} onClose={handleAlertClose} severity="success" message={t('common.link_copied')} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                {municipalitySelect ?
                    <CompDropdown
                                label={t('common.municipality')}
                                valueColumn={"id"}
                                labelColumn={"name"}
                                rows={municipalityList}
                                error={fieldErrors.municipalityId}
                                hasEmptyOption={false}
                                onChange={handleMunicipalityChange}
                                disabled={!editorActivated}
                                name="municipalityId"
                                selectedValue={selectedFunction.municipalityId ? selectedFunction.municipalityId : selectedMunicipality.id}
                    />
                    :
                    <CompTextField label={t('common.municipality')}
                                   value={selectedMunicipality.name}
                                   disabled={true}
                                   error={fieldErrors.municipalityId}
                                   name="municipalityId"
                    />
                 }
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CompDropdown
                        label={t('common.organization')}
                        valueColumn={"id"}
                        labelColumn={"name"}
                        rows={organizationList}
                        error={fieldErrors.organizationId}
                        hasEmptyOption={true}
                        onChange={handleChange}
                        disabled={!editorActivated}
                        name="organizationId"
                        selectedValue={selectedFunction.organizationId}
                    />

                </Grid>
                {editorActivated && <Grid item xs={12}>
                    <CompAccordion
                        id={'accordion-ft'}
                        label={t('function.function_template_information')}
                        onChange={(event, isExpanded) => handleAccordionChange(event, isExpanded, 'ft')}
                        error={accordionHasError([
                            'template',
                            'templateHandler',
                        ]) ? t('errors.has error') : null}
                        isExpanded={allPartitionsOpen || activeAccordion === 'ft'}
                    >
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <Grid item xs={12} sm={6}>
                                            <SearchableDropdown
                                                label={t('function.saved_function_templates')}
                                                selectedValue={selectedTemplate !== null ? selectedTemplate.id : null}
                                                rows={getFunctionTemplateOptions()}
                                                disabled={!editorActivated}
                                                name="template"
                                                error={fieldErrors.template}
                                                onChange={handleTemplateChange}
                                            />
                                    {editorActivated && <CompSearchInput
                                        label={`${t('function.template_handlers')}`}
                                        multiselect
                                        rows={selectedTemplateHandlers}
                                        keyColumn={'id'}
                                        valueColumn={municipalitySelect ? ['firstName', 'lastName', [municipalities, 'mainMunicipalityId']]: ['firstName', 'lastName']}
                                        disabled={!editorActivated}
                                        selectedRows={selectedTemplateHandlersRows}
                                        permanentSelections={permanentTemplateHandlers}
                                        name="templateHandlers"
                                        error={fieldErrors.templateHandlers}
                                        onSelect={selectTemplateHandler}
                                    />}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CompTextField
                                        label={`${t('function.template_creator_name')}`}
                                        value={getCreatorName(selectedFunction.creatorId)}
                                        disabled={true}
                                        name="templateCreator"
                                        error={fieldErrors.templateCreator}
                                    />
                                    {permissionToCreateFunctionBehalfOfAnother && 
                                    <CompCheckbox 
                                        booleanmode={true}
                                        rows={templateChoice}
                                        selected={selectedFunction}
                                        disabled={!editorActivated}
                                        onChange={handleMultiselectChange} 
                                        name={"feedbackChoices"}
                                    />
                                    }
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} className={'align-right'}>
                                    <div className={"buttonSpacer"}>
                                        {showEditTemplate && editFunctionTemplateButton}
                                        {showRemovemeTemplate && removemeFunctionTemplateButton}
                                        {showDeleteTemplate && deleteFunctionTemplateButton}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CompAccordion>
                </Grid>}


                <Grid item xs={12}>
                    <CompAccordion
                        id={'accordion-tt'}
                        label={t('function.function_information')}
                        onChange={(event, isExpanded) => handleAccordionChange(event, isExpanded, 'tt')}
                        error={accordionHasError([
                            'name',
                            'eventType',
                            'contentIdentifiers',
                            'methods',
                            'startDate',
                            'endDate',
                            'eventHours',
                            'organizers'
                        ]) ? t('errors.has error') : null}
                        isExpanded={allPartitionsOpen || activeAccordion === 'tt'}
                    >
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={6}>
                                <CompTextField
                                    label={`${t('function.function_name')}*`}
                                    value={selectedFunction.name}
                                    disabled={!editorActivated}
                                    name="name"
                                    error={fieldErrors.name}
                                    onChange={handleChange}
                                />
                                <CompDropdown
                                    label={`${t('function.function_type')}*`}
                                    selectedValue={selectedFunction.eventType}
                                    rows={getFunctionTypeOptions()}
                                    disabled={!editorActivated}
                                    name="eventType"
                                    error={fieldErrors.eventType}
                                    onChange={handleChange}
                                />
                                <MultiselectDropdown
                                    label={`${t('common.content_identifier')}*`}
                                    selectedRows={selectedContentIdentifiers}
                                    rows={filteredContentIdentifier()}
                                    keyColumn={'id'}
                                    valueFunc={(row) => row.currentTitle}
                                    disabled={!editorActivated}
                                    name="contentIdentifiers"
                                    error={fieldErrors.contentIdentifiers}
                                    onSelect={handleContentIdentifierChange}
                                />
                                <MultiselectDropdown
                                    label={t('common.methods')}
                                    selectedRows={selectedMethods}
                                    rows={getMethods()}
                                    keyColumn={'id'}
                                    valueFunc={(row) => row.name}
                                    disabled={!editorActivated}
                                    name="methods"
                                    error={fieldErrors.methods}
                                    onSelect={handleMethodChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} sm={6}>
                                        <CompDatePicker
                                            label={`${t('function.start_date')}*`}
                                            value={formatISODateStr(selectedFunction.startDatetime)}
                                            disabled={!editorActivated}
                                            name="startDate"
                                            error={fieldErrors.startDatetime}
                                            onChange={e => handleDateTimeChange(e, 'startDate')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <CompDatePicker
                                            label={`${t('function.end_date')}*`}
                                            value={formatISODateStr(selectedFunction.endDatetime)}
                                            disabled={!editorActivated}
                                            name="endDate"
                                            error={fieldErrors.endDatetime}
                                            onChange={e => handleDateTimeChange(e, 'endDate')}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} sm={6}>
                                        <CompTimePicker
                                            label={`${t('function.start_time')}*`}
                                            value={selectedFunction.startDatetime}
                                            disabled={!editorActivated}
                                            name="startTime"
                                            error={fieldErrors.startDatetime}
                                            onChange={e => handleDateTimeChange(e, 'startTime')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <CompTimePicker
                                            label={`${t('function.end_time')}*`}
                                            value={selectedFunction.endDatetime}
                                            disabled={!editorActivated}
                                            name="endTime"
                                            error={fieldErrors.endDatetime}
                                            onChange={e => handleDateTimeChange(e, 'endTime')}
                                        />
                                    </Grid>
                                </Grid>
                                <EventHoursTimePicker
                                    label={t('function.event_hours')}
                                    value={selectedFunction.eventHours}
                                    disabled={!editorActivated}
                                    name="eventHours"
                                    error={fieldErrors.eventHours}
                                    onChange={handleChange}
                                />
                                <CompSearchInput
                                    label={`${t('function.organizers')}*`}
                                    multiselect
                                    rows={selectedMunicipalityUsers}
                                    keyColumn={'id'}
                                    valueColumn={municipalitySelect ? ['firstName', 'lastName', [municipalities, 'mainMunicipalityId']]: ['firstName', 'lastName']}
                                    disabled={!editorActivated}
                                    selectedRows={selectedOrganizersRows}
                                    permanentSelections={permanentOrganizers}
                                    name="organizers"
                                    error={fieldErrors.organizers}
                                    onSelect={selectOrganizer}
                                />
                            </Grid>
                        </Grid>
                    </CompAccordion>
                </Grid>
                <Grid item xs={12}>
                    <CompAccordion
                        id={'accordion-kt'}
                        label={t('function.location_info')}
                        onChange={(event, isExpanded) => handleAccordionChange(event, isExpanded, 'kt')}
                        error={accordionHasError([
                            'locationId',
                            'locationName',
                            'locationPostCode',
                            'locationType'
                        ]) ? t('errors.has error') : null}
                        isExpanded={allPartitionsOpen || activeAccordion === 'kt'}
                    >
                        <Grid className="locationInfoCtr" container spacing={0}>
                            <Grid item xs={12} sm={6}>
                                <CompDropdown
                                    label={t('function.location')}
                                    selectedValue={selectedFunction.locationId ? selectedFunction.locationId : ""}
                                    rows={getSortedLocations()}
                                    name="locationId"
                                    labelColumn="name"
                                    valueColumn="id"
                                    disabled={!editorActivated}
                                    error={fieldErrors.locationId}
                                    onChange={onLocationSelectionChange}
                                    hasEmptyOption={true}
                                />
                                <CompTextField
                                    label={`${t('function.location_name')}*`}
                                    value={selectedFunction.locationName}
                                    disabled={!editorActivated || selectedFunction.locationId != null}
                                    name="locationName"
                                    error={fieldErrors.locationName}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CompTextField
                                    label={`${t('common.post_code')}`}
                                    value={selectedFunction.locationPostCode}
                                    disabled={!editorActivated || selectedFunction.locationId != null}
                                    name="locationPostCode"
                                    error={fieldErrors.locationPostCode}
                                    onChange={handleChange}
                                />
                                <CompDropdown
                                    label={`${t('location.location_type')}*`}
                                    selectedValue={selectedFunction.locationTypeId}
                                    rows={getLocationTypeOptions()}
                                    name="locationType"
                                    labelColumn="label"
                                    valueColumn="value"
                                    disabled={!editorActivated || selectedFunction.locationId != null}
                                    error={fieldErrors.locationType}
                                    onChange={onLocationTypeSelectionChange}
                                    hasEmptyOption={true}
                                />
                            </Grid>

                        </Grid>
                    </CompAccordion>
                </Grid>
                <Grid item xs={12}>
                    <CompAccordion
                        id={'accordion-ot'}
                        label={t('function.participant_info')}
                        onChange={(event, isExpanded) => handleAccordionChange(event, isExpanded, 'ot')}
                        error={accordionHasError([
                            'participants',
                            'youthOrganizers',
                            'eventHoursOfYouthOrganizers',
                            'description',
                            "participantsAge",
                            "participantsSex"
                        ]) ? t('errors.has error') : null}
                        isExpanded={allPartitionsOpen || activeAccordion === 'ot'}
                    >
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={4}>
                                <CompTextField
                                    label={t('function.participants')}
                                    value={selectedFunction.participants}
                                    disabled={!editorActivated}
                                    name="participants"
                                    type={'number'}
                                    error={fieldErrors.participants}
                                    onChange={handleNonNegativeChange}
                                />
                                <CompTextField
                                    label={t('function.youth_organizers')}
                                    value={selectedFunction.youthOrganizers}
                                    disabled={!editorActivated}
                                    name="youthOrganizers"
                                    type={'number'}
                                    error={fieldErrors.youthOrganizers}
                                    onChange={handleNonNegativeChange}
                                />
                                <CompTextField
                                    label={t('function.event_hours_of_youth_organizers')}
                                    value={selectedFunction.eventHoursOfYouthOrganizers}
                                    disabled={!editorActivated}
                                    name="eventHoursOfYouthOrganizers"
                                    type={'number'}
                                    error={fieldErrors.eventHoursOfYouthOrganizers}
                                    onChange={handleNonNegativeChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Grid container spacing={0}>
                                    {(!temporary && showParticipantInformation && ((rep || !selectedFunction.id) || !selectedFunction.feedbackGenderAndAge)) && (<Grid item xs={12} sm={6}>
                                        <CompInputList
                                            label={t('function.participants_pieces')}
                                            name="participantsSex"
                                            rows={[
            {value: selectedFunction.participantsMen, key: "participantsMen", label: `${t('function.men') + encase(rep, r.participantsMenAnswered)}`},
            {value: selectedFunction.participantsWomen, key: "participantsWomen", label: `${t('function.women') + encase(rep, r.participantsWomenAnswered)}`},
            {value: selectedFunction.participantsOther, key: "participantsOther", label: `${t('function.other') + encase(rep, r.participantsOtherAnswered)}`},
            {value: (row, rows, total, expectedTotal) => expectedTotal == null ? 0 : expectedTotal - total, key: "participantsSexNotAnswered", label: `${t('function.not_answered') + encase(rep, r.participantsSexNotAnsweredAnswered)}` },
        ]}
                                            disabled={!editorActivated}
                                            expectedTotal={selectedFunction.participants}
                                            error={fieldErrors.participantsSex}
                                            onChange={handleChange}
                                        />
                                    </Grid>)}
                                    {(!temporary && showParticipantInformation && ((rep || !selectedFunction.id) || !selectedFunction.feedbackGenderAndAge)) && (
                                    <Grid item xs={12} sm={6}>
                                        <CompInputList
                                            label={t('function.participants_age_info')}
                                            name="participantsAge"
                                            rows={[
            {value: selectedFunction.participantsAge_1To_8, key: "participantsAge_1To_8", label: `${t('function.age_1_to_8') + encase(rep, r.participantsAge1to8Answered)}`},
            {value: selectedFunction.participantsAge_9To_12, key: "participantsAge_9To_12", label: `${t('function.age_9_to_12') + encase(rep, r.participantsAge9to12Answered)}`},
            {value: selectedFunction.participantsAge_13To_15, key: "participantsAge_13To_15", label: `${t('function.age_13_to_15') + encase(rep, r.participantsAge13to15Answered)}`},
            {value: selectedFunction.participantsAge_16To_17, key: "participantsAge_16To_17", label: `${t('function.age_16_to_17') + encase(rep, r.participantsAge16to17Answered)}`},
            {value: selectedFunction.participantsAge_18To_28, key: "participantsAge_18To_28", label: `${t('function.age_18_to_28') + encase(rep, r.participantsAge18to28Answered)}`},
            {value: selectedFunction.participantsAge_29OrMore, key: "participantsAge_29OrMore", label: `${t('function.age_29_or_more') + encase(rep, r.participantsAge29orMoreAnswered)}`},
            {value: (row, rows, total, expectedTotal) => expectedTotal == null ? 0 : expectedTotal - total, key: "participantsAgeNotAnswered", label: `${t('function.unknown') + encase(rep, r.participantsAgeNotAnsweredAnswered)}`},
        ]}
                                            disabled={!editorActivated}
                                            expectedTotal={selectedFunction.participants}
                                            error={fieldErrors.participantsAge}
                                            onChange={handleChange}/>
                                    </Grid>)}
                                    {rep && editorActivated && (
                                    <Grid item xs={12} sm={12}>
                                        <ButtonMain
                                            key={'load_csv'}
                                            onClick={e => takeFromFeedbackValues(e)}>
                                            {t('function.use_feedback_values')}
                                        </ButtonMain>
                                    </Grid>)}
                                    {false && editorActivated && selectedFunction.id &&
                                <ButtonMain
                                        disabled={!editorActivated || disabled}
                                        onClick={e => getDataFromYouthCard(e)}>
                                        {loading ? `${t('function.loading')}` : `${t('function.loadYouthData')}`}
                                    </ButtonMain>}
                                    {loading && <CircularProgress className="loader" />}
                                </Grid>
                            </Grid>
                            {rep && (<Grid item xs={12} sm={2}>
                                <div className={'label'}>{`${t('function.feedback_rating')+" "+(Math.round(r.participantsRatingAverage * 100) / 100)+""}`}</div>
                            </Grid>)}
                            <Grid item xs={12} sm={12}>
                                <CompTextField
                                    label={t('function.description')}
                                    value={selectedFunction.description}
                                    disabled={!editorActivated}
                                    name="description"
                                    multiline
                                    rows={6}
                                    error={fieldErrors.description}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </CompAccordion>
                </Grid>
                <Grid item xs={12}>
                    <CompAccordion
                        id={'accordion-pt'}
                        label={t('function.feedback_info')}
                        onChange={(event, isExpanded) => handleAccordionChange(event, isExpanded, 'pt')}
                        error={accordionHasError([
                            'feedbackChoices',
                            'startDateFeedback',
                            'endDateFeedback'
                        ]) ? t('errors.has error') : null}
                        isExpanded={allPartitionsOpen || activeAccordion === 'pt'}
                    >
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={6}>
                                <CompCheckbox 
                                    booleanmode={true}
                                    rows={feedbackChoices}
                                    selected={selectedFunction}
                                    disabled={!editorActivated}
                                    onChange={handleMultiselectChange} 
                                    name={"feedbackChoices"}
                                />
                            </Grid>
                            <Grid container spacing={0}>
                                    <Grid item xs={12} sm={6}>
                                        <CompDatePicker
                                            label={`${t('function.start_date_feedback')}`}
                                            value={formatISODateStr(selectedFunction.startDatetimeFeedback)}
                                            disabled={!editorActivated}
                                            name="startDateFeedback"
                                            error={fieldErrors.startDatetimeFeedback}
                                            onChange={e => handleDateTimeChange(e, 'startDateFeedback')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <CompDatePicker
                                            label={`${t('function.end_date_feedback')}`}
                                            value={formatISODateStr(selectedFunction.endDatetimeFeedback)}
                                            disabled={!editorActivated}
                                            name="endDateFeedback"
                                            error={fieldErrors.endDatetimeFeedback}
                                            onChange={e => handleDateTimeChange(e, 'endDateFeedback')}
                                        />
                                    </Grid>
                            </Grid>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} sm={6}>
                                        <CompTimePicker
                                            label={`${t('function.start_time_feedback')}`}
                                            value={selectedFunction.startDatetimeFeedback}
                                            disabled={!editorActivated}
                                            name="startTimeFeedback"
                                            error={fieldErrors.startDatetimeFeedback}
                                            onChange={e => handleDateTimeChange(e, 'startTimeFeedback')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <CompTimePicker
                                            label={`${t('function.end_time_feedback')}`}
                                            value={selectedFunction.endDatetimeFeedback}
                                            disabled={!editorActivated}
                                            name="endTimeFeedback"
                                            error={fieldErrors.endDatetimeFeedback}
                                            onChange={e => handleDateTimeChange(e, 'endTimeFeedback')}
                                        />
                                    </Grid>
                                </Grid>
                            {selectedFunction.id && editorActivated && generateLinksView}
                            {selectedFunction.id && feedbackLinksView}
                            {selectedFunction.id && selectedFunction.feedbackCode && feedbackQRCodeView}
                        </Grid>
                    </CompAccordion>
                </Grid>
                {selectedFunction.id && (
                    <Grid item xs={12}>
                        <CompAccordion
                            id={'accordion-pd'}
                            label={t('function.feedback_data')}
                            onChange={(event, isExpanded) => handleAccordionChange(event, isExpanded, 'pd')}
                            error={accordionHasError([
                                'feedbackChoices',
                                'startDateFeedback',
                                'endDateFeedback'
                                ]) ? t('errors.has error') : null}
                                isExpanded={allPartitionsOpen || activeAccordion === 'pd'}
                            >
                            <Grid container spacing={0}>
                                <Grid item xs={12} sm={12}>
                                    <TableContainer component={Paper} className={'CompSystemLogTable-container'}>
                                        <Table className={'CompSystemLogTable-table'} aria-label={label}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>{t('feedback.date')}</TableCell>
                                                    <TableCell>{t('feedback.age')}</TableCell>
                                                    <TableCell>{t('feedback.gender')}</TableCell>
                                                    <TableCell>{t('feedback.rating')}</TableCell>
                                                    <TableCell>{t('feedback.comment')}</TableCell>
                                                    <TableCell/>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {feedbackItems.map((row, index) => (
                                                    <TableRow
                                                        hover
                                                        tabIndex={-1}
                                                        key={`row-${index}`}
                                                    >
                                                        <TableCell>{row.date}</TableCell>
                                                        <TableCell>{row.age !== null ? t(ageOptions.filter(obj => {return obj.value === row.age.toString();})[0].label) : ''}</TableCell>
                                                        <TableCell>{row.gender !== null ? t(genderOptions.filter(obj => {return obj.value === row.gender.toString();})[0].label) : ''}</TableCell>
                                                        <TableCell>{row.rating ? (row.rating === -1 ? t('feedback.not_answered') : row.rating) : ''}</TableCell>
                                                        <TableCell>{row.comment}</TableCell>
                                                        <TableCell>
                                                            <ButtonMain
                                                                key={'delete_feedback'}
                                                                onClick={e => handleDeleteFeedback(row.id)}>
                                                                {t('function.delete')}
                                                            </ButtonMain>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </CompAccordion>
                    </Grid>
            )}
            </Grid>
            <Grid container>
                <Grid item xs={12} className={'align-right'}>
                    <ButtonSpacer>
                        {componentActionButtons}
                    </ButtonSpacer>
                </Grid>
            </Grid>
        </form>
    )
}

FunctionFormComponent.propTypes = {
    label: PropTypes.string,
    editorActivated: PropTypes.bool,
    onChange: PropTypes.func,
    selectedFunction: PropTypes.object,
    selectedLocation: PropTypes.object,
    fieldErrors: PropTypes.object,
    actionButtons: PropTypes.array,
    functionTemplateButtons: PropTypes.array,
    allPartitionsOpen: PropTypes.bool,
    updateData: PropTypes.bool,
    youthData: PropTypes.object
};

const mapStateToProps = (state, ownProps) => {
    // return state;
    return {
        municipalities: state.municipality.municipalities,
        selectedMunicipality: state.municipality.selectedMunicipality,
        selectedMunicipalityUsers: state.municipality.selectedMunicipalityUsers,
        selectedTemplateHandlers: state.municipality.selectedTemplateHandlers,
        organizations: state.municipality.organizations,
        organizationTree: state.municipality.organizationTree,
        locations: state.location.locations,
        locationTypes: state.root.locationTypes,
        contentIdentifiers: state.root.contentIdentifiers,
        methods: state.root.methods,
        templates: state.func.templates,
        func: state.func,
        authenticatedUser: state.auth.authenticatedUser,
        props: ownProps
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getLocation: (id, onSuccess) => dispatch(getLocation(id, onSuccess)),
        setFunction: (data) => dispatch(setFunction(data)),
        listFunctionTemplates: (municipalityId) => dispatch(listFunctionTemplates(municipalityId)),
        listLocations: (municipalityId) => dispatch(listLocations(municipalityId)),
        listMunicipalityUsersSilent: (municipalityId) => dispatch(listMunicipalityUsersSilent(municipalityId)),
        listOrganizations: (municipalityId) => dispatch(listOrganizations(municipalityId)),
        getOrganizationTree: (municipalityId, level) => dispatch(getOrganizationTree(municipalityId, level)),
        updateContentIdentifierChoices: (municipalityId) => dispatch(updateContentIdentifierChoices(municipalityId)),
        recreateLinksForFunction: (id, onSuccess, onFailure) => dispatch(recreateLinksForFunction(id, onSuccess, onFailure)),
        getFeedbackReport: (eventId) => dispatch(getFeedbackReport(eventId)),
        deleteFeedback: (feedbackItemId) => dispatch(deleteFeedback(feedbackItemId)),
        listFeedback: (eventId) => dispatch(listFeedback(eventId)),
        getYouthCardInfo: (eventId) => dispatch(getYouthCardInfo(eventId)),
        setYouthData: (data) => dispatch(setYouthData(data)) 
    };
}

const FunctionForm = connect(mapStateToProps, mapDispatchToProps)(FunctionFormComponent);

export {FunctionForm};