import React from 'react';
import {Application} from "../../application/MainApp";
import {setBreadcrumbs} from "../root/root.actions";
import {connect} from "react-redux";
import {HomePage} from "./components/homePage";

class HomeComponent extends React.Component {
    constructor(props) {
        super(props);
        props.setBreadcrumbs(props.match);
    }

    render() {
        return (
            <Application>
                <HomePage action={this.props.match.params.action}/>
            </Application>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setBreadcrumbs: (match) => dispatch(setBreadcrumbs(match)),
    };
}

const Home = connect(
    null,
    mapDispatchToProps
)(HomeComponent);

export {Home};