import React from 'react';
import {Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import {CompTextField} from "../../components/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {passwordRenewalMessageSent, sendRenewalMessage} from "../auth/auth.actions";
import Link from "@material-ui/core/Link";
import {Copyright} from "../../components/Copyright";


class ForgotPasswordComponent extends React.Component {

    constructor(props) {
        super(props);
        this.userId = this.props.match.params.userId;
        this.hash = this.props.match.params.hash;
        this.key = this.props.match.params.key;
        this.state = {
            error: {},
            messageSent: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        if(this.state.email === undefined || this.state.email=== null || this.state.email === "") {
            this.setState({
                error: {
                    email: this.props.t("forgot_password.email_missing")
                }
            });
            return;
        }
        this.setState({error: {}});
        this.props.sendRenewalMessage(this.state.email, () => {
            this.setState({messageSent: true});
            return passwordRenewalMessageSent();
        });
    }


    render() {
        const {t} = this.props;

        let paper = (
            <div className={'paper'}>
                <div className={'paperHeader'}>
                    <img className={'appLogo'}
                         src={process.env.PUBLIC_URL + '/media/images/Logo_nuoDo_login.svg'}
                         alt={t('common.youth_work')}/>
                </div>
                <div className={'paperContent'}>
                    <Typography component="h1" className={'title'}>
                    {t('change_password.title')}
                </Typography>
                <Typography align={'center'} gutterBottom={true}>
                    {t('change_password.description')}
                </Typography>
                <form className={'form'} noValidate autoComplete="off"
                      onSubmit={this.handleSubmit}>
                    <CompTextField
                        variant="outlined"
                        required
                        fullWidth
                        id="email"
                        label={t('forgot_password.email')}
                        name="email"
                        autoComplete="email"
                        errorAlreadyTranslated={true}
                        autoFocus
                        error={this.state.error.email}
                        onChange={this.handleChange}
                        value={this.state.email}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={'comp-button button-main submit'}
                    >
                        {t('forgot_password.send_button')}
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link href="/login" className={'forgotPass'} variant="body2">
                                {t('forgot_password.to_login')}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
                </div>
                <div className={'paperFooter'}>
                    <Copyright/>

                    <img
                        src={process.env.PUBLIC_URL + '/media/images/kuntaliittoLogo.svg'}
                        alt={t('common.youth_work')}/>
                </div>
            </div>
        )
        if (this.state.messageSent) {
            paper = (
                <div className={'paper'}>
                    <Typography component="h1" variant="h5" gutterBottom={true}>
                        {t('forgot_password.message_sent_title')}
                    </Typography>
                    <Typography align={'center'} gutterBottom={true}>
                        {t('forgot_password.message_sent_description')}
                    </Typography>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={'submit'}
                        href={'/login'}
                    >
                        {t('forgot_password.to_login')}
                    </Button>
                    <Box mt={5}>
                        <Copyright/>
                    </Box>
                </div>
            )
        }

        return (
            <Grid container component="main" className={'loginRoot'}>
                <CssBaseline/>
                <Grid item xs={false} sm={4} md={7} className={'loginBackgroundImage'}/>
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    {paper}
                </Grid>
            </Grid>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        sendRenewalMessage: (userEmail, onSuccess) => dispatch(sendRenewalMessage(userEmail, onSuccess)),
    };
}

const ForgotPassword = connect(
    null,
    mapDispatchToProps
)(withTranslation('translation')(ForgotPasswordComponent));
export {ForgotPassword};
