import React from "react";
import * as PropTypes from "prop-types";
import {formatDateStr, getComparator, isFunction} from "../../../utils";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {TablePaginationActions} from "../../../components/TablePaginationActions";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";


const CompContentIdentifierTableComponent = ({
                                                 selectedContentIdentifier,
                                                 props
                                             }) => {
    const {
        rows = [],
        rowClick = (e, row, isAddAction, index) => {
            console.log((e + "," + row + "," + isAddAction + "," + index));
        },
        label = "",
        initialRowsPerPage = 10
    } = props;

    const {t} = useTranslation('translation');

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(initialRowsPerPage);

    if (rows.length === 0) {
        return (<div/>)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSelectionToggle = (e, row, index) => {
        const isAddAction = !isSelected(row);

        if (isFunction(rowClick)) {
            rowClick(e, row, isAddAction, index)
        }
    }

    const isSelected = (row) => row.id === selectedContentIdentifier.id;

    const comparator = getComparator();
    for (var r of rows) {
        if (!r.titleFi) {
            r.titleFi = "";
        }
    }
    rows.sort((a, b) => comparator.compare(a.titleFi,b.titleFi))
    
    return (
        <div className={'CompContentIdentifierTable-wrapper'}>
            <div className={'CompContentIdentifierTable-mainTitle'}>{label}</div>
            <TableContainer component={Paper} className={'CompContentIdentifierTable-container'}>
                <Table className={'CompContentIdentifierTable-table'} aria-label={label}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('content_identifier.identifier_name_fi')}</TableCell>
                            <TableCell>{t('content_identifier.identifier_name_sv')}</TableCell>
                            <TableCell>{t('content_identifier.creator')}</TableCell>
                            <TableCell>{t('content_identifier.timestamp')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : rows
                        ).map((row, index) => {
                            const isRowSelected = isSelected(row);
                            return (

                                <TableRow
                                    hover
                                    onClick={(e) => handleSelectionToggle(e, row, index)}
                                    role="checkbox"
                                    aria-checked={isRowSelected}
                                    tabIndex={-1}
                                    key={`CompContentIdentifierTable-row-${row.id}`}
                                    selected={isRowSelected}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.titleFi}
                                    </TableCell>
                                    <TableCell>{row.titleSv}</TableCell>
                                    <TableCell>{row.creator}</TableCell>
                                    <TableCell>{formatDateStr(row.createdAt)}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}

                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                labelRowsPerPage={t('components.rows_per_page')}
                                page={page}
                                SelectProps={{
                                    inputProps: {'aria-label': t('components.rows_per_page')},
                                    native: true,
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </div>
    );
}

CompContentIdentifierTableComponent.propTypes = {
    rows: PropTypes.array,
    rowClick: PropTypes.func,
    label: PropTypes.string,
    multiselect: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => {
    // return state;
    return {
        selectedContentIdentifier: state.contentIdentifier.selectedContentIdentifier,
        props: ownProps
    }
}

const CompContentIdentifierTable = connect(mapStateToProps)(CompContentIdentifierTableComponent);

export {
    CompContentIdentifierTable,
}
