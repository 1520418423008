import {types} from "./auth.types";


export const initialState = {
    token: null,
    authenticatedUser: {}
}

export default function moduleReducer(state = initialState, action) {
    switch (action.type) {
        case types.AUTH_SET_AUTH_USER_INFO:
            return {
                ...state,
                authenticatedUser: action.payload
            }
        case types.AUTH_LOGIN:
            return {
                ...state,
                token: action.payload.headers.authorization,
                authenticatedUser: action.payload.data
            }
        case types.AUTH_LOGOUT:
            return {
                ...state,
                token: null,
                authenticatedUser: {}
            }
        case types.AUTH_API_ERROR:
            if (action.payload == null) {
                return state;
            }
            if (typeof action.payload === "string") {
                console.log("API error: " + action.payload)
            } else if ('status' in action.payload && action.payload.status === 405) {
                console.log("API error: " + action.payload.error)
            } else if ('data' in action.payload && action.payload.data !== null) {
                console.log("API error: " + action.payload.message)
            } else {
                console.log("API error: " + action.payload)
            }
            return {
                ...state,
                token: null,
                authenticatedUser: {}
            }
        default:
            // Do not use the default case
            // A dispatch action will pass through each of the reducers associated with this one.
            // An action at the other end of your application could affect this state
            // without being expressed in the code. This should be avoided.
            return state
    }
}
