import React from 'react';
import {Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

import Link from "@material-ui/core/Link";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import {CompTextField} from "../../components/TextField";
import Button from "@material-ui/core/Button";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {login, setLoggedIn} from "./auth.actions";
import {getMunicipality} from "../municipality/municipality.actions";
import {closeAlert, getChoices} from "../root/root.actions";
import {Copyright} from "../../components/Copyright";
import {getReminders} from "../user/user.actions";
import {accessDeniedResponse} from "../api/actions";
import Alert from "@material-ui/lab/Alert";
import PublicIcon from '@material-ui/icons/Public';
import {ReactComponent as ErrorIcon} from "../../styles/images/IconError.svg";
import {ReactComponent as WarningIcon} from "../../styles/images/IconWarning.svg";
import {ReactComponent as InfoIcon} from "../../styles/images/IconInfo.svg";
import {ReactComponent as SuccessIcon} from "../../styles/images/IconSuccess.svg";
import i18n from "../../i18n.js";


class LoginComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            email: '',
            password: '',
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.changeLanguage = this.changeLanguage.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({error: ''});
        this.props.login(this.state.email, this.state.password, (data, response) => {
            // fetch initial municipality
            this.props.closeAlert();
            this.props.setLoggedIn(data, response);
            this.props.getChoices();
            this.props.getReminders();
            this.props.getMunicipality(data.mainMunicipalityId);
            return setLoggedIn(data, response);
        }, (error) => {
            console.log(error);
            return accessDeniedResponse(error);
        })
    }
    
    changeLanguage(lng) {
        localStorage.setItem('loc_id', lng)
        i18n.changeLanguage(lng);
    }


    render() {
        const fiLink = "https://www.kuntaliitto.fi/kunnallisen-nuorisotyon-dokumentaatio-hanke"
        const svLink = "https://www.kommunforbundet.fi/valfard-och-bildning/utvecklingsprojekt-inom-bildningssektorn/projektet-dokumentation-av-kommunalt-ungdomsarbete"
        let lang = "";

        const {t} = this.props;
        const alert = this.props.alert;
        let alertComponent = (<div/>);
        if (alert !== undefined && alert.isOpen === true) {
            alertComponent = (
                <Alert onClose={this.props.closeAlert}
                       severity={alert.severity}
                       iconMapping={{
                           error: <ErrorIcon fontSize="inherit"/>,
                           warning: <WarningIcon fontSize="inherit"/>,
                           info: <InfoIcon fontSize="inherit"/>,
                           success: <SuccessIcon fontSize="inherit"/>
                       }}
                >{alert.text}</Alert>
            );
        }

        let langChangeLink = (
            <Button onClick={() => this.changeLanguage(i18n.language === "sv" ? "fi" : "sv")}>
                <PublicIcon />
                <span>{t(i18n.language === "sv" ? 'common.fi' : 'common.sv')}</span>
            </Button>
        );
        
        if(i18n.language === "fi") {
            lang = fiLink
        } else {
            lang = svLink
        }

        return (
            <Grid container component="main" className={'loginRoot'}>
                <CssBaseline/>
                <Grid item xs={false} sm={3} md={6} className={'loginBackgroundImage'}/>
                <Grid item xs={12} sm={9} md={6} component={Paper} elevation={6} square>
                    <div className={'feedback'}>
                        <Button href="/feedback">
                             <span>{t('common.feedback')}</span>
                        </Button>
                    </div>
                    <div className={'langChange'}>
                        {langChangeLink}
                    </div>
                    <div className={'paper'}>
                        <div className={'paperHeader'}>
                            <img className={'appLogo'}
                                 src={process.env.PUBLIC_URL + '/media/images/Logo_nuoDo_login.svg'}
                                 alt={t('common.youth_work')}/>
                        </div>
                        <div className={'paperContent'}>
                            <Typography component="h1" className={'title'}>
                                {t('login.title')}
                            </Typography>
                            {alertComponent}
                            <form className={'form'} noValidate autoComplete="off"
                                  onSubmit={this.handleSubmit}>
                                <CompTextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="email"
                                    label={t('login.email')}
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    onChange={this.handleChange}
                                    value={this.state.email}
                                />
                                <CompTextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    name="password"
                                    label={t('login.password')}
                                    type="password"
                                    id="password"
                                    onChange={this.handleChange}
                                    value={this.state.password}
                                    autoComplete="current-password"
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={'comp-button button-main submit'}
                                >
                                    {t('login.login_button')}
                                </Button>
                                <Link href="/forgot_password" className={'forgotPass'} variant="body2">
                                    {t('login.forgot_password')}
                                </Link>
                            </form>

                        </div>
                        <div className={'paperFooter'}>
                            <Copyright/>

                            <Link href={lang}>
                                <img
                                    src={process.env.PUBLIC_URL + '/media/images/kuntaliittoLogo.svg'}
                                    alt={t('common.youth_work')}
                                />
                            </Link>
                        </div>


                    </div>
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    return {
        alert: state.root.alert
    };
}


const mapDispatchToProps = dispatch => {
    return {
        login: (username, password, onSuccess, onFailure) => dispatch(login(username, password, onSuccess, onFailure)),
        setLoggedIn: (data, response) => dispatch(setLoggedIn(data, response)),
        closeAlert: () => dispatch(closeAlert()),
        getMunicipality: (id) => dispatch(getMunicipality(id)),
        getChoices: () => dispatch(getChoices()),
        getReminders: () => dispatch(getReminders()),
    };
}

const Login = connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation('translation')(LoginComponent));
export {Login};