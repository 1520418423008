import React from "react";
import {generateKey, isFunction} from "../utils";
import * as PropTypes from "prop-types";
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from "moment";
import {useTranslation} from "react-i18next";
import "moment/locale/fi";

const CompDatePicker = (props) => {
    const key = generateKey('datepicker');

    let {label, error, value = null, id, disabled, onChange, canBeEmpty = false, ...componentProps} = props;
    const {t} = useTranslation('translation');

    const container = React.createRef();

    const handleDateChange = (date) => {
        if (date === null) {
            if (canBeEmpty) {
                if (isFunction(onChange)) {
                    const data = {
                        target: {
                            ...componentProps,
                            value: null,
                            id: key,
                        },
                    };
                    onChange(data)
                }
            }
            return;
        }
        if (!date.isValid()) return;
        const format = "YYYY-MM-DD"
        if (isFunction(onChange)) {
            const data = {
                target: {
                    ...componentProps,
                    value: date.format(format),
                    id: key,
                },
            };
            onChange(data)
        }
    };

    const stringValueToDate = (stringValue) => {
        let value = moment.utc(stringValue);
        if(value.isValid()) return value;
        return null;

    };

    return (
        <div
            id={`comp-datepicker-container-${key}`}
            className={`comp-datepicker-container ${props.className ? 'container-' + props.className : ''} ${error ? 'error' : ''} ${disabled ? 'disabled': ''}`}
            ref={container}>
            <div className={'label'}>{label}</div>
            <MuiPickersUtilsProvider locale="fi" utils={MomentUtils}>
                <KeyboardDatePicker
                    {...componentProps}
                    disabled={disabled}
                    disableToolbar
                    format="DD.MM.YYYY"
                    margin="normal"
                    id={key}
                    value={stringValueToDate(value)}
                    helperText={error?t("errors."+error):error}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': {label},
                    }}
                    cancelLabel={t('common.cancel')}
                />
            </MuiPickersUtilsProvider>
        </div>
    );
}

CompDatePicker.propTypes = {
    label: PropTypes.string,
    error: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func
};

export {CompDatePicker};