import React from "react";
import {Breadcrumbs } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

import {connect} from "react-redux";
import {Link} from "react-router-dom";

const mapStateToProps = (state) => {
    return {
        breadcrumbs: state.root.breadcrumbs
    }
}

class BreadcrumbsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.breadcrumbs = props.breadcrumbs;
    }

    render() {
        return (
            <Breadcrumbs separator="-" aria-label="breadcrumb" className={'breadcrumb'}>
                {
                    this.breadcrumbs.map(({name, path}, key) =>
                        key + 1 === this.breadcrumbs.length ? (
                            <Typography key={key}>{name}</Typography>
                        ) : (
                            <Link key={key} to={path} className={'appBar-link'}>
                                {name}
                            </Link>
                        )
                    )
                }
            </Breadcrumbs>
        );
    }
}

const CompBreadcrumbs = connect(
    mapStateToProps
)(BreadcrumbsComponent);

export {CompBreadcrumbs};