import React from "react";
import * as PropTypes from "prop-types";
import {formatDateStr, getComparator} from "../../../utils";
import {useTranslation} from "react-i18next";
import {
    hasPermission,
    hasPermissionToHandleRole,
    PERMISSION_EDIT_OTHER_USERS,
    PERMISSION_EDIT_OWN_USER_INFO,
    PERMISSION_VIEW_OTHER_MUNICIPALITIES_USER_LISTS
} from "../../../permissions";
import {IconButton} from "@material-ui/core";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import {getUserIconUrl} from "../../root/root.utils";


const CompUserCards = (props) => {
    const {
        users = [], actions = [],
        rowClick = (e, row) => {
            console.log((e + "," + row));
        },
        authenticatedUser = {},
        initialRowsPerPage = 10,
        municipalities
    } = props;

    const {t} = useTranslation('translation');

    const [rowsPerPage, setRowsPerPage] = React.useState(initialRowsPerPage);
    const [lastRowsPerPage, setLastRowsPerPage] = React.useState(initialRowsPerPage);
    const [startIndex, setStartIndex] = React.useState(0);
    const [endIndex, setEndIndex] = React.useState(initialRowsPerPage);

    if (users.length === 0) {
        return (<div/>)
    }
    const changePage = (e, referenceId, limit, direction) => {
        let referenceIndex = -1;
        const lastIndex = users.length -1;

        if (limit !== lastRowsPerPage) {
            setLastRowsPerPage(limit);
            setStartIndex(0);
            setEndIndex(limit);
            return;
        }

        if (referenceId == null) {
            if (startIndex > 0) {
                referenceIndex = lastIndex;
            }
        }
        else {
            for (var i = 0; i < users.length; i++) {
                if (users[i].id === referenceId) {
                    referenceIndex = i+1;
                    break;
                }
            }
        }

        if(referenceIndex < 0) {
            return;
        }

        var start = startIndex;
        if(direction ==='forward' && (startIndex + limit < lastIndex)){
            start = referenceIndex >= endIndex ? endIndex : referenceIndex;
        } else if(direction ==='backward'){
            start = referenceIndex - 1 - limit <= 0 ? 0 : referenceIndex - 1 - limit;
        }
        setStartIndex(start);
        setEndIndex(start + limit);
    }

    const handlePreviousPage = (event) => {
        const visibleUsers = getVisibleRows()

        if(visibleUsers.length === 0) {
            changePage(event,null, rowsPerPage, 'backward');
            return;
        }
        const first = visibleUsers[0];

        changePage(event,first.id, rowsPerPage, 'backward');

    };

    const handleNextPage = (event) => {
        const visibleUsers = getVisibleRows()

        if(visibleUsers.length === 0) {
            changePage(event,null, rowsPerPage, 'forward');
            return;
        }
        const last = visibleUsers[visibleUsers.length-1];

        changePage(event,last.id, rowsPerPage, 'forward');
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        changePage(event,null, newRowsPerPage, 'forward');
    };

    const handleClick = (event, callback, row) => {
        event.preventDefault()
        const data = {...row}
        callback(event, data);
    };

    const getActionButtons = (row) => {
        const actionButtons = [];
        let hasPerm = false;
        if (hasPermission(PERMISSION_EDIT_OTHER_USERS, authenticatedUser) &&
            hasPermissionToHandleRole(row.role, authenticatedUser)) {
            hasPerm = true;
        } else if (hasPermission(PERMISSION_EDIT_OWN_USER_INFO, authenticatedUser)
            && row.id === authenticatedUser.id) {
            hasPerm = true;
        }

        if (!hasPerm) return actionButtons;
        actions.forEach((button, index) => {
            const label = 'label' in button ? button.label : "";
            const className = 'className' in button ? button.className : "";
            const icon = 'icon' in button ? button.icon : "";
            const iconAlt = 'iconAlt' in button ? button.iconAlt : "";
            const onClick = 'onClick' in button ? button.onClick : () => {
            };

            actionButtons.push(
                <button key={`action-button-${index}`}
                    onClick={(event) => handleClick(event, onClick, row)} className={className}>
                    <div className={`${className}-image`}>
                        <img src={icon}
                             alt={iconAlt}/>
                    </div>
                    <div className={'editButton-text'}>{label}</div>
                </button>
            )

        });
        return actionButtons;
    };

    const getVisibleRows = () => {
        const visibleRows = users.sort((a, b) => comparator.compare(`${a.firstName} ${a.lastName}`,`${b.firstName} ${b.lastName}`))
        return (rowsPerPage > 0
            ? visibleRows.slice(startIndex, endIndex)
            : visibleRows
        )
    }

    const getMunicipality = (id) => {
        for (var i = 0; i < municipalities.length; i++) {
            // eslint-disable-next-line eqeqeq
            if (municipalities[i].value == id) {
                return municipalities[i].label;
            }
        }
        return "UNKNOWN";
    }

    const comparator = getComparator()

    const showMunicipality = hasPermission(PERMISSION_VIEW_OTHER_MUNICIPALITIES_USER_LISTS, authenticatedUser);

    return (
        <div className={'CompUserCards-container'}>
            <div className={'CompUserCards-row CompUserCards-header'}>
                <div className={'cell-image'}>
                </div>
                <div className={'cell-content'}>
                    <div className={'information'}>{t('user.name_and_user_type')}</div>
                    {showMunicipality && <div className={'municipality'}>{t('common.municipality')}</div>}
                    <div className={'organization'}>{t('common.organization')}</div>
                </div>
                <div className={'cell-right'}>
                    <div className={'dates'}>{t('user.creation_date')}</div>
                </div>
            </div>
            {getVisibleRows().map((row, index) => (
                <div className={'CompUserCards-row'} key={`CompUserCards-row-${index}`}>
                    <div className={'cell-image'} onClick={e => rowClick(e, row)}>
                        <img className="action-icon-img"
                             src={process.env.PUBLIC_URL + getUserIconUrl(row)}
                             alt={t('user.user_icon')}/>
                    </div>
                    <div className={'cell-content'} onClick={e => rowClick(e, row)}>
                        <div className={'information'}>
                            <div className={'title'}>{row.firstName} {row.lastName}</div>
                            <div className={'desc'}>{t("common." + row.role)}</div>
                        </div>
                        {showMunicipality && <div className={'municipality'}>
                            {getMunicipality(row.mainMunicipalityId)}
                        </div>}
                        <div className={'organization'}>
                            {
                                row.organizations.map((organization) => (
                                    <span>{organization.name}</span>
                                ))
                            }
                        </div>
                    </div>
                    <div className={'cell-right'}>
                        <div className={'dates'} onClick={e => rowClick(e, row)}>
                            <div>{formatDateStr(row.createdAt)}</div>
                        </div>
                        <div className={'actions'}>
                            {getActionButtons(row)}
                        </div>
                    </div>
                </div>
            ))}
            <div className={'CompUserCards-footer'}>
                <table>
                    <tbody>
                    <tr>
                        <td className="MuiTableCell-root MuiTablePagination-root">
                            <div
                                className="MuiToolbar-root MuiToolbar-regular MuiTablePagination-toolbar MuiToolbar-gutters">
                                <div className="MuiTablePagination-spacer"/>
                                <p className="MuiTypography-root MuiTablePagination-caption MuiTypography-body2 MuiTypography-colorInherit"
                                >{t("components.rows_per_page")}</p>
                                <div
                                    className="MuiInputBase-root MuiTablePagination-input MuiTablePagination-selectRoot">
                                    <select
                                        className="MuiSelect-root MuiSelect-select MuiTablePagination-select MuiInputBase-input"
                                        onChange={handleChangeRowsPerPage}
                                        value={rowsPerPage}
                                        aria-label={t("components.rows_per_page")}>
                                        <option className="MuiTablePagination-menuItem" value="5">5</option>
                                        <option className="MuiTablePagination-menuItem" value="10">10</option>
                                        <option className="MuiTablePagination-menuItem" value="25">25</option>
                                    </select>
                                    <svg className="MuiSvgIcon-root MuiSelect-icon MuiTablePagination-selectIcon"
                                         focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                        <path d="M7 10l5 5 5-5z" />
                                    </svg>
                                </div>
                                <div className="CompTable-paginator">

                                    <IconButton
                                        onClick={handlePreviousPage}
                                        aria-label={t('components.previous_page')}
                                    >
                                        <KeyboardArrowLeft/>
                                    </IconButton>
                                    <IconButton
                                        onClick={handleNextPage}
                                        aria-label={t('components.next_page')}
                                    >
                                        <KeyboardArrowRight/>
                                    </IconButton>
                                </div>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>


            </div>
        </div>
    );
}

CompUserCards.propTypes = {
    users: PropTypes.array,
    rowClick: PropTypes.func,
    changePage: PropTypes.func,
    actions: PropTypes.array,
    authenticatedUser: PropTypes.object
};

export {CompUserCards};