import types from "./user.types";
import {API_END, API_START} from "../api/types";
import {types as auth_types} from "../auth/auth.types";

export const initialState = {
    users: [],
    isLoadingUsersListData: false,
    selectedUser: {},
    userFieldErrors: {},
    notifications: [],
    selectedNotification: {},
    notificationFieldErrors: {},
    reminders: [],
}

export default function moduleReducer(state = initialState, action) {
    switch (action.type) {
        case auth_types.AUTH_LOGIN:
            return initialState;
        case auth_types.AUTH_LOGOUT:
            return initialState;
        case types.SET_USERS_LIST:
            return {
                ...state,
                users: action.payload
            }
        case types.SET_USER:
            return {
                ...state,
                userFieldErrors: {},
                selectedUser: action.payload
            }
        case types.UNSET_USER:
            return {
                ...state,
                userFieldErrors: {},
                selectedUser: {}
            }
        case types.SET_NOTIFICATIONS_LIST:
            return {
                ...state,
                notifications: action.payload
            }
        case types.SET_NOTIFICATION:
            return {
                ...state,
                notificationFieldErrors: {},
                selectedNotification: action.payload
            }
        case types.UNSET_NOTIFICATION:
            return {
                ...state,
                notificationFieldErrors: {},
                selectedNotification: {}
            }
        case types.SET_REMINDERS_LIST:
            return {
                ...state,
                reminders: action.payload
            }
        case types.SET_USER_ERRORS:
            return {
                ...state,
                userFieldErrors: action.payload
            }
        case API_START:
            if (action.payload === types.FETCH_USERS_LIST) {
                return {
                    ...state,
                    isLoadingUsersListData: true
                };
            }
            return state
        case API_END:
            if (action.payload === types.FETCH_USERS_LIST) {
                return {
                    ...state,
                    isLoadingUsersListData: false
                };
            }
            return state
        case types.USER_API_ERROR:
            if (action.payload == null) {
                return state;
            }
            if(typeof action.payload === "string") {
                console.log("API error: " + action.payload)
                return state
            } else if('status' in action.payload && action.payload.status === 405) {
                console.log("API error: " + action.payload.error)
                return state
            } else if('data' in action.payload) {
                console.log("API error: " + action.payload.message)
                return {
                    ...state,
                    userFieldErrors: action.payload.data
                }
            } else {
                console.log("API error: " + action.payload)
                return state
            }
        default:
            // Do not use the default case
            // A dispatch action will pass through each of the reducers associated with this one.
            // An action at the other end of your application could affect this state
            // without being expressed in the code. This should be avoided.
            return state
    }
}
