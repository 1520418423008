import React from "react";
import * as PropTypes from "prop-types";

const CompPartitionHeader = (props) => {
    const {text, ...divProps} = props;

    return (
        <div className={`partition-header`} {...divProps}>
            {text}
        </div>
    );
}
CompPartitionHeader.propTypes = {
    text: PropTypes.string,
};

export {CompPartitionHeader};