import {types} from "./root.types";
import {apiAction} from "../api/actions";
import settings from "../api/settings";

export const openMenu = payload => {
    return {type: types.MENU_TOGGLED, payload}
}
export const closeMenu = payload => {
    return {type: types.MENU_CLOSED, payload}
}
export const selectLeftDrawer = payload => {
    return {type: types.LEFT_DRAWER_SELECTED, payload}
}
export const selectRightDrawer = payload => {
    return {type: types.RIGHT_DRAWER_SELECTED, payload}
}

export const setBreadcrumbs = payload => {
    return {type: types.SET_BREADCRUMBS, payload}
}

export const closeAlert = () => {
    return {type: types.ALERT_CLOSED, payload:{}}
}

export const setAlert = (message, severity) => {
    if(severity === undefined) {
        severity = 'error'
    }
    return {type: types.SET_ALERT, payload:{
        message: message,
            severity: severity
        }}
}

export const getChoices = () => {
    return apiAction({
        url: `${settings.baseURL}/choices`,
        onSuccess: setChoices,
        onFailure: rootAPICallError,
        label: types.FETCH_CHOICES
    });
}

export const updateContentIdentifierChoices = (municipalityId) => {
    return apiAction({
        url: `${settings.baseURL}/choices/contentIdentifier/${municipalityId}`,
        onSuccess: setContentIdentifierChoices,
        onFailure: rootAPICallError,
        label: types.FETCH_CHOICES
    });
}

const rootAPICallError = error => {
    let data = "Connection error";
    if (error.response !== undefined) data = error.response.data;
    return {
        type: types.ROOT_API_ERROR,
        payload: data
    }
}

export const setContentIdentifierChoices = data => {
    return {
        type: types.UPDATE_CONTENT_IDENTIFIER,
        payload: data
    };
}

export const setChoices = data => {
    return {
        type: types.SET_CHOICES,
        payload: data
    };
}