import {Button} from "@material-ui/core";
import React from "react";
import * as PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {isFunction} from "../utils";

class CompPartSelectorComponent extends React.Component {
    onSelect;

    constructor(props) {
        super(props);
        const firstKey = props.selectors[0].key;
        const active = props.activeKey ? props.activeKey : firstKey;
        this.state = {active: active};

        this.onSelect = props.onSelect ? props.onSelect : null;
    }

    toggle(selection) {
        this.setState(state => ({
            ...state,
            active: selection
        }));
        const onSelect = this.onSelect;

        if (isFunction(onSelect)) {
            onSelect(selection)
        }
    }

    render() {

        const sm = Math.floor(12 / this.props.selectors.length);
        if (sm === 0) return (<div/>)

        let title = (<div className={'hidden'}/>)
        if (this.props.title) {
            title = (<div className={'label-container'}>
                <label>{this.props.title}</label>
            </div>);
        }

        return (
            <div className={'show-part-selector'}>
                {title}
                <div className={'button-container-box'}>
                    {this.props.selectors.map((element, index) => (
                        <div
                            key={`button-container-${index}`}
                            className={`button-container ${this.state.active === element.key ? 'active' : ''}`}
                        >
                            <Button className={'button'} onClick={() => this.toggle(element.key)}>
                                {element.label}
                            </Button>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

const CompPartSelector = withTranslation('translation')(CompPartSelectorComponent);

CompPartSelector.propTypes = {
    selectors: PropTypes.array,
    onSelect: PropTypes.func,
    activeKey: PropTypes.string,
    title: PropTypes.string
};

export {CompPartSelector};