const types = {
    CREATE_USER: 'user/createUser',
    FETCH_USERS_LIST: 'user/fetchUsersList',
    SET_USERS_LIST: 'user/setUsersList',
    FETCH_USER: 'user/fetchUser',
    SET_USER: 'user/setUser',
    UNSET_USER: 'user/unsetUser',
    UPDATE_USER: 'user/updateUser',
    REMOVE_USER: 'user/removeUser',
    USER_API_ERROR: 'user/userAPIError',
    SET_USER_ERRORS: 'user/setUserErrors',
    // notifications
    CREATE_NOTIFICATION: 'user/createNotification',
    FETCH_NOTIFICATIONS_LIST: 'user/fetchNotificationsList',
    SET_NOTIFICATIONS_LIST: 'user/setNotificationsList',
    SET_NOTIFICATION: 'user/setNotification',
    UNSET_NOTIFICATION: 'user/unsetNotification',
    REMOVE_NOTIFICATION: 'user/createNotification',
    SET_REMINDERS_LIST: "user/remindersList",
    FETCH_REMINDERS_LIST: 'user/fetchRemindersList',
    ACCEPT_REMINDER: 'user/acceptReminder'
}

export default types;