import {Grid,} from "@material-ui/core";
import React from "react";

import { withTranslation } from 'react-i18next';

const FooterComponent = ( props ) => {
    const {t} = props;

    return (
        <div className={'contentsDiv'}>
            <Grid container className={'footerToolbar'} spacing={0} alignItems={'baseline'}>
                <Grid item xs={12} sm={4} md={3}>
                    <a href={'https://www.kuntaliitto.fi/sites/default/files/media/file/K%C3%A4sittelytoimien%20kuvaus_nuodo%20_2023.pdf'} className={'link-data-privacy'}>
                        {t('footer.privacy_statement')}
                    </a>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <a href={'http://kuntaliitto.fi/saavutettavuusseloste'} className={'link-accessibility'}>
                        {t('footer.accessibility_statement')}
                    </a>

                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <div className={'copyright'}>© Kuntaliitto 2021</div>
                </Grid>
                <Grid item xs={12} sm={12} md={3} className={'footer-logo'}>
                    <img
                        src={process.env.PUBLIC_URL + '/media/images/kuntaliittoLogo.svg'}
                        alt={t('common.youth_work')} />
                </Grid>
            </Grid>
        </div>
    );
}
const Footer = withTranslation()(FooterComponent);
export {Footer};