import React from 'react';

import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Grid} from "@material-ui/core";
import {CompSearchInput} from "../../../components/SearchInput";
import {CompDatePicker} from "../../../components/DatePicker";
import {CompCheckbox} from "../../../components/Checkbox";
import {CompDropdown} from "../../../components/Dropdown";
import {ButtonSpacer} from "../../../components/ButtonSpacer";
import {ButtonMain} from "../../../components/ButtonMain";
import {getCSVReport, getCSVReportSuccess} from "../report.actions";
import {listMunicipalities} from "../../municipality/municipality.actions";

const mapStateToProps = (state) => {
    return {
        authenticatedUser: state.auth.authenticatedUser,
        municipalities: state.municipality.municipalities,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        listMunicipalities: () => dispatch(listMunicipalities()),
        getCSVReport: (data, onSuccess) => dispatch(getCSVReport(data, onSuccess)),
    };
}

class ReportPage extends React.Component {
    constructor(props) {
        super(props);
        var d = new Date();
        var dstring = [
            d.getFullYear(),
            ('0' + (d.getMonth() + 1)).slice(-2),
            ('0' + d.getDate()).slice(-2)
          ].join('-');

        this.state = {
            selectedMunicipalities: [],
            startDate: dstring,
            endDate: dstring,
            errors: {},
            eventHourType: "ALL",
            actionFeatures: ["TYPE"],
            participantFeatures: ["COUNT"],
            separationChoices: []
        }

        this.selectMunicipality = this.selectMunicipality.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleMultiselectChange = this.handleMultiselectChange.bind(this);
        this.downloadCSV = this.downloadCSV.bind(this);

        if (this.props.municipalities.length === 0) {
            this.props.listMunicipalities();
        }

    }

    valuetext(value) {
        return `${value} päivää`;
    }

    selectMunicipality = (row, isAddAction, index, i) => {

        const value = [...this.state.selectedMunicipalities];

        if (isAddAction) {
            value.push(row.id);
        } else {
            value.splice(i, 1);
        }

        this.setState({
            ...this.state,
            'selectedMunicipalities': value
        })
    }

    handleChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }

    handleMultiselectChange = (event, value) => {
        event.preventDefault()
        let selections = this.state[event.target.name];
        if (selections === undefined || selections === null) {
            selections = []
        }

        const i = selections.indexOf(value)
        const isAddAction = i < 0
        if (isAddAction) {
            selections.push(value);
        } else {
            selections.splice(i, 1);
        }
        this.setState({
            ...this.state,
            [event.target.name]: selections
        })
    }

    downloadCSV = (event, language) => {
        event.preventDefault()
        const errors = {};
        if (this.state.selectedMunicipalities.length <= 0 
            && !(this.state.allMunicipalities && this.state.allMunicipalities.length > 0 && this.state.allMunicipalities[0] === "ALL")) {
            errors['selectedMunicipalities'] = 'invalid_value';
        }
        if (this.state.startDate === null || this.state.startDate === '') {
            errors['startDate'] = 'invalid_value';
        }
        if (this.state.endDate === null || this.state.endDate === '') {
            errors['endDate'] = 'invalid_value';
        }
        this.setState({errors: errors})

        if (Object.keys(errors).length === 0) {
            const values = {
                municipalities: this.state.selectedMunicipalities,
                allMunicipalities: this.state.allMunicipalities && this.state.allMunicipalities.length > 0 && this.state.allMunicipalities[0] === "ALL" ? true : false,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                eventHourType: this.state.eventHourType,
                actionFeatures: this.state.actionFeatures,
                participantFeatures: this.state.participantFeatures,
                separationChoices: this.state.separationChoices,
                language: language
            }
            this.props.getCSVReport(values, (data, response) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;

                const contDispo = response.headers['content-disposition'];
                const contDispoParts = contDispo.split('"')[1];

                link.setAttribute('download', contDispoParts.trim()); //or any other extension
                document.body.appendChild(link);
                link.click();
                return getCSVReportSuccess(data);
            })

        }
    }


    render() {
        const {t} = this.props;

        const buttons = [(<ButtonMain
            key={'load_csv'}
            onClick={e => this.downloadCSV(e, t('report.current_language'))}>
            {t('report.load_csv')}
        </ButtonMain>)];

        const allMunicipalitiesChoices = [
            {
                label: t('report.select_all_municipalities'),
                value: "ALL"
            }
        ]

        const eventHourChoices = [
            {
                label: t('report.all'),
                value: "ALL"
            },
            {
                label: t('report.employees'),
                value: "EMPLOYEES"
            },
            {
                label: t('report.young_people'),
                value: "YOUNG_PEOPLE"
            },
            {
                label: t('report.exclude'),
                value: "EXCLUDE"
            },
        ]

        const separationChoices = [
            {
                label: t('report.separation'),
                value: "ALL"
            }
        ]

        const actionChoices = [
            {
                label: t('report.action_type'),
                value: "TYPE"
            },
            {
                label: t('report.organization_levels'),
                value: "ORGANIZATION_LEVELS"
            },
            {
                label: t('report.action_content_identifier'),
                value: "CONTENT_IDENTIFIER"
            },
            {
                label: t('report.action_organizer'),
                value: "ORGANIZER"
            },
            {
                label: t('report.action_location'),
                value: "LOCATION"
            },
            {
                label: t('report.action_description'),
                value: "DESCRIPTION"
            },
            {
                label: t('report.action_method'),
                value: "METHOD"
            }
        ]

        const participantChoices = [
            {
                label: t('report.participant_count'),
                value: "COUNT"
            },
            {
                label: t('report.participant_gender'),
                value: "GENDER"
            },
            {
                label: t('report.participant_age'),
                value: "AGE"
            },
            {
                label: t('report.participant_rating'),
                value: "RATING"
            },
            {
                label: t('report.participant_feedback'),
                value: "FEEDBACK"
            },
        ]

        return (
            <div className={'reports dataQueryPage'}>
                <form noValidate autoComplete="off"
                      onSubmit={this.handleSubmit}>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <CompSearchInput
                                disabled={this.state.allMunicipalities && this.state.allMunicipalities.length > 0 && this.state.allMunicipalities[0] === "ALL"}
                                label={t('common.municipalities')}
                                multiselect
                                rows={this.props.municipalities}
                                keyColumn={'id'}
                                valueColumn={['name']}
                                selectedRows={this.state.selectedMunicipalities}
                                error={this.state.errors.selectedMunicipalities}
                                onSelect={this.selectMunicipality}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className={'MuiGrid-item-label label'}>{t('report.start_and_end_date')}</div>
                            <Grid container spacing={0}>
                                <Grid item xs={12} sm={6}>
                                    <CompDatePicker
                                        value={this.state.startDate}
                                        name="startDate"
                                        onChange={this.handleChange}
                                        error={this.state.errors.startDate}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CompDatePicker
                                        value={this.state.endDate}
                                        name="endDate"
                                        onChange={this.handleChange}
                                        error={this.state.errors.endDate}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <CompCheckbox 
                            rows={allMunicipalitiesChoices}
                            selected={this.state.allMunicipalities}
                            onChange={this.handleMultiselectChange} 
                            name={"allMunicipalities"}/>
                        </Grid>

                        <Grid item xs={6}>
                            <CompDropdown
                                name="eventHourType"
                                label={`${t('report.work_hours')}`}
                                selectedValue={this.state.eventHourType}
                                rows={eventHourChoices}
                                onChange={this.handleChange}
                                />
                        </Grid>

                        <Grid item xs={6}>
                            <CompCheckbox 
                            rows={separationChoices}
                            selected={this.state.separationChoices}
                            onChange={this.handleMultiselectChange} 
                            name={"separationChoices"}/>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <div className={'label'}>{t('report.action')}</div>
                            <CompCheckbox
                                selected={this.state.actionFeatures}
                                rows={actionChoices}
                                name={'actionFeatures'}
                                onChange={this.handleMultiselectChange}/>

                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className={'label'}>{t('report.participants')}</div>
                            <CompCheckbox
                                selected={this.state.participantFeatures}
                                rows={participantChoices}
                                name={'participantFeatures'}
                                onChange={this.handleMultiselectChange}/>
                        </Grid>
                        <Grid item xs={12} className={'align-right'}>
                            <ButtonSpacer>
                                {buttons}
                            </ButtonSpacer>
                        </Grid>
                    </Grid>
                </form>
            </div>
        )
    }
}

const DataQueryReportPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation('translation')(ReportPage));

export {DataQueryReportPage};