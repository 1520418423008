const types = {
    CREATE_MUNICIPALITY: 'municipality/createMunicipality',
    FETCH_MUNICIPALITIES_LIST: 'municipality/fetchMunicipalitiesList',
    SET_MUNICIPALITIES_LIST: 'municipality/setMunicipalitiesList',
    FETCH_MUNICIPALITY: 'municipality/fetchMunicipality',
    SET_MUNICIPALITY: 'municipality/setMunicipality',
    SET_MUNICIPALITY_SILENT: 'municipality/setMunicipalitySilent',
    UNSET_MUNICIPALITY: 'municipality/unsetMunicipality',
    UPDATE_MUNICIPALITY: 'municipality/updateMunicipality',
    MUNICIPALITY_API_ERROR: 'municipality/municipalityAPIError',
    SET_MUNICIPALITY_DATA: 'municipality/setMunicipalityData',
    // organization
    CREATE_ORGANIZATION: 'municipality/createOrganization',
    FETCH_ORGANIZATIONS_LIST: 'municipality/fetchOrganizationsList',
    SET_ORGANIZATIONS_LIST: 'municipality/setOrganizationsList',
    FETCH_ORGANIZATION: 'municipality/fetchOrganization',
    SET_ORGANIZATION: 'municipality/setOrganization',
    UNSET_ORGANIZATION: 'municipality/unsetOrganization',
    UPDATE_ORGANIZATION: 'municipality/updateOrganization',
    ORGANIZATION_API_ERROR: 'municipality/organizationAPIError',
    SET_ORGANIZATION_TREE: 'municipality/setOrganizationTree',
    FETCH_ORGANIZATION_TREE: 'municipality/fetchOrganizationTree',
    ARCHIVE_ORGANIZATION: 'municipality/archiveOrganization',

}

export default types;