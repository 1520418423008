const types = {
    CREATE_FUNCTION: 'function/createFunction',
    FETCH_FUNCTIONS_LIST: 'function/fetchFunctionsList',
    SET_FUNCTIONS_LIST: 'function/setFunctionsList',
    SET_FUNCTION_TEMPLATES_LIST: 'function/setFunctionTemplatesList',
    FETCH_FUNCTION: 'function/fetchFunction',
    SET_FUNCTION: 'function/setFunction',
    UNSET_FUNCTION: 'function/unsetFunction',
    UPDATE_FUNCTION: 'function/updateFunction',
    FUNCTION_API_ERROR: 'function/functionAPIError',
    ARCHIVE_FUNCTION: 'function/archiveFunction',
    UPDATE_FUNCTION_TEMPLATE: "function/updateFunctionTemplate",
    DELETE_FUNCTION_TEMPLATE: "function/deleteFunctionTemplate",
    REMOVEME_FUNCTION_TEMPLATE: "function/removemeFunctionTemplate",
    SET_EMPTY_ACTION: "function/setEmptyAction",
    UPDATE_FUNCTION_LINKS: "function/updateFunctionLinks",
    FEEDBACK_REPORT: "function/feedbackReport",
    LIST_FEEDBACK: "function/listfeedback",
    SET_YOUTHINFO: "function/setYouthData",
    FETCH_YOUTHINFO: "function/getYouthCardInfo"
}

export default types;