import React from 'react';
import {Grid, Modal} from "@material-ui/core";

import {connect} from "react-redux";
import {ButtonMain} from "../../../components/ButtonMain";
import {ButtonSpacer} from "../../../components/ButtonSpacer";
import {CompUserCards} from "./UserCards";
import {UserForm} from "./userFormComponent";
import {createUser, getUser, listUsers, removeUser, saveUser, setUser, setUserErrors, unsetUser} from "../user.actions";
import {CompSearchInput} from "../../../components/SearchInput";
import {CompPartitionHeader} from "../../../components/PartitionHeader";
import {Trans, withTranslation} from "react-i18next";
import {CompDropdown} from "../../../components/Dropdown";
import {CompDatePicker} from "../../../components/DatePicker";
import {
    hasRole,
    hasPermission,
    organizationalRoles,
    ROLE_ADMIN_SYSTEM,
    PERMISSION_EDIT_OTHER_USERS,
    PERMISSION_EDIT_OWN_USER_INFO,
    PERMISSION_INVITE_NEW_ADMIN,
    PERMISSION_INVITE_NEW_USER,
    PERMISSION_REMOVE_USER,
    PERMISSION_RESTORE_OTHER_USERS_PASSWORD,
    PERMISSION_RESTORE_OTHER_MUNICIPALITY_USERS_PASSWORD
} from "../../../permissions";
import {getOrganizationTree, listMunicipalities} from "../../municipality/municipality.actions";
import {passwordRenewalMessageSent, sendRenewalMessage, setAuthUserInfo} from "../../auth/auth.actions";
import {setAlert} from "../../root/root.actions";
import {ButtonSecondary} from "../../../components/ButtonSecondary";
import moment from "moment";


const mapStateToProps = (state) => {
    return {
        users: state.user.users,
        selectedUser: state.user.selectedUser,
        selectedMunicipality: state.municipality.selectedMunicipality,
        selectedOrganization: state.municipality.selectedOrganization,
        userFieldErrors: state.user.userFieldErrors,
        authenticatedUser: state.auth.authenticatedUser,
        municipalities: state.municipality.municipalities,
        userRoles: state.root.userRoles,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        listMunicipalities: () => dispatch(listMunicipalities()),
        sendRenewalMessage: (userEmail, onSuccess) => dispatch(sendRenewalMessage(userEmail, onSuccess)),
        setAlert: (message, severity) => dispatch(setAlert(message, severity)),
        setUser: (data) => dispatch(setUser(data)),
        getUser: (id) => dispatch(getUser(id)),
        unsetUser: () => dispatch(unsetUser()),
        listUsers: (municipalityId) => dispatch(listUsers(municipalityId)),
        removeUser: (userId, onSuccess) => dispatch(removeUser(userId, onSuccess)),
        createUser: (data, onSuccess) => dispatch(createUser(data, onSuccess)),
        saveUser: (id, data, onSuccess) => dispatch(saveUser(id, data, onSuccess)),
        getOrganizationTree: (municipalityId, level) => dispatch(getOrganizationTree(municipalityId, level)),
        setUserErrors: data => dispatch(setUserErrors(data)),
        setAuthUserInfo: userData => dispatch(setAuthUserInfo(userData))
    };
}

class UserPage extends React.Component {
    constructor(props) {
        super(props);
        this.props.listMunicipalities();
        if (this.props.selectedMunicipality !== undefined && this.props.selectedMunicipality != null) {
            this.props.listUsers(this.props.selectedMunicipality.id);
        } else {
            this.props.listUsers(null);
        }

        const action = this.props.action;
        let userModalOpen = false;
        let modalState = 'create';
        if (action === 'openProfile') {
            this.props.getUser(this.props.authenticatedUser.id);
            this.props.getOrganizationTree(this.props.authenticatedUser.mainMunicipalityId);
            userModalOpen = true;
            modalState = 'edit';
        }


        this.state = {
            editorActivated: false,
            userModalOpen: userModalOpen,
            archiveModalOpen: false,
            modalState: modalState,
            filter: '',
            searchMunicipality: -1,
            searchRole: -1,
            searchSignUp: null
        }

        this.handleCreateNew = this.handleCreateNew.bind(this);
        this.sendPasswordRenewal = this.sendPasswordRenewal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleEditUser = this.handleEditUser.bind(this);
        this.handleOpenReadOnlyView = this.handleOpenReadOnlyView.bind(this);

        this.filteredRows = this.filteredRows.bind(this);
        this.search = this.search.bind(this);
        this.closeArchiveModal = this.closeArchiveModal.bind(this);
        this.remove = this.remove.bind(this);
        this.openArchiveModal = this.openArchiveModal.bind(this);
        this.handleMunicipalityChange = this.handleMunicipalityChange.bind(this);
        this.handleRoleChange = this.handleRoleChange.bind(this);
        this.handleSignedUpAfterChange = this.handleSignedUpAfterChange.bind(this);
    }

    remove(event) {
        event.preventDefault()

        if (this.props.selectedUser.id !== undefined && this.props.selectedUser.id !== null) {
            this.props.removeUser(this.props.selectedUser.id, () => {
                this.setState({
                    ...this.state,
                    archiveModalOpen: false,
                    editorActivated: false,
                    userModalOpen: false
                });
                if (this.props.selectedMunicipality !== undefined && this.props.selectedMunicipality != null) {
                    this.props.listUsers(this.props.selectedMunicipality.id);
                } else {
                    this.props.listUsers(null);
                }
                return unsetUser();
            });
        } else {
            // this shouldn't happen
            this.props.unsetUser();
        }


    }

    openArchiveModal(event) {
        event.preventDefault()
        this.setState({...this.state, archiveModalOpen: true})
    }

    closeArchiveModal(event) {
        event.preventDefault()
        this.setState({...this.state, archiveModalOpen: false})
    }

    handleCreateNew(event) {
        event.preventDefault()
        this.props.unsetUser()

        this.props.getOrganizationTree(this.props.authenticatedUser.mainMunicipalityId);

        this.setState({
            ...this.state,
            modalState: 'create',
            userModalOpen: true
        })
    }

    handleEditUser(event, data) {
        event.preventDefault()
        this.props.setUser(data);
        this.props.getOrganizationTree(data.mainMunicipalityId);
        this.setState({
            ...this.state,
            modalState: 'edit',
            userModalOpen: true
        })
    }

    handleOpenReadOnlyView(event, data) {
        event.preventDefault();
        this.props.setUser(data);
        this.props.getOrganizationTree(data.mainMunicipalityId);
        this.setState({
            ...this.state,
            modalState: 'readOnly',
            userModalOpen: true
        })
    }


    closeModal(event) {
        event.preventDefault()
        this.setState({...this.state, userModalOpen: false})
    }

    sendPasswordRenewal = () => {
        this.setState({...this.state, userModalOpen: false});
        this.props.sendRenewalMessage(this.props.selectedUser.email, () => {
            this.props.setAlert(this.props.t('user.renewal_sent'), 'success');
            return passwordRenewalMessageSent();
        });

    }

    resetUser = () => {
        this.setState({...this.state, userModalOpen: false});
        if (this.props.selectedUser.id !== undefined && this.props.selectedUser.id !== null) {
            this.props.getUser(this.props.selectedUser.id);
        } else {
            this.props.unsetUser();
        }
    }

    handleChange(e) {
        const data = {
            ...this.props.selectedUser,
            [e.target.name]: e.target.value
        }
        this.props.setUser(data);
    }


    _createUser(data) {
        this.props.createUser(data, data => {
            this.setState({
                ...this.state, userModalOpen: false,
                editorActivated: false
            })
            this.props.listUsers(this.props.selectedMunicipality.id);
            return setUser(data);
        })
    }

    _saveUser(id, data) {
        this.props.saveUser(id, data, data => {
            this.setState({
                ...this.state, userModalOpen: false,
                editorActivated: false
            })
            this.props.listUsers(this.props.selectedMunicipality.id);
            if (this.props.authenticatedUser.id === data.id) this.props.setAuthUserInfo(data);
            return setUser(data);
        })
    }
    handleSubmit(event, props) {
        const userData = {...props};

        if (organizationalRoles.indexOf(userData.role) >= 0 && (
            userData.organizations === undefined ||
            userData.organizations === null ||
            userData.organizations.length === 0)) {
            const data = {
                organizations: 'should not be empty'
            }
            this.props.setUserErrors(data);
        } else if (!(/.+@.+\.[A-Za-z]+$/.test(userData.email))) {
            const data = {
                email: 'invalid_value'
            }
            this.props.setUserErrors(data);
        } else {
            const {id} = props;
            if (id) {
                this._saveUser(id, userData);
            } else {
                this._createUser(userData);
            }
        }
    }

    search(e) {
        this.setState({filter: e.target.value})
    }

    filteredRows() {
        const lowerCasedFilter = this.state.filter.toLowerCase();

        return this.props.users.filter(item => {
            return Object.keys(item).some(key => {
                    if (typeof item[key] == 'string') {
                        return item[key].toLowerCase().includes(lowerCasedFilter)
                    } else {
                        return false;
                    }
                }
            // eslint-disable-next-line eqeqeq
            ) && (!this.state.searchMunicipality || this.state.searchMunicipality == -1 || this.state.searchMunicipality == item.mainMunicipalityId)
            // eslint-disable-next-line eqeqeq
              && (!this.state.searchRole || this.state.searchRole == -1 || this.state.searchRole === item.role)
              && (!this.state.searchSignUp || moment.utc(this.state.searchSignUp) <= moment.utc(item.createdAt))
            ;
        });
    }

    getMunicipalityOptions(unshift = true) {
        const response = [];
        this.props.municipalities.forEach((m) => response.push({value: m.id, label: m.name}));
        response.sort((a,b) => a.label.localeCompare(b.label));
        if (unshift) response.unshift({value: -1, label: this.props.t('report.all')});

        return response;
    };

    getRoles() {
        const roles = [];
        roles.push({value: -1, label: this.props.t('report.all')});
        this.props.userRoles.forEach((role) => {roles.push({value: role, label: this.props.t("common." + role)})})
        return roles;
    };

    handleMunicipalityChange(e) {
        this.setState({
            ...this.state,
            searchMunicipality: e.target.value
        })
    };

    handleRoleChange(e) {
        this.setState({
            ...this.state,
            searchRole: e.target.value
        })
    };

    handleSignedUpAfterChange(e) {
        this.setState({
            ...this.state,
            searchSignUp: e.target.value
        })
    }

    render() {
        const {t} = this.props;

        // create user
        let actionButtons = [
            {
                type: "secondary",
                label: t('common.leave_without_saving'),
                className: "",
                onClick: this.closeModal
            },
            {
                type: "main",
                label: t('common.save'),
                className: "",
                onClick: this.handleSubmit
            },
        ];

        if (this.state.modalState === "readOnly") {
            actionButtons = [
                {
                    type: "primary",
                    label: t('common.close'),
                    className: "",
                    onClick: this.closeModal
                },
            ];

        } else if (this.state.modalState === "edit") {
            if (hasPermission(PERMISSION_REMOVE_USER, this.props.authenticatedUser)
                && this.props.authenticatedUser.id !== this.props.selectedUser.id) {
                actionButtons = [];
                actionButtons = [{
                    type: "attention",
                    label: t('common.remove'),
                    className: "archive-button",
                    onClick: this.openArchiveModal
                }];
            } else {
                actionButtons = [];
            }

            if (hasPermission(PERMISSION_RESTORE_OTHER_USERS_PASSWORD, this.props.authenticatedUser) ||
                this.props.authenticatedUser.id === this.props.selectedUser.id) {
                actionButtons.push({
                    type: "secondary",
                    label: t('user.renew_password'),
                    className: "",
                    onClick: this.sendPasswordRenewal
                });
            }
            else if (hasPermission(PERMISSION_RESTORE_OTHER_MUNICIPALITY_USERS_PASSWORD, this.props.authenticatedUser) && 
                this.props.selectedUser.municipalityId === this.props.authenticatedUser.municipalityId &&
                !hasPermission(PERMISSION_RESTORE_OTHER_MUNICIPALITY_USERS_PASSWORD, this.props.selectedUser)
            ) {
                actionButtons.push({
                    type: "secondary",
                    label: t('user.renew_password'),
                    className: "",
                    onClick: this.sendPasswordRenewal
                }); 
            }

            actionButtons.push({
                type: "secondary",
                label: t('common.cancel_editing'),
                className: "",
                onClick: this.resetUser
            });
            actionButtons.push({
                type: "main",
                label: t('common.save_changes'),
                className: "",
                onClick: this.handleSubmit
            });
        }

        let cardActionButtons = [];

        if (hasPermission(PERMISSION_EDIT_OTHER_USERS, this.props.authenticatedUser) ||
            hasPermission(PERMISSION_EDIT_OWN_USER_INFO, this.props.authenticatedUser)) {
            cardActionButtons.push(
                {
                    label: t('common.edit'),
                    icon: process.env.PUBLIC_URL + '/media/images/icons/IconPencil.svg',
                    iconAlt: t('common.edit'),
                    className: "editButton",
                    onClick: this.handleEditUser
                }
            );
        }
        let createButtons = [];
        if (hasPermission(PERMISSION_INVITE_NEW_USER, this.props.authenticatedUser) ||
            hasPermission(PERMISSION_INVITE_NEW_ADMIN, this.props.authenticatedUser)) {
            createButtons.push(
                (
                    <ButtonMain
                        key={'add_new_user'}
                        onClick={this.handleCreateNew}>
                        {t('user.add_new_user')}
                    </ButtonMain>
                )
            );
        }

        const getModalTitle = (modalState) => {
            if (modalState === "create") return t('user.create_new_user')
            if (modalState === "edit") return t('user.edit_user');
            return t('common.user');
        }

        return (
            <div>
                <Grid container>
                    <Grid item xs={12}>
                        <CompPartitionHeader text={t('common.users')}/>
                    </Grid>
                    {hasRole(ROLE_ADMIN_SYSTEM, this.props.authenticatedUser) && 
                    <>
                        <Grid item xs={12} sm={4}>
                        <CompDropdown
                            label={t('common.municipality')}
                            selectedValue={this.state.searchMunicipality}
                            rows={this.getMunicipalityOptions()}
                            hasEmptyOption={false}
                            name="municipalityId"
                            onChange={this.handleMunicipalityChange}
                        />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <CompDropdown
                                label={t('user.user_role')}
                                selectedValue={this.state.searchRole}
                                rows={this.getRoles()}
                                hasEmptyOption={false}
                                name="roleId"
                                onChange={this.handleRoleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <CompDatePicker
                                label={`${t('function.start_date')}`}
                                value={this.state.searchSignUp}
                                disabled={false}
                                canBeEmpty={true}
                                name="startDate"
                                onChange={this.handleSignedUpAfterChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CompSearchInput label={t('user.search')}
                                            onChange={this.search}/>
                        </Grid>
                    </>}
                    <Grid item xs={12}>
                        <CompUserCards
                            users={this.filteredRows()}
                            rowClick={this.handleOpenReadOnlyView}
                            actions={cardActionButtons}
                            authenticatedUser={this.props.authenticatedUser}
                            municipalities={this.getMunicipalityOptions(false)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={12} className={'align-right'}>
                                <ButtonSpacer>
                                    {createButtons}
                                </ButtonSpacer>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Modal
                    open={this.state.userModalOpen}
                    onClose={this.closeModal}
                >
                    <div className={'modal'}>
                        <UserForm
                            label={getModalTitle(this.state.modalState)}
                            editorActivated={this.state.modalState !== 'readOnly'}
                            onChange={this.handleChange}
                            selectedUser={this.props.selectedUser}
                            fieldErrors={this.props.userFieldErrors}
                            actionButtons={actionButtons}
                        />
                    </div>
                </Modal>
                <Modal
                    open={this.state.archiveModalOpen}
                    onClose={this.closeArchiveModal}
                >
                    <div className={'modal archiveModal'}>
                        <div className={'modalDescription'}>
                            <Trans i18nKey="user.archive_desc"/>
                        </div>
                        <div className={'modalQuestion'}>
                            {t('user.archive_question')}
                        </div>
                        <div className={'modalButtons'}>
                            <ButtonSecondary onClick={this.closeArchiveModal}>
                                {t('common.cancel')}
                            </ButtonSecondary>
                            <ButtonMain onClick={this.remove}>
                                {t('common.remove')}
                            </ButtonMain>
                        </div>

                    </div>
                </Modal>
            </div>
        )
    }
}

const UserPageForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation('translation')(UserPage));

export {UserPageForm};