import React from "react";


const ButtonSpacer = (props) => {
    const {children, className, ...componentProps} = props;

    return (
        <div
            className={`buttonSpacer ${className ? className : ''}`}
            {...componentProps}>
            {children}
        </div>
    );
}
export {ButtonSpacer};