import React from 'react';
import {Grid, Modal} from "@material-ui/core";

import {connect} from "react-redux";
import {
    archiveContentIdentifier,
    createContentIdentifier,
    listContentIdentifiers,
    saveContentIdentifier,
    setContentIdentifier,
    unsetContentIdentifier
} from "../contentIdentifier.actions";
import {ContentIdentifierFormComponent} from "./contentIdentifierFormComponent";
import {ButtonMain} from "../../../components/ButtonMain";
import {ButtonSpacer} from "../../../components/ButtonSpacer";
import {CompContentIdentifierTable} from "./contentIdentifierTableComponent";

import {CompSearchInput} from "../../../components/SearchInput";
import {ButtonSecondary} from "../../../components/ButtonSecondary";
import {Trans, withTranslation} from "react-i18next";
import {
    ARCHIVE_MUNICIPALITY_CONTENT_IDENTIFIER,
    ARCHIVE_NATIONWIDE_CONTENT_IDENTIFIER,
    CREATE_NATIONWIDE_CONTENT_IDENTIFIER,
    CREATE_OWN_MUNICIPALITY_CONTENT_IDENTIFIER,
    EDIT_NATIONWIDE_CONTENT_IDENTIFIER,
    EDIT_OWN_MUNICIPALITY_CONTENT_IDENTIFIER,
    hasPermission
} from "../../../permissions";
import {getChoices} from "../../root/root.actions";

const mapStateToProps = (state) => {
    return {
        contentIdentifiers: state.contentIdentifier.contentIdentifiers,
        selectedContentIdentifier: state.contentIdentifier.selectedContentIdentifier,
        contentIdentifierFieldErrors: state.contentIdentifier.contentIdentifierFieldErrors,
        authenticatedUser: state.auth.authenticatedUser,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        listContentIdentifiers: (municipalityId) => dispatch(listContentIdentifiers(municipalityId)),
        unsetContentIdentifier: () => dispatch(unsetContentIdentifier()),
        setContentIdentifier: selectedContentIdentifier => dispatch(setContentIdentifier(selectedContentIdentifier)),
        saveContentIdentifier: (id, data, onSuccess) => dispatch(saveContentIdentifier(id, data, onSuccess)),
        createContentIdentifier: (contentIdentifier, onSuccess) => dispatch(createContentIdentifier(contentIdentifier, onSuccess)),
        archiveContentIdentifier: (id, onSuccess) => dispatch(archiveContentIdentifier(id, onSuccess)),
        getChoices: () => dispatch(getChoices()),
    };
}

class ContentIdentifierPage extends React.Component {
    constructor(props) {
        super(props);
        this.props.listContentIdentifiers();
        this.state = {
            editorActivated: false,
            createNewContentIdentifierModalOpen: false,
            archiveModalOpen: false,
            filter: ''
        }
        this.callListContentIdentifiers = this.callListContentIdentifiers.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCreateNew = this.handleCreateNew.bind(this);
        this.closeCreateNewModal = this.closeCreateNewModal.bind(this);
        this.handleActivateEditor = this.handleActivateEditor.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.resetContentIdentifier = this.resetContentIdentifier.bind(this);
        this.filteredRows = this.filteredRows.bind(this);
        this.search = this.search.bind(this);
        this.closeArchiveModal = this.closeArchiveModal.bind(this);
        this.archive = this.archive.bind(this);
        this.openArchiveModal = this.openArchiveModal.bind(this);
    }

    async componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.page !== prevProps.page) {
            this.callListContentIdentifiers();
        }
    }

    callListContentIdentifiers() {
        if (this.props.page === 'ownMunicipality') {
            this.props.listContentIdentifiers(this.props.authenticatedUser.mainMunicipalityId);
        } else {
            this.props.listContentIdentifiers();
        }
    }

    handleChange(e) {
        const data = {
            ...this.props.selectedContentIdentifier,
            [e.target.name]: e.target.value
        }
        this.props.setContentIdentifier(data);
    }

    handleCreateNew(event) {
        event.preventDefault()
        this.props.unsetContentIdentifier()
        this.setState({...this.state, createNewContentIdentifierModalOpen: true})
    }

    closeCreateNewModal(event) {
        event.preventDefault()
        this.props.unsetContentIdentifier()
        this.setState({...this.state, createNewContentIdentifierModalOpen: false})
    }

    archive(event) {
        event.preventDefault()
        this.props.archiveContentIdentifier(this.props.selectedContentIdentifier.id, () => {
            this.setState({
                ...this.state,
                archiveModalOpen: false,
                createNewContentIdentifierModalOpen: false,
                editorActivated: false
            });
            this.callListContentIdentifiers();
            this.props.getChoices();
            return unsetContentIdentifier();
        })
        this.setState({...this.state, archiveModalOpen: false})
    }

    openArchiveModal(event) {
        event.preventDefault()
        this.setState({...this.state, archiveModalOpen: true})
    }

    closeArchiveModal(event) {
        event.preventDefault()
        this.setState({...this.state, archiveModalOpen: false})
    }

    handleActivateEditor() {
        this.setState({...this.state, editorActivated: true})
    }

    handleSubmit(event, props) {
        const data = {...props};
        const {id} = props;

        if (id) {
            this.props.saveContentIdentifier(id, data, data => {
                this.callListContentIdentifiers();
                this.setState({editorActivated: false})
                this.props.getChoices();
                return setContentIdentifier(data);
            })
        } else {
            this.props.createContentIdentifier(data, data => {
                this.setState({...this.state, createNewContentIdentifierModalOpen: false,
                    editorActivated: false})
                this.props.getChoices();
                this.callListContentIdentifiers();
                return setContentIdentifier(data);
            })
        }
    }

    resetContentIdentifier = (event) => {
        this.selectContentIdentifier(event, {id: this.props.selectedContentIdentifier.id}, true)
    }

    selectContentIdentifier = (event, row, isAddAction /*, index */) => {
        event.preventDefault();
        this.setState({editorActivated: false})
        if (isAddAction) {
            this.props.setContentIdentifier(row);
        } else {
            this.props.unsetContentIdentifier();
        }
    }

    search(e) {
        this.setState({filter: e.target.value})
    }

    filteredRows() {
        const lowerCasedFilter = this.state.filter.toLowerCase();
        if(this.props.contentIdentifiers[this.props.page] === undefined) {
            return [];
        }
        return this.props.contentIdentifiers[this.props.page].filter(item => {
            return Object.keys(item).some(key => {
                    if (typeof item[key] == 'string') {
                        return item[key].toLowerCase().includes(lowerCasedFilter)
                    } else {
                        return false;
                    }
                }
            );
        });
    }

    render() {

        let municipalityId = null;
        if (this.props.page === 'ownMunicipality') {
            municipalityId = this.props.authenticatedUser.mainMunicipalityId;
        }

        let actionButtons = [];
        let selectedName = "";
        if (this.props.selectedContentIdentifier && this.props.selectedContentIdentifier.id) {
            selectedName = this.props.selectedContentIdentifier.name;
        }
        const {t} = this.props;

        let hasAddPerms = false;
        let hasEditPerms = false;
        let hasArchivePerms = false;
        let addEditButtons = [];
        if (this.props.page === 'nationwide') {
            if (hasPermission(CREATE_NATIONWIDE_CONTENT_IDENTIFIER, this.props.authenticatedUser)) {
                hasAddPerms = true;
            }
            if (hasPermission(EDIT_NATIONWIDE_CONTENT_IDENTIFIER, this.props.authenticatedUser)) {
                hasEditPerms = true;
            }
            if (hasPermission(ARCHIVE_NATIONWIDE_CONTENT_IDENTIFIER, this.props.authenticatedUser)) {
                hasArchivePerms = true;
            }
        } else {
            if (hasPermission(CREATE_OWN_MUNICIPALITY_CONTENT_IDENTIFIER, this.props.authenticatedUser)) {
                hasAddPerms = true;
            }
            if (hasPermission(EDIT_OWN_MUNICIPALITY_CONTENT_IDENTIFIER, this.props.authenticatedUser)) {
                hasEditPerms = true;
            }
            if (hasPermission(ARCHIVE_MUNICIPALITY_CONTENT_IDENTIFIER, this.props.authenticatedUser)) {
                hasArchivePerms = true;
            }
        }

        if (this.state.editorActivated) {
            if (hasArchivePerms) {
                actionButtons.push({
                    type: "attention",
                    label: t('common.archive'),
                    className: "archive-button",
                    onClick: this.openArchiveModal
                });
            }
            actionButtons.push({
                type: "secondary",
                label: t('common.cancel_editing'),
                className: "",
                onClick: this.resetContentIdentifier
            });
            actionButtons.push({
                type: "main",
                label: t('common.save_changes'),
                className: "",
                onClick: this.handleSubmit
            });
        }

        if (hasAddPerms) {
            addEditButtons.push(
                <ButtonMain disabled={this.state.editorActivated}
                            key={'add_new'}
                            onClick={this.handleCreateNew}>
                    {t("common.add_new")}
                </ButtonMain>
            );
        }
        if (hasEditPerms && this.props.selectedContentIdentifier.id !== undefined) {
            addEditButtons.push(
                <ButtonMain disabled={this.state.editorActivated}
                            key={'edit'}
                            onClick={this.handleActivateEditor}>
                    {t("common.edit")}
                </ButtonMain>
            );
        }

        return (
            <div>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <CompContentIdentifierTable
                                    rows={this.filteredRows()}
                                    rowClick={this.selectContentIdentifier}
                                    label={t('common.content_identifiers')}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <CompSearchInput label={t('content_identifier.search_identifier')}
                                                 onChange={this.search}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid container spacing={1}
                              direction="row"
                              justify="flex-end"
                              alignItems="center">
                            <Grid item xs={12} className={'align-right'}>
                                <ButtonSpacer>
                                    {addEditButtons}
                                </ButtonSpacer>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <ContentIdentifierFormComponent
                    label={t('content_identifier.identifier_information')}
                    editorActivated={this.state.editorActivated}
                    onChange={this.handleChange}
                    selectedContentIdentifier={this.props.selectedContentIdentifier}
                    fieldErrors={this.props.contentIdentifierFieldErrors}
                    municipalityId={municipalityId}
                    contentIdentifiers={this.props.contentIdentifiers['nationwide'] !== undefined ? this.props.contentIdentifiers['nationwide']: []}
                    actionButtons={actionButtons}
                />
                <Modal
                    open={this.state.createNewContentIdentifierModalOpen}
                    onClose={this.closeCreateNewModal}
                >

                    <div className={'modal'}>

                        <ContentIdentifierFormComponent
                            label={t('content_identifier.create_new_identifier')}
                            editorActivated={this.state.createNewContentIdentifierModalOpen}
                            onChange={this.handleChange}
                            selectedContentIdentifier={this.props.selectedContentIdentifier}
                            fieldErrors={this.props.contentIdentifierFieldErrors}
                            municipalityId={municipalityId}
                            contentIdentifiers={this.props.contentIdentifiers['nationwide']}
                            actionButtons={[
                                {
                                    type: "secondary",
                                    label: t('common.leave_without_saving'),
                                    className: "",
                                    onClick: this.closeCreateNewModal
                                },
                                {
                                    type: "main",
                                    label: t('common.save'),
                                    className: "",
                                    onClick: this.handleSubmit
                                },
                            ]}
                        />
                    </div>
                </Modal>
                <Modal
                    open={this.state.archiveModalOpen}
                    onClose={this.closeCreateNewModal}
                >
                    <div className={'modal archiveModal'}>
                        <div className={'modalDescription'}>
                            <Trans i18nKey="content_identifier.archive_desc" selectedName={selectedName}/>
                        </div>
                        <div className={'modalQuestion'}>
                            {t('content_identifier.archive_question')}
                        </div>
                        <div className={'modalButtons'}>
                            <ButtonSpacer>
                                <ButtonSecondary onClick={this.closeArchiveModal}>
                                    {t('common.cancel')}
                                </ButtonSecondary>
                                <ButtonMain onClick={this.archive}>
                                    {t('common.archive')}
                                </ButtonMain>
                            </ButtonSpacer>
                        </div>

                    </div>
                </Modal>
            </div>
        )
    }
}

const ContentIdentifierPageForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation('translation')(ContentIdentifierPage));

export {ContentIdentifierPageForm};