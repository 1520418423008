import {Button, InputBase, List, ListItem, ListItemText,} from "@material-ui/core";
import React from "react";
import * as PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {getComparator, isFunction, valueFoundInArray} from "../utils";


const CompSearchList = (props) => {
    const {
        rows,
        selectedRows,
        keyColumn,
        valueFunc = (row) => {
            return row.toString()
        },
        classColumn = "",
        children,
        className,
        multiselect,
        onSelect,
        disabled,
        canUnselect,
        error,
        ...componentProps
    } = props;

    const {t} = useTranslation('translation');

    const [filter, setFilter] = React.useState('');

    const handleSelectionToggle = (row, index) => () => {
        if (disabled) return;

        const newSelectedRows = [...selectedRows]
        const i = newSelectedRows.indexOf(row[keyColumn])
        const isAddAction = i < 0
        if (multiselect) {
            /* MULTI SELECTION */
            if (isAddAction) { /* add */
                newSelectedRows.push(row[keyColumn])
            } else { /* remove */
                if (!canUnselect && newSelectedRows.length <= 1) {
                    // prevent unselection
                    return;
                }
                newSelectedRows.splice(i, 1)
            }
            /* SINGLE SELECTION */
        } else if (!isAddAction && !canUnselect) { /* remove */
            // prevent unselection
            return;
        }

        if (isFunction(onSelect)) {
            onSelect(row, isAddAction, index, i)
        }
    }

    const handleSearchChange = (e) => {
        e.persist();
        if (disabled) return;
        setFilter(e.target.value);
    }

    const debounce = (func, delay) => {
        let debounceTimer;
        return function () {
            const context = this;
            const args = arguments;
            clearTimeout(debounceTimer);
            debounceTimer =
                setTimeout(() => func.apply(context, args), delay);
        }
    }

    let optimisedHandleChange = debounce(handleSearchChange, 300);

    const filteredRows = () => {
        const comparator = getComparator()
        const lowerCasedFilter = filter.toLowerCase();
        return rows.filter(item => {
            return Object.keys(item).some(key => {
                    if (disabled) {
                        if (key === keyColumn) {
                            return valueFoundInArray(item[key], selectedRows);
                        } else {
                            return false;
                        }
                    }
                    if (typeof item[key] == 'string') {
                        return item[key].toLowerCase().includes(lowerCasedFilter)
                    } else {
                        return false;
                    }
                }
            );
        }).sort((a, b) => comparator.compare(valueFunc(a),valueFunc(b)));
    }

    return (
        <div className={`search-list-wrapper ${error ? 'error' : ''}`}>
            <div className={`search-list ${className} ${disabled ? 'disabled' : ''}`}
                 {...componentProps}>
                <div className={'search-list-title'}>
                    {children}
                </div>
                <InputBase className={`search-list-input`}
                           onChange={optimisedHandleChange}
                />
                <Button className={`search-list-button`}>
                    <img className={`search-list-button-icon-image`}
                         src={process.env.PUBLIC_URL + '/media/images/icons/search.svg'}
                         alt={t('components.search')}/>
                </Button>
                <List className={'search-list-container'}>
                    {filteredRows().map((row, index) => (
                        <ListItem key={row[keyColumn]}
                                  role={undefined}
                                  dense
                                  disabled={disabled}
                                  button
                                  className={`search-list-item ${valueFoundInArray(row[keyColumn], selectedRows) ? 'selected' : ''} ${classColumn !== '' ? row[classColumn] : ''}`}
                                  onClick={handleSelectionToggle(row, index)}>
                            <ListItemText
                                id={`search-list-item-label-${row[keyColumn]}`}
                                primary={valueFunc(row)}
                            />
                        </ListItem>
                    ))}
                </List>
            </div>
            <div className={`error-label ${props.className ? 'error-' + props.className : ''}`}>{t("errors."+error)}</div>
        </div>
    );
}

CompSearchList.propTypes = {
    keyColumn: PropTypes.string,
    valueFunc: PropTypes.func,
    classColumn: PropTypes.string,
    rows: PropTypes.array,
    selectedRows: PropTypes.array,
    multiselect: PropTypes.bool,
    onSelect: PropTypes.func,
    disabled: PropTypes.bool,
    canUnselect: PropTypes.bool,
    error: PropTypes.string
};

export {CompSearchList};