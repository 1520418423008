import React from "react";
import {connect} from 'react-redux'
import {CompSearchList} from "../../../components/SearchList";
import {useTranslation} from "react-i18next";

const mapStateToProps = (state, ownProps) => {
    // return state;
    return {
        locations: state.location.locations,
        isLoadingLocationsListData: state.location.isLoadingLocationsListData,
        selectedLocation: state.location.selectedLocation,
        props: ownProps
    }
}
const ListLocationsComponent = ({locations, isLoadingLocationsListData, selectedLocation, props}) => {
    const {t} = useTranslation('translation');
    const selectedRows = [];
    if (selectedLocation && selectedLocation.id) {
        selectedRows.push(selectedLocation.id);
    }
    const onSelect = props.onSelect ? props.onSelect : null;
    return (
        <CompSearchList
            className={`listLocationsComponent ${isLoadingLocationsListData ? 'loading' : ''}`}
            rows={locations}
            keyColumn={'id'}
            valueFunc={(row) => {return row.name}}
            selectedRows={selectedRows}
            onSelect={onSelect}>
            {t('location.select_location')}
        </CompSearchList>
    )
};

const ListLocations = connect(mapStateToProps)(ListLocationsComponent);

export {ListLocations};
