import {Button, InputBase, List, ListItem, ListItemText,} from "@material-ui/core";
import React from "react";
import * as PropTypes from "prop-types";
import {isFunction, valueFoundInArray} from "../utils";
import {useTranslation} from "react-i18next";


const CompSearchInput = (props) => {
    const {t} = useTranslation('translation');

    let {error} = props
    const {
        rows = [],
        selectedRows,
        keyColumn,
        valueColumn,
        className,
        multiselect,
        onSelect,
        onChange,
        disabled,
        permanentSelections = [],
        label = "",
        ...componentProps
    } = props;

    if(error !== undefined && error !== null) {
        error = t("errors."+error);
    } else {
        error=null;
    }

    const [filter, setFilter] = React.useState('');
    const [searchText, setSearchText] = React.useState('');

    const closeSearchList = () => {
        setFilter('');
        setSearchText('');
    }

    const handleSelectionToggle = (event,row, index) => {
        event.stopPropagation();
        event.preventDefault();

        if (disabled) return;

        const i = selectedRows.indexOf(row[keyColumn])
        const isAddAction = i < 0

        setFilter('');
        setSearchText('');

        if (isFunction(onSelect)) {
            onSelect(row, isAddAction, index, i)
        }
    }

    const getValueColumn = (row) => {
        if (Array.isArray(valueColumn)) {
            const column = [];
            valueColumn.forEach(function (columnKey) {
                if (Array.isArray(columnKey)) {
                    //there's two items, at 0 is the map, at 1 is the value we want mapped
                    const obj = columnKey[0].find(o => o.id===row[columnKey[1]]);
                    column.push(obj ? obj.name : "");
                } 
                else {
                    column.push(row[columnKey]);
                }
            })
            return column.join(" ");
        } else if (typeof valueColumn === 'string') {
            return row[valueColumn];
        }
        return ""
    }

    const handleSearchChange = (e) => {
        e.persist();
        if (disabled) return;
        setFilter(e.target.value);
        if (isFunction(onChange)) {
            onChange(e);
        }
    }

    const debounce = (func, delay) => {
        let debounceTimer;
        return function () {
            const context = this;
            const args = arguments;
            setSearchText(args[0].target.value);
            clearTimeout(debounceTimer);
            debounceTimer =
                setTimeout(() => func.apply(context, args), delay);
        }
    }

    const getSelectedRows = () => {
        return rows.filter(item => {
            return isSelected(item[keyColumn])
        });
    }

    const isSelected = value => {
        return selectedRows.indexOf(value) >= 0
    }

    const filteredRows = () => {
        const lowerCasedFilter = filter.toLowerCase();
        return rows.filter(item => {
            return Object.keys(item).some(key => {
                    if (isSelected(item[keyColumn])) {
                        return false;
                    }
                    if (typeof item[key] == 'string') {
                        return item[key].toLowerCase().includes(lowerCasedFilter)
                    } else {
                        return false;
                    }
                }
            );
        });
    }


    let optimisedHandleChange = debounce(handleSearchChange, 300);

    let searchOverlay = (<div/>);
    let searchList = (<div/>);
    if (filter && filter !== "" && filteredRows().length > 0) {
        searchOverlay = (<div className={'searchOverlay'}
        onClick={closeSearchList}/>);
        searchList = (<List className={'search-list-container'}>
            {filteredRows().map((row, index) => (
                <ListItem
                          key={`search-input-list-item-${index}`}
                          role={undefined}
                          dense
                          disabled={disabled}
                          button
                          className={`search-list-item ${valueFoundInArray(row[keyColumn], selectedRows) ? 'selected' : ''}`}
                          onClick={e => handleSelectionToggle(e, row, index) }>
                    <ListItemText
                        id={`search-list-item-label-${row[keyColumn]}`}
                        primary={getValueColumn(row)}
                    />
                </ListItem>
            ))}
        </List>);
    }

    let multiselectList = (<div/>);
    if (multiselect && getSelectedRows().length > 0) {
        multiselectList = (<ul className={`selected-list`}>
            {getSelectedRows().map((row, index) => (
                <li className={'item'} key={`selected-list-item-${index}`}>
                    <div>{getValueColumn(row)}
                        <div className={`closeButton ${valueFoundInArray(row[keyColumn],permanentSelections)?'hidden':''}`}
                             onClick={e => handleSelectionToggle(e, row, index)}/>
                    </div>
                </li>
            ))}
            <div className={'clear'}/>
        </ul>);

    }

    return (
        <div className={`search-input 
        ${className}
        ${error ? 'error' : ''}
        ${disabled ? 'disabled' : ''}`} {...componentProps}>
            <div className={'label'}>{label}</div>
            <InputBase className={'search-input-input'}
                       disabled={disabled}
                       value={searchText}
                       onChange={optimisedHandleChange}
            />
            <Button className={'search-input-button'}
                    disabled={disabled}>
                <img className={`search-input-button-icon-image`}
                     src={process.env.PUBLIC_URL + '/media/images/icons/search.svg'}
                     alt={t('components.search')}/>
            </Button>
            {searchOverlay}
            {searchList}
            {multiselectList}
            <div className={`error-label`}>{error}</div>
        </div>
    );
};

CompSearchInput.propTypes = {
    keyColumn: PropTypes.string,
    valueColumn: PropTypes.array,
    rows: PropTypes.array,
    selectedRows: PropTypes.array,
    multiselect: PropTypes.bool,
    onSelect: PropTypes.func,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    label: PropTypes.string
};

export {CompSearchInput};