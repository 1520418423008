export function getOrganizations(organizationTree) {
    const response = [];
    if (organizationTree['10']) {
        organizationTree['10'].sort((a, b) => a.name.localeCompare(b.name));
        for (const child of organizationTree['10']) {
            getSortedOrganizationsRecursive(response, child, 0);
        }
    }
    return response;
}

function getSortedOrganizationsRecursive(response, currentBranch, depth) {
    const newObj = {
        id: currentBranch.id,
        name: '\xA0'.repeat(depth*2) + currentBranch.name
    }
    response.push(newObj);
    if (currentBranch.children) {
        currentBranch.children.sort((a, b) => a.name.localeCompare(b.name));
        for (var child of currentBranch.children) {
            getSortedOrganizationsRecursive(response, child, depth+1);
        }
    }
}