// based on https://github.com/ohansemmanuel/fake-medium/blob/master/src/middleware/api.js
// inspired by https://leanpub.com/redux-book

import axios from "axios";
import {API} from "./types";
import {accessDenied, apiError, apiStart, apiEnd, incorrectCredentials} from "./actions";
import settings from "./settings";
import {types} from "../auth/auth.types";

const apiMiddleware = store => next => action => {
    next(action);

    if (action.type !== API) return;

    const {
        url,
        method,
        data,
        onSuccess,
        onFailure,
        label,
        headers,
        responseType= 'json'
    } = action.payload;
    const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";

    let accessToken = "";
    if(label !== types.AUTH_LOGIN && store.getState().auth) accessToken = store.getState().auth.token;

    if(accessToken == null) {
        accessToken = "";
    }

    // axios default configs
    axios.defaults.baseURL = settings.baseURL || "";
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["Authorization"] = accessToken;
    axios.defaults.headers.common["Cache-Control"] = `no-cache`;
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    if (settings.APIKey != null && settings.APIKey !== '') {
        axios.defaults.headers.common["X-Api-Key"] = settings.APIKey;
    }

    if (label) {
        store.dispatch(apiStart(label));
    }

    axios
        .request({
            url,
            method,
            headers,
            responseType,
            [dataOrParams]: data,
        })
        .then((response) => {
            store.dispatch(onSuccess(response.data, response));
        })
        .catch(error => {
            store.dispatch(apiError(error));
            store.dispatch(onFailure(error));
            if (error.response && (error.response.status === 403 || error.response.status === 401)) {
                if (error.response.data && error.response.data.message === "username or password is incorrect") {
                    store.dispatch(incorrectCredentials());
                }
                else {
                    store.dispatch(accessDenied(window.location.pathname));
                }
            }
        })
        .finally(() => {
            if (label) {
                store.dispatch(apiEnd(label));
            }
        });
};

export default apiMiddleware;