import i18n from "i18next";
import {valueFoundInArray} from "../../utils";
import {
    ROLE_ADMIN_MUNICIPALITY,
    ROLE_ADMIN_ORGANIZATION,
    ROLE_ADMIN_SYSTEM,
    ROLE_EMPLOYEE_MUNICIPALITY,
    ROLE_EMPLOYEE_ORGANIZATION,
    ROLE_GUEST,
    ROLE_LEADER_MUNICIPALITY,
    roles
} from "../../permissions";

export const MUNICIPALITY_DRAFT = "DRAFT";
export const MUNICIPALITY_PUBLISHED = "PUBLISHED";

const functionTypes = {
    'open': i18n.t("function.open"),
    'group': i18n.t("function.group"),
    'individual': i18n.t("function.individual"),
}

const systemLogOperations = {
    'edit': i18n.t("system_log.edit"),
    'archive': i18n.t("system_log.archive"),
    'create': i18n.t("system_log.create"),
    'restore': i18n.t("system_log.restore"),
}

const systemLogCategories = {
    'municipality': i18n.t("system_log.municipality"),
    'organization': i18n.t("system_log.organization"),
    'location': i18n.t("system_log.location"),
    'function': i18n.t("system_log.function"),
    'user': i18n.t("system_log.user"),
    'contentIdentifier': i18n.t("system_log.contentIdentifier"),
}

const reminderTypeTranslations = {
    "FUNCTION_PARTICIPANTS_MISSING": {
        'title': i18n.t("reminder.participantsMissing"),
        "content": i18n.t("reminder.participantsMissingContent")
    },
    "CHECK_MUNICIPALITY_KEY_FIGURES": {
        'title': i18n.t("reminder.municipalityFiguresChanged"),
        "content": i18n.t("reminder.municipalityFiguresChangedContent")
    },
    "TIME_TO_CHANGE_PASSWORD": {
        'title': i18n.t("reminder.changeYourPassword"),
        "content": i18n.t("reminder.changeYourPasswordContent")
    },
    "MUNICIPALITY_KEY_FIGURES_UPDATED": {
        'title': i18n.t("reminder.updateMunicipalityFigures"),
        "content": i18n.t("reminder.updateMunicipalityFiguresContent")
    },
    "USER_WILL_BE_INACTIVATED": {
        'title': i18n.t("reminder.userWillBeInactivated"),
        "content": i18n.t("reminder.userWillBeInactivatedContent")
    },
}

export const getReminderTitle = (key) => {
    if (key == null) {
        return "";
    }
    return reminderTypeTranslations[key].title;
}
export const getReminderContent = (key) => {
    if (key == null) {
        return "";
    }
    return reminderTypeTranslations[key].content;
}

export const getFunctionTypeOptions = () => {
    const response = [];
    for (const key in functionTypes) {
        response.push({value: key, label: functionTypes[key]})
    }
    return response;

}

export const getOperationLabel = (key) => {
    if (key == null) {
        return "";
    }
    return systemLogOperations[key];
}


export const getCategoryLabel = (key) => {
    if (key == null) {
        return "";
    }
    return systemLogCategories[key];
}

export const getFunctionTypeLabel = (key) => {
    if (key == null) {
        return "";
    }
    return functionTypes[key];
}

export const getUserIconUrl = (user) => {
    const images = {
        [ROLE_ADMIN_SYSTEM]: 'IconMainUserH54.svg',
        [ROLE_ADMIN_MUNICIPALITY]: 'IconBossH54.svg',
        [ROLE_LEADER_MUNICIPALITY]: 'IconBossH54.svg',
        [ROLE_ADMIN_ORGANIZATION]: 'IconDirectorH54.svg',
        [ROLE_EMPLOYEE_MUNICIPALITY]: 'IconWorkerH54.svg',
        [ROLE_EMPLOYEE_ORGANIZATION]: 'IconWorkerH54.svg',
        [ROLE_GUEST]: 'IconVarisH54.svg',
    }

    const role = user.role;
    if (!valueFoundInArray(role, roles)) {
        return '/media/images/icons/' + images[ROLE_GUEST];
    }

    return '/media/images/icons/' + images[role];
}

export const getFunctionIconUrl = (func) => {
    const images = {
        'open': 'IconOpen.svg',
        'group': 'IconGroup.svg',
        'individual': 'IconIndividual.svg'
    }

    const eventType = func.eventType;
    if (!functionTypes.hasOwnProperty(eventType)) {
        return '/media/images/icons/' + images['open'];
    }

    return '/media/images/icons/' + images[eventType];
}

