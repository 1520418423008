import {Button, makeStyles, Popover,} from "@material-ui/core";
import React from "react";
import * as PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
}));

const settings = {
    'top': {
        anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
        },
        transformOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
        }
    },
    'left': {
        anchorOrigin: {
            vertical: 'center',
            horizontal: 'left',
        },
        transformOrigin: {
            vertical: 'center',
            horizontal: 'right',
        }
    },
    'right': {
        anchorOrigin: {
            vertical: 'center',
            horizontal: 'right',
        },
        transformOrigin: {
            vertical: 'center',
            horizontal: 'left',
        }
    },
}
const CompTooltipIcon = (props) => {
    const {t} = useTranslation('translation');
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        if (open) {
            handlePopoverClose();
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const {orientation="top", children, ...componentProps} = props;

    const orig = settings[orientation];

    let popover = null;
    let popoverOverlay = (<div/>);

    if (open) {
        popover = (
            <Popover
                className={'tooltip-icon-popover ' + classes.popover}
                classes={{
                    paper: orientation + " " + classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: orig.anchorOrigin.vertical,
                    horizontal: orig.anchorOrigin.horizontal,
                }}
                transformOrigin={{
                    vertical: orig.transformOrigin.vertical,
                    horizontal: orig.transformOrigin.horizontal,
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
                container={anchorEl.parentNode}
                {...componentProps}
            >
                <Typography>{children}</Typography>
            </Popover>
        )
        popoverOverlay = (<div className={'popoverOverlay'}
                              onClick={handlePopoverOpen}/>);
    }

    return (

        <span className={'tooltip-icon-container'}>
            <Button onClick={handlePopoverOpen}
                //onMouseLeave={handlePopoverClose}
                //onMouseEnter={handlePopoverOpen}
            >
                <img className={`tooltip-icon-image ${open ? 'hidden' : ''}`}
                     src={process.env.PUBLIC_URL + '/media/images/icons/IconTooltipInfo.svg'}
                     alt={t('common.info')}/>
                <img className={`tooltip-icon-image ${open ? '' : 'hidden'}`}
                     src={process.env.PUBLIC_URL + '/media/images/icons/IconTooltipInfoFocus.svg'}
                     alt={t('common.info')}/>
            </Button>
            {popoverOverlay}
            {popover}
        </span>
    );
}

CompTooltipIcon.propTypes = {
    orientation: PropTypes.string
};

export {CompTooltipIcon};