const types = {
    FETCH_OWN_MUNICIPALITY_REPORT: 'report/fetchOwnMunicipalityReport',
    FETCH_OWN_MUNICIPALITY_REPORT_SUCCESS: 'report/fetchOwnMunicipalityReportSuccess',
    FETCH_MUNICIPALITY_REPORT: 'report/fetchMunicipalityReport',
    FETCH_MUNICIPALITY_REPORT_SUCCESS: 'report/fetchMunicipalityReportSuccess',
    FETCH_PROVINCE_REPORT: 'report/fetchProvinceReport',
    FETCH_PROVINCE_REPORT_SUCCESS: 'report/fetchProvinceReportSuccess',
    FETCH_COUNTRY_REPORT: 'report/fetchCountryReport',
    FETCH_COUNTRY_REPORT_SUCCESS: 'report/fetchCountryReportSuccess',
    FETCH_CSV_REPORT: 'report/fetchCSVReport',
    FETCH_CSV_REPORT_SUCCESS: 'report/fetchCSVReportSuccess',
    REPORT_API_ERROR: 'report/reportAPIError',
    FETCH_MUNICIPALITY_GRID: 'report/fetchMunicipalityGrid',
    IGNORE: 'report/ignore'
}

export default types;