import {apiAction} from "../api/actions";
import settings from "../api/settings";
import types from "./changepass.types";


export const changePasswordAPICallError = error => {
    let data = "Connection error";
    if (error.response !== undefined) data = error.response.data;
    return {
        type: types.CHANGE_PASSWORD_ERROR,
        payload: data
    }
}

export const setPasswordChanged = (data, response) => {
    return {
        type: types.CHANGE_PASSWORD_POST,
        payload: {
            data: data,
            headers: response.headers
        }
    };
}

export const changePassword = (userId, hash, key, password, passwordAgain, onSuccess, onFailure) => {
    const data = {
        'password': password,
        'passwordAgain': passwordAgain,
    };
    return apiAction({
        url: `${settings.baseURL}/change/pw/${userId}/${hash}/${key}`,
        method: 'POST',
        data: data,
        onSuccess: onSuccess,
        onFailure: onFailure,
        label: types.CHANGE_PASSWORD_POST
    });
};

