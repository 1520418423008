const types = {
    CREATE_CONTENT_IDENTIFIER: 'contentIdentifier/createContentIdentifier',
    FETCH_CONTENT_IDENTIFIERS_LIST: 'contentIdentifier/fetchContentIdentifiersList',
    SET_CONTENT_IDENTIFIERS_LIST: 'contentIdentifier/setContentIdentifiersList',
    FETCH_CONTENT_IDENTIFIER: 'contentIdentifier/fetchContentIdentifier',
    SET_CONTENT_IDENTIFIER: 'contentIdentifier/setContentIdentifier',
    UNSET_CONTENT_IDENTIFIER: 'contentIdentifier/unsetContentIdentifier',
    UPDATE_CONTENT_IDENTIFIER: 'contentIdentifier/updateContentIdentifier',
    CONTENT_IDENTIFIER_API_ERROR: 'contentIdentifier/contentIdentifierAPIError',
    ARCHIVE_CONTENT_IDENTIFIER: 'contentIdentifier/archiveContentIdentifier',
}

export default types;