import {apiAction} from "../api/actions";
import settings from "../api/settings";
import {types} from "./auth.types";


const authAPICallError = error => {
    let data = "Connection error";
    if (error.response !== undefined) data = error.response.data;
    return {
        type: types.AUTH_API_ERROR,
        payload: data
    }
}

export const setAuthUserInfo = (userData) => {
    return {
        type: types.AUTH_SET_AUTH_USER_INFO,
        payload: userData
    }
}

export const setLoggedIn = (data, response) => {
    return {
        type: types.AUTH_LOGIN,
        payload: {
            data: data,
            headers: response.headers
        }
    };
}
export const setLoggedOut = () => {
    return {
        type: types.AUTH_LOGOUT,
        payload: {}
    };
}

export const passwordRenewalMessageSent = () => {
    return {
        type: types.AUTH_RENEW_PASSWORD,
        payload: {}
    };
}

export const login = (username, password, onSuccess, onFailure) => {
    const data = {
        'username': username,
        'password': password,
    };
    return apiAction({
        url: `${settings.baseURL}/login`,
        method: 'POST',
        data: data,
        onSuccess: onSuccess,
        onFailure: onFailure,
        label: types.AUTH_LOGIN
    });
};

export const sendRenewalMessage = (userEmail, onSuccess) => {
    const data = {
        email: userEmail
    }
    return apiAction({
        url: `${settings.baseURL}/users/renew_password`,
        data: data,
        method: 'POST',
        onSuccess: onSuccess,
        onFailure: authAPICallError,
        label: types.AUTH_RENEW_PASSWORD
    });
}