import {Municipality} from "./modules/municipality/municipality.index";
import {Login} from "./modules/auth/auth.index";
import {ChangePassword} from "./modules/changepass/changepass.index";
import {Redirect, Route} from "react-router-dom";
import {Location} from "./modules/location/location.index";
import {Home} from "./modules/home/home.index";
import {Version} from "./modules/home/versionComponent.js";
import {Feedback} from "./modules/feedback/feedbackComponent.js";
import React from "react";
import {Func} from "./modules/function/function.index";
import {User} from "./modules/user/user.index"
import {ContentIdentifier} from "./modules/contentidentifier/contentIdentifier.index";
import {Report} from "./modules/report/report.index"
import {
    hasPermission,
    isAuthenticated,
    PERMISSION_AREAS,
    PERMISSION_CONTENT_IDENTIFIERS,
    PERMISSION_FUNCTIONS,
    PERMISSION_LOCATIONS,
    PERMISSION_NOTIFICATIONS,
    PERMISSION_SYSTEM_LOG,
    PERMISSION_REPORTS,
    PERMISSION_USERS
} from "./permissions";
import {ForgotPassword} from "./modules/forgotpass/forgotpass.index";
import {SystemLog} from "./modules/systemlog/systemlog.index";
import i18n from "./i18n.js";


const PrivateRoute = ({component: Component, permissionName, authenticatedUser, ...rest}) => {

    return (
        <Route
            {...rest}
            render={(props) => hasPermission(permissionName, authenticatedUser)
                ? <Component {...props}/>
                : <Redirect to={{pathname: '/login', state: {from: props.location}}}/>}
        />
    )
}

const PublicRoute = ({component: Component, authenticatedUser, ...rest}) => {

    return (
        <Route
            {...rest}
            render={(props) => isAuthenticated(authenticatedUser)
                ? <Redirect to='/'/>
                : <Component {...props} />
            }
        />
    )
}

const HybridRoute = ({component: Component, authenticatedUser, ...rest}) => {

    return (
        <Route
            {...rest}
            render={(props) => <Component {...props} />
            }
        />
    )
}

export const routes = [
    {
        RouteType: PublicRoute, 
        path: "/login", 
        name: "Sisäänkirjautuminen", 
        Component: Login
    },
    {
        RouteType: HybridRoute, 
        path: "/version", 
        name: i18n.t("version"), 
        Component: Version
    },
    {
        RouteType: HybridRoute, 
        path: "/feedback", 
        name: i18n.t("feedback.feedback"), 
        Component: Feedback
    },
    {
        RouteType: HybridRoute, 
        path: "/feedback/:code", 
        name: i18n.t("feedback.feedback"), 
        Component: Feedback
    },
    {
        RouteType: PublicRoute, 
        path: "/forgot_password", 
        name: "Unohtuiko salasana", 
        Component: ForgotPassword
    },
    {
        RouteType: PublicRoute,
        path: "/change/password/:userId/:hash/:key",
        name: "Salasanan vaihto",
        Component: ChangePassword
    },
    {
        RouteType: PrivateRoute,
        path: "/notification/:action",
        name: i18n.t("common.front_page"),
        Component: Home,
        PermissionName: PERMISSION_NOTIFICATIONS
    },
    {
        RouteType: PrivateRoute,
        path: "/",
        name: i18n.t("common.front_page"),  // TODO: translate
        Component: Home,
        PermissionName: PERMISSION_NOTIFICATIONS
    },
    {
        RouteType: PrivateRoute,
        path: "/report",
        name: i18n.t("common.reports"),
        Component: Report,
        PermissionName: PERMISSION_REPORTS
    },
    {
        RouteType: PrivateRoute,
        path: "/systemLog",
        name: i18n.t("common.system_logs"),
        Component: SystemLog,
        PermissionName: PERMISSION_SYSTEM_LOG
    },
    {
        RouteType: PrivateRoute,
        path: "/contentIdentifier",
        name: i18n.t("common.content_identifiers"),
        Component: ContentIdentifier,
        PermissionName: PERMISSION_CONTENT_IDENTIFIERS
    },
    {
        RouteType: PrivateRoute,
        path: "/user/:action",
        name: i18n.t("common.users"),
        Component: User,
        PermissionName: PERMISSION_USERS
    },
    {
        RouteType: PrivateRoute,
        path: "/user",
        name: i18n.t("common.users"),
        Component: User,
        PermissionName: PERMISSION_USERS
    },
    {
        RouteType: PrivateRoute,
        path: "/function/edit/:editid",
        name: i18n.t("common.functions"),
        Component: Func,
        PermissionName: PERMISSION_FUNCTIONS
    },
    {
        RouteType: PrivateRoute,
        path: "/function/:action",
        name: i18n.t("common.functions"),
        Component: Func,
        PermissionName: PERMISSION_FUNCTIONS
    },
    {
        RouteType: PrivateRoute,
        path: "/function",
        name: i18n.t("common.functions"),
        Component: Func,
        PermissionName: PERMISSION_FUNCTIONS
    },
    {
        RouteType: PrivateRoute,
        path: "/location",
        name: i18n.t("common.locations"),
        Component: Location,
        PermissionName: PERMISSION_LOCATIONS
    },
    {
        RouteType: PrivateRoute, path: "/municipality",
        name: i18n.t("common.areas"),
        Component: Municipality,
        PermissionName: PERMISSION_AREAS
    },
];