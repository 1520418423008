import {types} from "./root.types";
import {routes} from "../../routes";
import {ACCESS_DENIED, API_ERROR, WRONG_PASSWORD} from "../api/types";
import {valueFoundInArray} from "../../utils";
import i18n from "i18next";
import {types as auth_types} from "../auth/auth.types";

export const initialState = {
    mobileOpen: false,
    leftDrawerOpen: false,
    rightDrawerOpen: false,
    breadcrumbs: [],
    locationTypes: [],
    userRoles: [],
    contentIdentifiers: [],
    methods: [],
    provinces: [],
    alert: {
        isOpen: false,
        severity: undefined,
        text: ''
    }
}

export default function moduleReducer(state = initialState, action) {
    switch (action.type) {
        case auth_types.AUTH_LOGIN:
            return initialState;
        case auth_types.AUTH_LOGOUT:
            return initialState;
        case ACCESS_DENIED:
            const text = "Access denied";
            return {
                ...state,
                alert: {
                    isOpen: true,
                    severity: "error",
                    text: i18n.t("errors." + text) ? i18n.t("errors." + text) : text
                }
            }
        case WRONG_PASSWORD:
            const text2 = "username or password is incorrect";
            return {
                ...state,
                alert: {
                    isOpen: true,
                    severity: "error",
                    text: i18n.t("errors." + text2) ? i18n.t("errors." + text2) : text2
                }
            }
        case API_ERROR:
            if (action.payload == null) {
                return state;
            }
            if (typeof action.payload === "string") {
                return {
                    ...state,
                    alert: {
                        isOpen: true,
                        severity: "error",
                        text: i18n.t("errors." + action.payload) ? i18n.t("errors." + action.payload) : action.payload,
                    }
                }
            } else if ('status' in action.payload && valueFoundInArray(action.payload.status, [404, 405])) {
                return {
                    ...state,
                    alert: {
                        isOpen: true,
                        severity: "error",
                        text: i18n.t("errors." + action.payload.error) ? i18n.t("errors." + action.payload.error) : action.payload.error
                    }
                }
            } else {
                return state
            }
        case types.ALERT_CLOSED:
            return {
                ...state,
                alert: {
                    isOpen: false,
                    severity: undefined,
                    text: ''
                }
            }
        case types.SET_ALERT:
            return {
                ...state,
                alert: {
                    isOpen: true,
                    severity: action.payload.severity,
                    text: action.payload.message
                }
            }
        case types.MENU_TOGGLED:
            return {
                ...state,
                mobileOpen: !state.mobileOpen,
                leftDrawerOpen: false,
                rightDrawerOpen: true
            }
        case types.MENU_CLOSED:
            return {
                ...state,
                mobileOpen: false,
                leftDrawerOpen: false,
                rightDrawerOpen: false
            }
        case types.LEFT_DRAWER_SELECTED:
            return {
                ...state,
                mobileOpen: true,
                leftDrawerOpen: true,
                rightDrawerOpen: false
            }
        case types.RIGHT_DRAWER_SELECTED:
            return {
                ...state,
                mobileOpen: true,
                leftDrawerOpen: false,
                rightDrawerOpen: true
            }
        case types.SET_BREADCRUMBS:
            const crumbs = routes
                // Get all routes that contain the current one.
                .filter(({path}) => action.payload.path.includes(path))
                // Swap out any dynamic routes with their param values.
                .map(({path, ...rest}) => ({
                    path: Object.keys(action.payload.params).length
                        ? Object.keys(action.payload.params).reduce(
                            (path, param) => path.replace(
                                `:${param}`, action.payload.params[param]
                            ), path
                        )
                        : path,
                    ...rest
                }));
            return {
                ...state,
                breadcrumbs: crumbs
            }
        case types.UPDATE_CONTENT_IDENTIFIER:
            return {
                ...state,
                contentIdentifiers: action.payload.contentIdentifiers
            }
        case types.SET_CHOICES:
            return {
                ...state,
                locationTypes: action.payload.locationTypes,
                userRoles: action.payload.userRoles,
                contentIdentifiers: action.payload.contentIdentifiers,
                methods: action.payload.methods,
                provinces: action.payload.provinces,
            }
        default:
            // Do not use the default case
            // A dispatch action will pass through each of the reducers associated with this one.
            // An action at the other end of your application could affect this state
            // without being expressed in the code. This should be avoided.
            return state
    }
}
