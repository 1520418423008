import React from 'react';


import {withRouter} from "react-router";
import {Container, CssBaseline} from "@material-ui/core";
import {TopMenu} from "./TopMenu";
import {LeftDrawer} from "./LeftDrawer";
import {RightDrawer} from "./RightDrawer";
import {Footer} from "./Footer";
import {connect} from "react-redux";
import Alert from '@material-ui/lab/Alert';
import {closeAlert, closeMenu} from "../modules/root/root.actions";
import {ReactComponent as ErrorIcon} from "../styles/images/IconError.svg";
import {ReactComponent as WarningIcon} from "../styles/images/IconWarning.svg";
import {ReactComponent as InfoIcon} from "../styles/images/IconInfo.svg";
import {ReactComponent as SuccessIcon} from "../styles/images/IconSuccess.svg";


const mapStateToProps = state => {
    return {
        mobileOpen: state.root.mobileOpen,
        alert: state.root.alert
    };
}
const mapDispatchToProps = dispatch => {
    return {
        closeAlert: () => dispatch(closeAlert()),
        closeMenu: () => dispatch(closeMenu()),
    };
}

class ApplicationComponent extends React.Component {

    constructor(props) {
        super(props);
        this.closeMobileMenu = this.closeMobileMenu.bind(this);
    }

    closeMobileMenu(event) {
        event.preventDefault();
        this.props.closeMenu();
    }

    render() {
        const alert = this.props.alert;
        let alertComponent = (<div/>);
        if (alert !== undefined && alert.isOpen === true) {
            alertComponent = (
                <Alert onClose={this.props.closeAlert}
                       severity={alert.severity}
                       iconMapping={{
                           error: <ErrorIcon fontSize="inherit"/>,
                           warning: <WarningIcon fontSize="inherit"/>,
                           info: <InfoIcon fontSize="inherit"/>,
                           success: <SuccessIcon fontSize="inherit"/>
                       }}
                >{alert.text}</Alert>
            );
        }
        return (
            <div className={'root'}>
                <CssBaseline/>

                {/** Top menu **/}
                <TopMenu/>

                {/** Left menu **/}
                <LeftDrawer/>
                {/** Main content **/}
                <main className={'content'}>
                    <div className={'appBarSpacer'}/>
                    <Container className={'container'} maxWidth={false}>
                        {alertComponent}
                        {this.props.children}
                    </Container>
                    {/** Footer **/}
                    <Footer/>
                </main>

                {/** Right menu **/}
                <RightDrawer/>
                {/** Overlay vor mobile menu **/}
                <div className={`mobileMenuOverlay ${this.props.mobileOpen ? '' : 'hidden'}`}
                     onClick={this.closeMobileMenu}/>
            </div>
        )
    }
}

const Application = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ApplicationComponent));

export {Application};