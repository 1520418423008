import types from "./contentIdentifier.types";
import {API_END, API_START} from "../api/types";
import {types as auth_types} from "../auth/auth.types";

export const initialState = {
    contentIdentifiers: {},
    isLoadingContentIdentifiersListData: false,
    selectedContentIdentifier: {},
    contentIdentifierFieldErrors: {},

}

export default function moduleReducer(state = initialState, action) {
    switch (action.type) {
        case auth_types.AUTH_LOGIN:
            return initialState;
        case auth_types.AUTH_LOGOUT:
            return initialState;
        case types.SET_CONTENT_IDENTIFIERS_LIST:
            return {
                ...state,
                contentIdentifiers: {
                    ...state.contentIdentifiers,
                    [action.payload.type]: action.payload.data
                }
            }
        case types.SET_CONTENT_IDENTIFIER:
            return {
                ...state,
                contentIdentifierFieldErrors: {},
                selectedContentIdentifier: action.payload
            }
        case types.UNSET_CONTENT_IDENTIFIER:
            return {
                ...state,
                contentIdentifierFieldErrors: {},
                selectedContentIdentifier: {}
            }
        case API_START:
            if (action.payload === types.FETCH_CONTENT_IDENTIFIERS_LIST) {
                return {
                    ...state,
                    isLoadingContentIdentifiersListData: true
                };
            }
            return state
        case API_END:
            if (action.payload === types.FETCH_CONTENT_IDENTIFIERS_LIST) {
                return {
                    ...state,
                    isLoadingContentIdentifiersListData: false
                };
            }
            return state
        case types.CONTENT_IDENTIFIER_API_ERROR:
            if (action.payload == null) {
                return state;
            }
            if (typeof action.payload === "string") {
                console.log("API error: " + action.payload)
                return state
            } else if ('status' in action.payload && action.payload.status === 405) {
                console.log("API error: " + action.payload.error)
                return state
            } else if ('data' in action.payload && action.payload.data !== null) {
                console.log("API error: " + action.payload.message)
                return {
                    ...state,
                    contentIdentifierFieldErrors: action.payload.data
                }
            } else {
                console.log("API error: " + action.payload)
                return state
            }
        default:
            // Do not use the default case
            // A dispatch action will pass through each of the reducers associated with this one.
            // An action at the other end of your application could affect this state
            // without being expressed in the code. This should be avoided.
            return state
    }
}
