import React from "react";
import * as PropTypes from "prop-types";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";


const CompReportYouthWorkFiguresTableComponent = ({
                                                      props
                                                  }) => {
    const {
        rows = [],
        label = "",
    } = props;

    const {t} = useTranslation('translation');

    return (
        <div className={'CompSystemLogTable-wrapper'}>
            <div className={'CompSystemLogTable-mainTitle'}>{label}</div>
            <TableContainer component={Paper} className={'CompSystemLogTable-container'}>
                <Table className={'CompSystemLogTable-table'} aria-label={label}>
                    <TableHead>
                        <TableRow>
                            <TableCell/>
                            <TableCell>{t('report.participants')}</TableCell>
                            <TableCell>{t('report.event_count')}</TableCell>
                            <TableCell>{t('report.sum_of_event_hours')}</TableCell>
                            <TableCell>{t('report.youth_organizers_count')}</TableCell>
                            <TableCell>{t('report.event_hours_of_youth_organizers_count')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow
                                hover
                                tabIndex={-1}
                                key={`row-${index}`}
                            >
                                <TableCell component="th" scope="row">
                                    {row.title}
                                </TableCell>
                                <TableCell>{row.participantCount}</TableCell>
                                <TableCell>{row.eventCount}</TableCell>
                                <TableCell>{row.sumOfEventHours}</TableCell>
                                <TableCell>{row.youthOrganizersCount}</TableCell>
                                <TableCell>{row.sumOfYouthOrganizersSEventHours}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

CompReportYouthWorkFiguresTableComponent.propTypes = {
    rows: PropTypes.array,
    label: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
    // return state;
    return {
        selectedSystemLog: state.systemLog.selectedSystemLog,
        props: ownProps
    }
}

const CompReportYouthWorkFiguresTable = connect(mapStateToProps)(CompReportYouthWorkFiguresTableComponent);

export {
    CompReportYouthWorkFiguresTable,
}