import React, {useState} from "react";
import * as PropTypes from "prop-types";
import {isFunction, isNormalInteger} from "../utils";
import {useTranslation} from "react-i18next";


const CompInputList = (props) => {
    const { t } = useTranslation('translation');

    const inputProps = {
        disabled: false,
        onChange: () => {
        }
    };
    const {rows, label, expectedTotal, error, onChange, ...componentProps} = props;

    const [currentRows, setRows] = useState(rows);

    if ('disabled' in componentProps) {
        inputProps.disabled = componentProps.disabled;
    }
    if (onChange) {
        inputProps.onChange = onChange;
    }

    const getTotal = (rows) => {
        return rows.reduce((total, obj) => isNormalInteger(obj.value) ? parseInt(obj.value) + total: total, 0)
    }

    const getValue = (value, row, rows) => {
        if(isFunction(value)) {
            return value(row, rows, getTotal(rows), expectedTotal);
        } else {
            if (value == null) value = '';
            return value;
        }
    }
    const changeValue = (row, value, index, e) => {
        e.preventDefault();
        const currentVal = currentRows[index].value ? currentRows[index].value : 0;
        if (isNaN(value)) {
            value = currentVal;
        }

        if(value < 0) value = 0;
        const total = expectedTotal ? expectedTotal : 0;
        const currentRow = currentRows[index];

        const newRows = [...currentRows];
        newRows[index] = {
            key: currentRow.key,
            value: value,
            label: currentRow.label
        };
        if(getTotal(newRows) > total && value > currentVal) return;

        setRows(newRows);
        if(isFunction(onChange)) {
            onChange(e);
        }
    }

    return (
        <div className={`input-list-container ${error ? 'error' : ''}`}>
            <div className={'label'}>{label}</div>
            <div className={'input-list'} {...componentProps}>
                {currentRows.map((row, index) => (
                    <div key={`input-list-row-${index}`} className={`input-list-row input-list-row-${index}`}>
                        <div className={'input-list-row-label label'}>{row.label}</div>
                        <div className={'input-list-row-value'}>
                            <input {...inputProps}
                                   type='number'
                                   disabled={inputProps.disabled || isFunction(row.value)}
                                   name={row.key}
                                   value={getValue(row.value, row, currentRows)}
                                   onChange={e => changeValue(row, e.target.value, index, e)}
                            />
                        </div>
                    </div>
                ))}
                <div className={`input-list-row input-list-row-total`}>
                    <div className={'input-list-row-label label'}>{t('components.registered')}</div>
                    <div className={'input-list-row-value'}>
                        {getTotal(currentRows)}/{expectedTotal}
                    </div>
                </div>
            </div>
            {(error && <div className={`error-label input-list-error ${props.className ? 'error-' + props.className : ''}`}>{t("errors."+error)}</div>)}
        </div>
    );
}

CompInputList.propTypes = {
    label: PropTypes.string,
    rows: PropTypes.array,
    error: PropTypes.string,
    expectedTotal: PropTypes.number,
    onChange: PropTypes.func
};

export {CompInputList};