import {ButtonMain} from "./ButtonMain";
import {ButtonSecondary} from "./ButtonSecondary";
import {ButtonAttention} from "./ButtonAttention";
import React from "react";

export function selectButtonType(button, componentActionButtons, key, handleClick, id = null) {
    const label = 'label' in button ? button.label : "";
    const className = 'className' in button ? button.className : "";
    const onClick = 'onClick' in button ? button.onClick : () => {
    };
    if (button.type === 'main') {
        componentActionButtons.push(
            <ButtonMain key={key} onClick={(event) => handleClick(event, onClick)} className={className} buttonIdentifier={id}>
                {label}
            </ButtonMain>
        )
    } else if (button.type === 'secondary') {
        componentActionButtons.push(
            <ButtonSecondary key={key} onClick={(event) => handleClick(event, onClick)} className={className} buttonIdentifier={id}>
                {label}
            </ButtonSecondary>
        )
    } else if (button.type === 'attention') {
        componentActionButtons.push(
            <ButtonAttention key={key} onClick={(event) => handleClick(event, onClick)} className={className} buttonIdentifier={id}>
                {label}
            </ButtonAttention>
        )
    } else {
        componentActionButtons.push(
            <ButtonMain key={key} onClick={(event) => handleClick(event, onClick)} className={className} buttonIdentifier={id}>
                {label}
            </ButtonMain>
        )
    }
}