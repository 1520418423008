import {FormControl, FormHelperText, MenuItem, Select, InputLabel} from "@material-ui/core";
import React from "react";
import * as PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {isFunction} from "../utils";


const CompDropdown = (props) => {
    const {t} = useTranslation('translation');
    let {error} = props
    const {
        rows, label, children, onChange, labelColumn = 'label',
        valueColumn = 'value',
        hasEmptyOption = false,
        selectedValue = "",
        ...componentProps
    } = props;

    if(error !== undefined && error !== null) {
        error = t("errors."+error);
    } else {
        error=null;
    }

    let emptyOption;

    if (hasEmptyOption) {
        emptyOption = (
            <MenuItem key={`option-empty`} className={`option option-empty`}
                    value={""}>{t('components.fill_out')}</MenuItem>
        )
    } else {
        emptyOption = (
            <MenuItem disabled key={`option-empty`} value={""}> -- {t('components.select')} -- </MenuItem>
        );
    }

    const customRenderValue = (selected) => {

        if (selected === "") {
            emptyOption = ""

            if(hasEmptyOption) {
                return t('components.fill_out')
            } else {
                return "-- " + t('components.select') + " --"
            }
        }
        const selectedOption = rows.find((row) => row[valueColumn] === selected);
        const label = selectedOption ? selectedOption[labelColumn] : '';

        return label.trim();
      };

    const handleChange = (event) => {
        if (isFunction(onChange)) {
            onChange(event)
        }
    };

    return (
        <div>
            <InputLabel className={'label'}>{label}</InputLabel>
                <FormControl className={`comp-dropdown-container ${props.className ? 'container-' + props.className : ''} ${error ? 'error' : ''}`}>
                    <Select
                        className={'dropdown-select dropdown_custom'}
                        value={null == selectedValue ? null : selectedValue}
                        {...componentProps}
                        label={label}
                        onChange={handleChange}
                        renderValue={customRenderValue}
                        disableUnderline={true}
                        displayEmpty
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null
                        }}
                    > 
                    {emptyOption}
                        {rows.map((row, index) => (
                             <MenuItem
                                 style={props.style}
                                key={`option-${index}`}
                                 className={`option option-${index}`}
                                 value={row[valueColumn]}
                             >
                                 {row[labelColumn]}
                             </MenuItem>
                         ))}
                     </Select>
                 <FormHelperText className={`error-label ${props.className ? 'error-' + props.className : ''}`}>{error}</FormHelperText>
             </FormControl>
         </div>
    );
}

CompDropdown.propTypes = {
    labelColumn: PropTypes.string,
    valueColumn: PropTypes.string,
    label: PropTypes.string,
    rows: PropTypes.array,
    error: PropTypes.string,
    hasEmptyOption: PropTypes.bool,
    onChange: PropTypes.func,
    selectedValue: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
};

export {CompDropdown};