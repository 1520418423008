import React from "react";

import {Grid} from "@material-ui/core";
import {CompPartitionHeader} from "../../../components/PartitionHeader";
import {CompTextField} from "../../../components/TextField";
import * as PropTypes from "prop-types";
import {ButtonSpacer} from "../../../components/ButtonSpacer";
import {CompListTree} from "../../../components/ListTree";
import {generateKey, isFunction} from "../../../utils";
import {useTranslation} from "react-i18next";
import {selectButtonType} from "../../../components/utils";
import {CompDropdown} from "../../../components/Dropdown";
import {connect} from "react-redux";


const LocationFormComponent = ({locationTypes,
                                   props}) => {
    const {
        label,
        onChange,
        editorActivated,
        actionButtons,
        selectedLocation = {},
        selectedMunicipality = {},
        fieldErrors = {},
        organizationTree = [],
        ...componentProps
    } = props;

    const {t} = useTranslation('translation');

    const selectOrganization = (row, isAddAction/*, index */) => {
        let value = null;
        if (isAddAction) {
            value = row.id;
        }
        const data = {
            target: {
                ...componentProps,
                value: value,
                name: 'organizationId',
            },
        };
        if (isFunction(onChange)) {
            onChange(data);
        }
    }

    const handleChange = (event) => {
        if (isFunction(onChange)) {
            onChange(event)
        }
    };

    const handleClick = (event, callback) => {
        event.preventDefault()
        callback(event, selectedLocation);
    };

    const getLocationTypeOptions = () => {
        const response = [];
        for (const i in locationTypes) {
            const locationType = locationTypes[i];
            response.push({value: locationType.id, label: t("fields."+locationType.name)})
        }
        return response;
    }

    let componentActionButtons = [];
    if (actionButtons) {
        actionButtons.forEach(function (button) {
            const key = generateKey('locEditButton');
            selectButtonType(button, componentActionButtons, key, handleClick);
        });
    }

    return (
        <form noValidate autoComplete="off" className={"location-container"}>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <CompPartitionHeader text={label}/>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <CompTextField
                                label={`${t('common.municipality')}*`}
                                value={selectedMunicipality.name}
                                disabled={true}
                                error={fieldErrors.name}
                                name="municipality"
                                onChange={handleChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <CompTextField
                                value={selectedLocation.name}
                                onChange={handleChange}
                                disabled={!editorActivated}
                                error={fieldErrors.name}
                                name="name"
                                label={`${t('location.name')}*`}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CompTextField
                                label={`${t('location.post_code')}`}
                                value={selectedLocation.postCode}
                                disabled={!editorActivated}
                                error={fieldErrors.postCode}
                                name="postCode"
                                onChange={handleChange}/>
                        </Grid>
                        <Grid item xs={12}>

                            <CompDropdown
                                label={`${t('location.location_type')}*`}
                                selectedValue={selectedLocation.locationTypeId?selectedLocation.locationTypeId:""}
                                rows={getLocationTypeOptions()}
                                disabled={!editorActivated}
                                name="locationTypeId"
                                error={fieldErrors.locationTypeId}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <CompListTree
                                onSelect={selectOrganization}
                                rows={organizationTree}
                                selectedRows={[selectedLocation.organizationId]}
                                disabled={!editorActivated}
                                error={fieldErrors.organizationId}
                                name="organizationId"
                                wholeTreeOpen={true}
                                keyColumn={'id'}
                                valueColumn={'name'}>
                                {t('common.organization')}
                            </CompListTree>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <CompTextField
                                label={t('location.description')}
                                value={selectedLocation.description}
                                disabled={!editorActivated}
                                error={fieldErrors.description}
                                name="description"
                                multiline
                                rows={6}
                                onChange={handleChange}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid
                        container spacing={1}
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                        className={'bottom-button-container'}>
                        <Grid item xs={12} className={'align-right'}>
                            <ButtonSpacer>
                                {componentActionButtons}
                            </ButtonSpacer>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}

LocationFormComponent.propTypes = {
    editorActivated: PropTypes.bool,
    onChange: PropTypes.func,
    selectedLocation: PropTypes.object,
    selectedMunicipality: PropTypes.object,
    actionButtons: PropTypes.array
};

const mapStateToProps = (state, ownProps) => {
    // return state;
    return {
        locationTypes: state.root.locationTypes,
        props: ownProps
    }
}

const LocationForm = connect(mapStateToProps)(LocationFormComponent);

export {LocationForm};