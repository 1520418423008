import {createStore, combineReducers, applyMiddleware} from 'redux'
import municipality from './modules/municipality/municipality.reducer.js'
import location from './modules/location/location.reducer.js'
import root from './modules/root/root.reducer.js'
import auth from './modules/auth/auth.reducer.js'
import func from './modules/function/function.reducer'
import systemLog from './modules/systemlog/systemlog.reducer'
import contentIdentifier from './modules/contentidentifier/contentIdentifier.reducer'
import apiMiddleware from "./modules/api/middleware";
import storage from 'redux-persist/lib/storage'
import {createMigrate, persistReducer, persistStore} from "redux-persist";
import user from './modules/user/user.reducer'

const migrations = {
};

const persistConfig = {
    key: "root",
    storage,
    version: 1,
    migrate: createMigrate(migrations)
}

const reducers = combineReducers({root, municipality, auth, location, func, contentIdentifier, user, systemLog})
const persistedReducer = persistReducer(persistConfig, reducers)

export let store = createStore(persistedReducer,
    applyMiddleware(apiMiddleware))
export let persistor = persistStore(store)