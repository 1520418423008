import React from 'react';
import {Snackbar} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';

const CustomSnackbar = ({ open, onClose, severity, message }) => {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose();
  };

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
      <Alert elevation={6} variant="filled" onClose={handleClose} severity={severity}>
            {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;